import { AfterViewInit, Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { StorageMap } from '@ngx-pwa/local-storage';
import { TranslateService } from '@ngx-translate/core';
import { uniqBy } from 'lodash-es';
import filter from 'lodash-es/filter';
import orderBy from 'lodash-es/orderBy';
import { LazyLoadEvent, SortEvent, SortMeta } from 'primeng/api';
import { Table } from 'primeng/table';
import { combineLatest, delay, first, map, Observable, of, filter as rxjsFilter, take, takeUntil } from 'rxjs';
import {
  ApplicationState,
  CommonConstants,
  INotificationType,
  IScrollOptions,
  IStoreApiItem,
  IStoreApiList,
  ITableColumn,
  NotificationsService,
  PropertyBag,
  StorageConstants,
  TableService,
  UserStorageService,
} from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins';
import {
  checkAutoUpdateFeatureToggleBeforeStartProtectorTest,
  checkRAMConnectorPort,
  dateFormat,
  extractOnlyDate,
  formatOnlyDate,
  getDateFormatFromUserProfile,
  getRAMSettingsAvailableWorkflows,
  processLocationPath,
  startTest,
} from 'src/app/common/utils';
import { ConfigurationNotificationConstants } from 'src/app/configuration';
import { ILocationHierarchy, IRAMSettings, ITask, SearchedLocation } from 'src/app/configuration/models';
import {
  selectLocationHierarchy,
  selectSearchedLocation,
} from 'src/app/configuration/state/selectors/configuration.selectors';
import { AccessControlService } from 'src/app/root';
import { AppModulesTypes, AppUserPermissionList } from 'src/app/root/models';
import { SettingsActions } from 'src/app/settings';
import {
  EquipmentNotificationConstants,
  ProtectorSoftwareNotificationConstants,
  WorkshopConstants,
  WorkshopModuleRoutes,
} from '../../constants';
import { RAMConnectorErrorsService } from '../../services';
import { EquipmentCRUDActions } from '../../state/actions/equipment-crud/equipment-crud.actions';
import { EquipmentServiceTaskActions } from '../../state/actions/equipment-service-task';
import { EquipmentTestActions } from '../../state/actions/equipment-test';
import { RAMActions } from '../../state/actions/ram';
import { tableColumns } from './table-columns';

import {
  Equipment,
  ICylinderChargerRequest,
  IEquipment,
  IEquipmentServiceInterval,
  IIntervalData,
  ILocationAssignment,
  ILocationChange,
  IRAMStatus,
  ITestStatus,
  IWorkflowStoredTableElement,
  WorkflowType,
} from '../../models';
import {
  selectCheckRAMConnectorStatus,
  selectCheckRAMPortStatus,
  selectSavedEquipments,
  selectSavedLocationEquipments,
  selectSendServiceInterval,
  selectUpdateEquipmentLocationDuringBarcodeSearch,
  selectUpdateEquipmentsLocation,
  selectWorkflowAsset,
} from '../../state/selectors';

@Component({
  selector: 'ignis-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss'],
})
export class WorkflowComponent extends OnDestroyMixin() implements OnInit, AfterViewInit {
  workflowBtnType: typeof WorkflowType = WorkflowType;
  localTableColumns: ITableColumn[] = tableColumns;
  tableFiltersKey: string = StorageConstants.tablesStorageKeys.WORKFLOW;
  tableName: string = AppModulesTypes.equipmentWorkflowPage;
  operationalStatuses: PropertyBag = WorkshopConstants.operationalStatuses;
  currentlyWorkflowChecked: WorkflowType = WorkflowType.NONE;
  scrollbarOptions: IScrollOptions = CommonConstants.scrollbarOptions;

  barcode: string;
  addedEquipment: IEquipment;
  autoSelectedEquipment: IEquipment[] = null;
  selectedEquipmentForChecklistWorkflow: IEquipment[] = [];
  ramConnectorStatus: boolean | string;
  startAutoUpdate: boolean = false;
  notificationType: INotificationType = CommonConstants.notificationType;

  equipmentCRUDActions: EquipmentCRUDActions = inject(EquipmentCRUDActions);
  equipmentServiceTaskActions: EquipmentServiceTaskActions = inject(EquipmentServiceTaskActions);
  equipmentTestActions: EquipmentTestActions = inject(EquipmentTestActions);
  ramActions: RAMActions = inject(RAMActions);
  translateService: TranslateService = inject(TranslateService);
  notificationsService: NotificationsService = inject(NotificationsService);
  userStorageService: UserStorageService = inject(UserStorageService);
  storage: StorageMap = inject(StorageMap);
  ramConnectorErrorsService: RAMConnectorErrorsService = inject(RAMConnectorErrorsService);
  public tableService: TableService = inject(TableService);
  settingsActions: SettingsActions = inject(SettingsActions);
  accessControlService: AccessControlService = inject(AccessControlService);

  selectedLocation: ILocationAssignment;
  isLoading: Observable<boolean>;

  equipmentList: IEquipment[];
  newEquipment: IEquipment;
  equipmentDataAfterScanLocation: IEquipment[];
  tableColumns: ITableColumn[];
  isMobileSearch: boolean = false;

  isAddedInTable: boolean = false;
  updateTableData: boolean = true;
  isSubmitting: Observable<boolean>;
  disableCylinderChargerBtn: boolean;
  isSearchInputFocused: boolean = false;
  selectedEquipment: IEquipment[] = [];
  hideSelectedWorkflowSection: boolean;
  isWorkflowInProgress: boolean = false;
  isConfirmCloseModalOpen: boolean = false;
  isAddEquipmentBtnEnabled: boolean = false;
  isBlueBarButtonsDisabled: boolean = false;
  disableChecklistBntFlow: boolean;
  displayNoServiceIdTooltip: boolean = false;
  displayNoChecklistTooltip: boolean = false;
  mobileLocation: ILocationAssignment = null;
  isTableItemsLimitExceeded: boolean = false;
  maximumNumberOfWorkflowTableItems: number = 100;

  sortColumn: SortMeta;
  workflowAssetsTable: Partial<Table>;
  loadDataEvent: LazyLoadEvent;

  constructor(
    private router: Router,
    private store: Store<ApplicationState>,
  ) {
    super();

    this.equipmentList = [];

    this.handleTestFinish();
    this.removeEquipmentAfterChecklist();
  }

  ngOnInit(): void {
    if (WorkshopConstants.equipmentAdded in localStorage && WorkshopConstants.barCodeToSave in localStorage) {
      this.equipmentTestActions.requestWorkflowAsset(localStorage.getItem(WorkshopConstants.barCodeToSave));
    }

    this.readSelectedWorkflowFromLocalStorage();
    this.readLoadingState();

    this.isSubmitting = combineLatest([
      this.store.select(selectWorkflowAsset),
      this.store.select(selectSearchedLocation),
      this.store.select(selectCheckRAMConnectorStatus),
      this.store.select(selectCheckRAMPortStatus),
    ]).pipe(
      takeUntil(this.destroy),
      map(
        ([data, searchedLocation, ramConnectorStatus, portStatus]: [
          data: IStoreApiItem<IEquipment>,
          searchedLocation: IStoreApiItem<SearchedLocation>,
          ramConnectorStatus: IStoreApiItem<ITestStatus>,
          portStatus: IStoreApiItem<IRAMStatus>,
        ]) => data.isLoading || searchedLocation.isLoading || ramConnectorStatus.isLoading || portStatus.isLoading,
      ),
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.redirectToModuleOverviewPage();
      this.checkToUpdateTableData();
      this.updateEquipmentData();
      this.processTableData();
      this.processingEquipmentDataFromBE();
      this.processUpdatedEquipmentLocation();
      this.readRAMSettingsFromStoreAndToggleSelectedWorkflowSection();
    }, 250);

    this.checkCylinderChargerHasFinished();
    this.closeCompleteChecklistModalIfNoEquipmentSelected();
  }

  redirectToModuleOverviewPage(): void {
    if (!this.accessControlService.equipmentWorkflowPageFeatureToggle) {
      this.router.navigate(['workshop'])?.then(() => {
        return;
      });
    }

    if (
      this.accessControlService.permissions &&
      (!this.accessControlService.permissions.includes(AppUserPermissionList.equipmentView) ||
        !this.accessControlService.permissions.includes(AppUserPermissionList.equipmentTest))
    ) {
      this.router.navigate(['']);
    }
  }

  closeCompleteChecklistModalIfNoEquipmentSelected(): void {
    if (this.selectedEquipment.length < 1) {
      this.router.navigate(['workshop', 'workflow']);
    }
  }

  readRAMSettingsFromStoreAndToggleSelectedWorkflowSection(): void {
    getRAMSettingsAvailableWorkflows(this.store, this.destroy).subscribe((response: IRAMSettings) => {
      this.hideSelectedWorkflowSection = Object.values(response).every((workflow: boolean) => !workflow);
    });
  }

  readLoadingState(): void {
    this.isLoading = combineLatest([
      this.store.select(selectSavedEquipments),
      this.store.select(selectLocationHierarchy),
      this.store.select(selectWorkflowAsset),
      this.store.select(selectSearchedLocation),
      this.store.select(selectUpdateEquipmentLocationDuringBarcodeSearch),
      this.store.select(selectSendServiceInterval),
    ]).pipe(
      map(
        ([equipment, locationList, workflowAsset, searchedLocation, eqLocation, charge]: [
          IStoreApiList<IEquipment[]>,
          IStoreApiList<ILocationHierarchy[]>,
          IStoreApiItem<IEquipment>,
          IStoreApiItem<SearchedLocation>,
          IStoreApiItem<ILocationChange>,
          IStoreApiItem<ICylinderChargerRequest>,
        ]) =>
          equipment.isLoading ||
          locationList.isLoading ||
          workflowAsset.isLoading ||
          searchedLocation.isLoading ||
          eqLocation.isLoading ||
          charge.isLoading,
      ),
      takeUntil(this.destroy),
      delay(0),
    );
  }

  checkToUpdateTableData(): void {
    this.storage.watch(CommonConstants.updateWorkflowTableDataAfterChecklist).subscribe((response: unknown) => {
      if (response) {
        this.updateTableData = Boolean(response) ?? true;
        this.storage.delete(CommonConstants.updateWorkflowTableDataAfterChecklist).subscribe(() => {
          return;
        });
      }
    });

    this.storage.watch(CommonConstants.updateWorkflowFooterItemsAfterChecklist).subscribe((response: unknown) => {
      if (response) {
        this.isWorkflowInProgress = true;
        this.storage.delete(CommonConstants.updateWorkflowFooterItemsAfterChecklist).subscribe(() => {
          return;
        });
      }
    });
  }

  removeEquipmentAfterChecklist(): void {
    this.storage.watch(CommonConstants.markEquipmentToBeRemovedAfterChecklist).subscribe((response: string[]) => {
      if (response) {
        response.forEach((id: string) => {
          this.removeEquipmentIdFromStorage(id);
        });

        this.removeEquipmentFromTable();
        this.onSelectEquipment([null]);
        this.autoSelectedEquipment = null;
        this.isSearchInputFocused = true;

        const savedEquipmentsIds: string[] = this.getEquipmentIdsFromLocalStorage().map(
          (elem: IWorkflowStoredTableElement) => elem.id,
        );

        if (savedEquipmentsIds.length < 1) {
          this.equipmentList = [];
        }

        this.storage.delete(CommonConstants.markEquipmentToBeRemovedAfterChecklist).subscribe(() => {
          return;
        });
      }
    });
  }

  updateEquipmentData(): void {
    const savedEquipmentsIds: string[] = this.getEquipmentIdsFromLocalStorage().map(
      (elem: IWorkflowStoredTableElement) => elem.id,
    );

    if (savedEquipmentsIds.length) {
      this.equipmentCRUDActions.requestSavedEquipments(savedEquipmentsIds);
    }
  }

  processingEquipmentDataFromBE(): void {
    this.isLoading = of(true);
    this.store
      .pipe(
        select(selectSavedEquipments),
        rxjsFilter((state: IStoreApiList<IEquipment[]>) => {
          return !state.isLoading;
        }),
        takeUntil(this.destroy),
        delay(0),
      )
      .subscribe((equipment: IStoreApiList<IEquipment[]>) => {
        if (equipment.data) {
          this.equipmentList = structuredClone(equipment.data.map((eq: IEquipment) => new Equipment(eq)) ?? []);

          this.equipmentList.forEach((eq: IEquipment) => {
            eq.nextDueServiceDate = eq.nextDueServiceDate
              ? dateFormat(
                  new Date(eq.nextDueServiceDate).toString(),
                  getDateFormatFromUserProfile(this.translateService),
                ).split(', ')[0]
              : null;
          });

          this.equipmentList = [...this.equipmentList];

          this.equipmentCRUDActions.resetSelectedEquipmentState();

          const orderList: IWorkflowStoredTableElement[] = this.getEquipmentIdsFromLocalStorage();
          const positionMap: Partial<IEquipment> = {};

          orderList.forEach((item: IWorkflowStoredTableElement) => {
            positionMap[item.id] = item.defaultPosition;
          });

          this.equipmentList.sort(
            (a: IEquipment, b: IEquipment) => positionMap[a.aggregateId] - positionMap[b.aggregateId],
          );

          if (this.savedFilters?.sortColumn) {
            this.sortWorkflowTableDataLocally(this.savedFilters?.sortColumn.field, this.savedFilters?.sortColumn.order);
            this.sortColumn = this.savedFilters?.sortColumn as SortMeta;
          }

          this.updateTableData = false;

          this.autoSelectItemAfterEquipmentIsAdded();
        }

        this.isLoading = of(false);
      });

    this.readSelectWorkflowAssetAndProcessTheNewEquipmentData();
  }

  autoSelectItemAfterEquipmentIsAdded(): void {
    if (WorkshopConstants.equipmentAddedAfterScan in localStorage) {
      this.autoSelectedEquipment = [
        JSON.parse(localStorage.getItem(WorkshopConstants.equipmentAddedAfterScan)),
      ] as IEquipment[];

      this.onSelectEquipment(this.autoSelectedEquipment);
      localStorage.removeItem(WorkshopConstants.equipmentAddedAfterScan);
    }
  }

  readSelectWorkflowAssetAndProcessTheNewEquipmentData(): void {
    this.store
      .pipe(
        select(selectWorkflowAsset),
        rxjsFilter((state: IStoreApiItem<IEquipment>) => {
          return state.data !== null && !state.isLoading;
        }),
        take(1),
        delay(350),
      )
      .subscribe((equipment: IStoreApiItem<IEquipment>) => {
        this.newEquipment = new Equipment(equipment.data);

        if (this.newEquipment && WorkshopConstants.equipmentAdded in localStorage) {
          this.changeEquipmentLocationDuringBarcodeSearch(this.newEquipment);
          this.equipmentList = uniqBy([...this.equipmentList, this.newEquipment], 'aggregateId');

          localStorage.setItem(WorkshopConstants.equipmentAddedAfterScan, JSON.stringify(this.newEquipment));

          const savedEquipmentsIds: IWorkflowStoredTableElement[] = this.getEquipmentIdsFromLocalStorage();
          const toSaveIds: IWorkflowStoredTableElement[] = Array.from(new Set([...savedEquipmentsIds]));

          toSaveIds.push({
            id: this.newEquipment.aggregateId,
            defaultPosition: savedEquipmentsIds.length,
          });

          this.saveEquipmentIdsToLocalStorage(uniqBy(toSaveIds, 'id'));
          this.updateEquipmentData();
        }

        localStorage.removeItem(WorkshopConstants.barCodeToSave);
        localStorage.removeItem(WorkshopConstants.equipmentAdded);

        this.isLoading = of(false);
      });
  }

  getSelectedWorkflow(selectedWorkflow: number): void {
    this.currentlyWorkflowChecked = selectedWorkflow;
  }

  getSelectedLocation(selectedLocation: ILocationAssignment): void {
    this.selectedLocation = selectedLocation;
  }

  getEnteredBarcode(barcode: string): void {
    this.barcode = barcode.trimStart().trimEnd();

    this.readLoadingState();
  }

  getAddEquipmentBtnState(btnState: boolean): void {
    this.isAddEquipmentBtnEnabled = btnState;
  }

  getMobileLocationData(mobileLocationData: { isMobileSearch: boolean; mobileLocation: ILocationAssignment }): void {
    this.isMobileSearch = mobileLocationData.isMobileSearch;
    this.mobileLocation = mobileLocationData.mobileLocation;

    this.addScannedLocationWithEquipmentIntoTable();
  }

  addScannedLocationWithEquipmentIntoTable(): void {
    if (!this.selectedLocation && this.mobileLocation) {
      this.equipmentCRUDActions.requestSavedLocationEquipment(this.mobileLocation?.aggregateId);

      this.store
        .pipe(
          select(selectSavedLocationEquipments),
          rxjsFilter((state: IStoreApiList<IEquipment[]>) => state.data !== null),
          first(),
        )
        .subscribe((equipment: IStoreApiList<IEquipment[]>) => {
          const scannedEquipment: IEquipment[] = structuredClone(equipment.data);

          if (scannedEquipment.length < 1) {
            this.displayWarningNotificationForLocationWithoutEquipment();
          }

          this.equipmentDataAfterScanLocation = equipment.data;

          this.processingScannedLocationDataToAddInTable(scannedEquipment);
        });
    }
  }

  displayWarningNotificationForLocationWithoutEquipment(): boolean {
    this.notificationsService.requestShowNotification(
      CommonConstants.notificationType.WARNING,
      EquipmentNotificationConstants.notificationCodes.SCANNED_LOCATION_NOT_HAVE_EQUIPMENT,
      EquipmentNotificationConstants.notificationCodes,
    );

    this.equipmentCRUDActions.resetSavedLocationEquipmentState();

    return true;
  }

  isSearchedLocationIsInTable(): boolean {
    return this.equipmentList.some(
      (eq: IEquipment) => eq.locationAssignment.aggregateId === this.mobileLocation.aggregateId,
    );
  }

  processingScannedLocationDataToAddInTable(scannedEquipment: IEquipment[]): void {
    if (this.equipmentList.length !== scannedEquipment.length) {
      this.equipmentList.forEach((listEq: IEquipment) => {
        scannedEquipment.forEach((scannedEq: IEquipment, index: number) => {
          if (scannedEq.aggregateId === listEq.aggregateId) {
            scannedEquipment.splice(index, 1);
          }
        });
      });
    }

    if (scannedEquipment.length < 1 && this.isSearchedLocationIsInTable()) {
      this.displayWarningNotificationIfEqExist();
    }

    scannedEquipment.forEach((eqFromScan: IEquipment) => {
      const exists: boolean = this.equipmentList.some(
        (eqFromTable: IEquipment) => eqFromTable.aggregateId === eqFromScan.aggregateId,
      );

      if (!exists) {
        this.processDefaultRespnseIfEqNotExist(eqFromScan);
      }

      if (exists) {
        this.displayWarningNotificationIfEqExist();
      }

      if (scannedEquipment.length < 1 && !exists) {
        this.displayWarningNotificationIfEqExist();
      }
    });
  }

  processDefaultRespnseIfEqNotExist(eqFromScan: IEquipment): boolean {
    this.processDefaultResponse(eqFromScan, false);
    this.equipmentCRUDActions.resetSavedLocationEquipmentState();

    return true;
  }

  displayWarningNotificationIfEqExist(): boolean {
    this.notificationsService.requestShowNotification(
      CommonConstants.notificationType.WARNING,
      EquipmentNotificationConstants.notificationCodes.SEARCHED_BARCODE_ALREADY_EXIST,
      EquipmentNotificationConstants.notificationCodes,
    );
    this.isAddedInTable = false;
    this.isSearchInputFocused = true;

    this.equipmentCRUDActions.resetSavedLocationEquipmentState();

    return true;
  }

  getWorkflowEquipmentData(equipment: IEquipment): void {
    this.changeEquipmentLocationDuringBarcodeSearch(equipment);
    this.addedEquipment = equipment;
  }

  updateEquipment(): void {
    this.updateEquipmentData();
  }

  readSelectedWorkflowFromLocalStorage(): void {
    if (WorkshopConstants.selectedWorkflow in localStorage) {
      this.currentlyWorkflowChecked = Number(localStorage.getItem(WorkshopConstants.selectedWorkflow));
    }
  }

  handleTestFinish(): void {
    if (WorkshopConstants.testEnded in localStorage) {
      const equipmentID: string = localStorage.getItem(WorkshopConstants.equipmentToDelete);

      this.removeEquipmentIdFromStorage(equipmentID);

      localStorage.removeItem(WorkshopConstants.testEnded);
      localStorage.removeItem(WorkshopConstants.equipmentToDelete);
    }
  }

  removeEquipmentIdFromStorage(idToRemove: string): void {
    const localEquipments: IWorkflowStoredTableElement[] = this.getEquipmentIdsFromLocalStorage();

    this.saveEquipmentIdsToLocalStorage(
      localEquipments.filter((elem: IWorkflowStoredTableElement) => elem?.id !== idToRemove),
    );
  }

  processTableData(): void {
    this.equipmentList = structuredClone(this.equipmentList ?? []);

    this.equipmentList.forEach((eq: IEquipment) => {
      let processedDate: string = eq.nextDueServiceDate;

      if (eq.nextDueServiceDate?.includes('-')) {
        processedDate = formatOnlyDate(
          eq.nextDueServiceDate ? new Date(eq.nextDueServiceDate).toString() : null,
          getDateFormatFromUserProfile(this.translateService),
        );
      }

      eq.nextDueServiceDate = processedDate;
    });
  }

  changeEquipmentLocationDuringBarcodeSearch(equipment: IEquipment): void {
    if (this.selectedLocation) {
      const locationChange: ILocationChange = {
        locationAssignment: this.selectedLocation,
        equipmentAggregateIds: [equipment.aggregateId],
      };

      this.equipmentServiceTaskActions.requestUpdateEquipmentsLocationDuringTheBarcodeSearch(locationChange);
    } else {
      this.processEquipmentResponse(equipment);
    }
  }

  processUpdatedEquipmentLocation(): void {
    this.isLoading = of(true);
    this.store
      .pipe(
        select(selectUpdateEquipmentLocationDuringBarcodeSearch),
        rxjsFilter((equipmentsLocation: IStoreApiItem<ILocationChange>) => !equipmentsLocation.isLoading),
        takeUntil(this.destroy),
        delay(500),
      )
      .subscribe((response: IStoreApiItem<ILocationChange>) => {
        if (response.data && this.addedEquipment) {
          const eqData: IEquipment = structuredClone(this.addedEquipment);

          eqData.locationAssignment.name = response.data.locationAssignment.name;
          eqData.locationAssignment.identifier = response.data.locationAssignment.identifier;

          this.processEquipmentResponse(eqData);
          this.isLoading = of(false);
        }
      });
  }

  processEquipmentResponse(data: IEquipment): void {
    if (this.currentlyWorkflowChecked === WorkflowType.TESTING) {
      this.handleTestWorkflow(data);

      return;
    }

    if (this.currentlyWorkflowChecked === WorkflowType.CYLINDER_CHARGING) {
      this.handleCylinderChargerWorkflow(data);

      return;
    }

    if (this.currentlyWorkflowChecked === WorkflowType.CHECKING) {
      this.handleCompleteChecklistWorkflow(data);

      return;
    }

    this.processDefaultResponse(data);
  }

  handleTestWorkflow(data: IEquipment): void {
    if (data.generalData.hasProtectorType) {
      this.processTableData();
      this.startTest(data, 'workshop/workflow');
    } else {
      this.isSearchInputFocused = true;
      this.isWorkflowInProgress = this.selectedEquipment.length < 1;
      this.notificationsService.requestShowNotification(
        CommonConstants.notificationType.ERROR,
        EquipmentNotificationConstants.notificationCodes.EQUIPMENT_WITHOUT_PROTECTOR_TEST_TYPE,
        EquipmentNotificationConstants.notificationCodes,
      );
    }

    this.equipmentTestActions.resetWorkflowAssetState();
  }

  handleCylinderChargerWorkflow(data: IEquipment): void {
    if (!data.generalData.isCylinderType) {
      this.isSearchInputFocused = true;
      this.isWorkflowInProgress = this.selectedEquipment.length < 1;

      this.notificationsService.requestShowNotification(
        CommonConstants.notificationType.ERROR,
        EquipmentNotificationConstants.notificationCodes.EQUIPMENT_NOT_CYLINDER_TYPE,
        EquipmentNotificationConstants.notificationCodes,
      );

      return;
    }

    this.processDefaultResponse(data);

    if (this.isAddedInTable) {
      setTimeout(() => {
        this.checkEquipmentsStatus();
      }, 10);
    }
  }

  handleCompleteChecklistWorkflow(data: IEquipment): void {
    if (data.serviceIntervals?.some((service: IEquipmentServiceInterval) => service.hasCheckList === true)) {
      this.selectedEquipmentForChecklistWorkflow = [data];
      this.completeChecklist();
    } else {
      this.isWorkflowInProgress = this.selectedEquipment.length < 1;
      this.notificationsService.requestShowNotification(
        CommonConstants.notificationType.ERROR,
        EquipmentNotificationConstants.notificationCodes.SEARCHED_EQUIPMENT_NO_CHECKLIST,
        EquipmentNotificationConstants.notificationCodes,
      );
    }

    this.equipmentTestActions.resetWorkflowAssetState();
  }

  processDefaultResponse(data: IEquipment, displayWarning: boolean = true): void {
    localStorage.removeItem(WorkshopConstants.equipmentAdded);

    if (
      this.equipmentList?.some((equipment: IEquipment) => equipment.aggregateId === data.aggregateId) &&
      displayWarning
    ) {
      this.notificationsService.requestShowNotification(
        CommonConstants.notificationType.WARNING,
        EquipmentNotificationConstants.notificationCodes.SEARCHED_BARCODE_ALREADY_EXIST,
        EquipmentNotificationConstants.notificationCodes,
      );
      this.isAddedInTable = false;
      this.isSearchInputFocused = true;
    } else {
      this.addItemToTable(data);
      this.onSelectEquipment([data]);

      setTimeout(() => {
        /* istanbul ignore if */
        if (document.getElementsByClassName('p-datatable-wrapper')[0]) {
          document.getElementsByClassName('p-datatable-wrapper')[0].scrollTop = 5000;
        }

        this.barcode = '';
      }, 0);
    }

    this.equipmentTestActions.resetWorkflowAssetState();
  }

  onTableSort(event: SortEvent): void {
    this.setTableFilters('sortColumn', event);
    this.sortWorkflowTableDataLocally(event.field, event.order);
  }

  sortWorkflowTableDataLocally(field: string, order: number): void {
    const orderString: 'desc' | 'asc' = order === -1 ? 'desc' : 'asc';

    this.equipmentList = orderBy<IEquipment[]>(this.equipmentList, [field], [orderString]) as IEquipment[];
  }

  get savedFilters(): { sortColumn: { field: string; order: number } } {
    return this.tableFiltersKey in localStorage ? JSON.parse(localStorage.getItem(this.tableFiltersKey)) : null;
  }

  setTableFilters(field: string, value: SortEvent): void {
    this.tableService.saveFilters(field, value, this.tableFiltersKey, this.savedFilters);
  }

  addItemToTable(data: IEquipment): void {
    const autoSelectNeededInfo: PropertyBag = {
      'generalData.rfid': data.generalData.rfid,
      'generalData.owner': data.generalData.owner,
      'generalData.barcode': data.generalData.barcode,
      'generalData.identifier': data.generalData.identifier,
      'generalData.serialNo': data.generalData.serialNo,
      'generalData.partNo': data.generalData.partNo,
      'generalData.partName': data.generalData.partName,
      'generalData.description': data.generalData.description,
      'generalData.model.name': data.generalData.model.name,
      'generalData.model.type.name': data.generalData.model.type.name,
      'generalData.operationalStatus': data.generalData.operationalStatus,
      'locationAssignment.name': data.locationAssignment.name,
      'manufacturerData.organizationName': data.manufacturerData?.organizationName,
    };

    this.autoSelectedEquipment = [{ ...data, ...autoSelectNeededInfo }];
    this.equipmentList.push(this.autoSelectedEquipment[0]);
    this.processTableData();
    this.isAddedInTable = true;

    this.savingEquipmentIdsIfNoNewEqIsAdded();
    this.barcode = '';

    this.isSearchInputFocused = true;
    this.isWorkflowInProgress = false;
    this.removeEntriesFromWorkflowTable();
  }

  savingEquipmentIdsIfNoNewEqIsAdded(): void {
    if (!(WorkshopConstants.equipmentAdded in localStorage)) {
      this.saveEquipmentIdsToLocalStorageAfterSearch();
    }
  }

  removeEntriesFromWorkflowTable(): void {
    if (this.getEquipmentIdsFromLocalStorage().length > this.maximumNumberOfWorkflowTableItems) {
      this.userStorageService.removeUserLocalStorage(WorkshopConstants.workflowTable);
      this.isTableItemsLimitExceeded = true;
    }
  }

  saveEquipmentIdsToLocalStorageAfterSearch(): void {
    const ids: IWorkflowStoredTableElement[] = this.equipmentList?.map((eq: IEquipment, index: number) => {
      return { id: eq.aggregateId, defaultPosition: index };
    });

    const savedEquipmentsIds: IWorkflowStoredTableElement[] = this.getEquipmentIdsFromLocalStorage();
    const toSaveIds: IWorkflowStoredTableElement[] = Array.from(new Set([...ids, ...savedEquipmentsIds]));

    this.userStorageService.setStoredValue(WorkshopConstants.workflowTable, uniqBy(toSaveIds, 'id'));
  }

  saveEquipmentIdsToLocalStorage(currentIds: IWorkflowStoredTableElement[] = null): void {
    const ids: IWorkflowStoredTableElement[] = this.equipmentList?.map((eq: IEquipment, index: number) => {
      return { id: eq.aggregateId, defaultPosition: index };
    });

    const savedEquipmentsIds: IWorkflowStoredTableElement[] = uniqBy(Array.from(new Set([...ids])), 'id');
    const toSaveIds: IWorkflowStoredTableElement[] = currentIds ?? savedEquipmentsIds;

    if (toSaveIds && toSaveIds?.length > 0) {
      this.userStorageService.setStoredValue(WorkshopConstants.workflowTable, toSaveIds);

      return;
    }

    this.userStorageService.removeUserLocalStorage(WorkshopConstants.workflowTable);
  }

  getEquipmentIdsFromLocalStorage(): IWorkflowStoredTableElement[] {
    return this.userStorageService.getStoredValue(WorkshopConstants.workflowTable) || [];
  }

  onSelectEquipment(event: IEquipment[] = [], source?: string): void {
    if (event && event[0] === null) return;

    this.selectedEquipment = event ?? [];
    this.disableCylinderChargerBtn = false;
    this.disableChecklistBntFlow = false;
    this.isBlueBarButtonsDisabled = false;

    if (this.selectedEquipment?.length > 0) {
      this.checkIfBatchChecklistButtonShouldBeEnabled();

      this.selectedEquipment = this.processSelectedTableEqData();
    }

    if (source) {
      this.isWorkflowInProgress = false;
    }

    this.selectedEquipmentForChecklistWorkflow = this.selectedEquipment;
  }

  processSelectedTableEqData(): IEquipment[] {
    const processedSelectedEquipment: IEquipment[] = [];

    this.selectedEquipment?.forEach((task: IEquipment) => {
      this.equipmentList?.forEach((data: IEquipment) => {
        if (data.aggregateId === task?.aggregateId) {
          processedSelectedEquipment.push(task);
        }
      });
    });

    processedSelectedEquipment.forEach((selectedItem: IEquipment) => {
      if (!selectedItem?.generalData.isCylinderType) {
        this.disableCylinderChargerBtn = true;
      }
    });

    return processedSelectedEquipment;
  }

  checkIfBatchChecklistButtonShouldBeEnabled(): void {
    if (!this.selectedEquipment.length) {
      return;
    }

    const arrayOfArrays: IEquipmentServiceInterval[][] = this.selectedEquipment.map(
      (eq: IEquipment) => eq.serviceIntervals,
    );
    const isInAllArrays = (service: IEquipmentServiceInterval, arrays: IEquipmentServiceInterval[][]) =>
      arrays.every((array: IEquipmentServiceInterval[]) =>
        array.some((item: IEquipmentServiceInterval) => item.serviceId === service.serviceId),
      );

    const firstArray: IEquipmentServiceInterval[] = arrayOfArrays[0];

    const commonServices: IEquipmentServiceInterval[] = firstArray?.filter((obj: IEquipmentServiceInterval) =>
      isInAllArrays(obj, arrayOfArrays),
    );

    const allServices: IEquipmentServiceInterval[] = arrayOfArrays.flat();
    const hasChecklistInAllServices: boolean = allServices?.some((service: ITask) => service?.hasCheckList);

    const hasCheckList: boolean = commonServices?.some((service: ITask) => service.hasCheckList);

    this.displayNoChecklistTooltip = false;
    this.displayNoServiceIdTooltip = false;

    if (!hasCheckList) {
      this.disableChecklistBntFlow = true;
      this.displayNoChecklistTooltip = true;
    }

    if (commonServices?.length < 1 && hasChecklistInAllServices) {
      this.displayNoServiceIdTooltip = true;
      this.displayNoChecklistTooltip = false;
    }
  }

  markEquipmentIfHasChecklist(task: IEquipment): void {
    if (task) {
      task.generalData.hasCheckList = task.serviceIntervals.some(
        (service: IEquipmentServiceInterval) => service.hasCheckList === true,
      );
    }

    if (!task?.generalData.hasCheckList) {
      this.disableChecklistBntFlow = true;
    }
  }

  onCylinderCharge(): void {
    const preparedData: IIntervalData[] = this.prepareDataForCylinderCharging(this.selectedEquipment);
    const savedEquipmentsIds: string[] = this.getEquipmentIdsFromLocalStorage().map(
      (elem: IWorkflowStoredTableElement) => elem?.id,
    );

    if (savedEquipmentsIds.length && this.selectedLocation) {
      this.equipmentCRUDActions.requestSavedEquipments(savedEquipmentsIds);
    }

    if (this.equipmentDataAfterScanLocation) {
      localStorage.setItem(
        CommonConstants.equipmentDataAfterScanLocation,
        JSON.stringify(this.equipmentDataAfterScanLocation),
      );
    }

    this.equipmentServiceTaskActions.saveSelectedServiceEquipment(preparedData);
    this.router.navigate(['workshop', 'workflow', 'cylinder']);
  }

  prepareDataForCylinderCharging(equipments: IEquipment[]): IIntervalData[] {
    const prepared: IIntervalData[] = [];

    equipments.forEach((eq: IEquipment) => {
      const intervals: Partial<unknown[]> = eq.serviceIntervals.map((service: IEquipmentServiceInterval) => {
        return {
          interval: service.interval,
          taskId: service.serviceId,
          taskName: service.taskName,
          lastServiceDate: extractOnlyDate(service.lastTestDate as string),
          nextServiceDate: extractOnlyDate(service.nextTestDate as string),
          origin: service.origin,
        };
      });

      prepared.push({
        includedTasks: intervals as ITask[],
        others: [],
        location: eq.locationAssignment.name,
        locationPath: processLocationPath(eq.locationAssignment?.path),
        maxPressure: eq.generalData.maxPressure,
        equipmentAggregateId: eq.aggregateId,
        equipmentBarcode: eq.generalData.barcode,
        rfid: eq.generalData.rfid,
        serialNo: eq.generalData.serialNo,
        equipmentIdentification: eq.generalData.identifier,
        equipmentCategory: eq.generalData.model.category.name,
        equipmentType: eq.generalData.model.type.name,
        equipmentModel: eq.generalData.model.name,
        operationalStatus: eq.generalData.operationalStatus,
        isCylinderType: eq.generalData.isCylinderType,
        outOfServiceDate: extractOnlyDate(eq.generalData.outOfOrderDate),
        outOfServiceReason: eq.generalData.outOfServiceReason,
      });
    });

    return prepared;
  }

  redirectToEquipmentPage(): void {
    this.router.navigate(['workshop', 'update', this.selectedEquipment[0].aggregateId]);
    localStorage.removeItem(WorkshopConstants.barCodeToSave);
  }

  openSelectedEquipmentOnDblClick(event: IEquipment): void {
    this.router.navigate(['workshop', 'update', event.aggregateId]);
    localStorage.removeItem(WorkshopConstants.barCodeToSave);
  }

  startTest(data?: IEquipment, module?: string): void {
    const eqToTest: IEquipment = data ?? this.selectedEquipment[0];

    localStorage.setItem(WorkshopConstants.equipmentToDelete, eqToTest?.aggregateId);
    localStorage.setItem(WorkshopConstants.testFromWorkflowPage, 'true');

    checkRAMConnectorPort({
      ramActions: this.ramActions,
      store: this.store,
      notificationsService: this.notificationsService,
    }).then(() => {
      startTest({
        ramActions: this.ramActions,
        store: this.store,
        ramConnectorErrorsService: this.ramConnectorErrorsService,
        router: this.router,
        notificationsService: this.notificationsService,
        equipmentAggregateId: eqToTest?.aggregateId,
        module,
      }).then((response: boolean | string) => {
        this.ramConnectorStatus = checkAutoUpdateFeatureToggleBeforeStartProtectorTest(
          this.accessControlService,
          response,
          this.notificationsService,
        );

        this.resetSelectedEquipmentIfRAMConnectorStatusIsFalse();
      });
    });
  }

  resetSelectedEquipmentIfRAMConnectorStatusIsFalse(): void {
    if (
      (!this.ramConnectorStatus ||
        this.ramConnectorStatus ===
          ProtectorSoftwareNotificationConstants.notificationCodes.PROTECTOR_SOFTWARE_VERSION_NOT_SUPPORTED.value) &&
      this.selectedEquipment.length < 1
    ) {
      this.selectedEquipment = null;
    }
  }

  addNewEquipment(): void {
    this.router.navigate(['workshop', 'create']);
  }

  changeColumnVisibility(columns: ITableColumn[]): void {
    this.tableService.saveColumnsSettings(columns, this.settingsActions, AppModulesTypes.equipmentWorkflowPage);
  }

  getTableRef(event: Partial<Table>): void {
    this.workflowAssetsTable = event;
  }

  reloadTableColumnsData(columns: ITableColumn[]): void {
    this.tableColumns = columns;
  }

  /* istanbul ignore next */
  removeEquipmentFromTable(): void {
    if (this.equipmentList && this.equipmentList.length > 0) {
      this.selectedEquipment.forEach((selectedEquipment: IEquipment, parentIndex: number) => {
        this.equipmentList.forEach((eq: IEquipment, childIndex: number) => {
          if (selectedEquipment?.aggregateId === eq?.aggregateId) {
            this.equipmentList.splice(childIndex, 1);

            if (this.selectedEquipment.length < 2) {
              this.selectedEquipment.splice(parentIndex, 1);
            }
          }
        });
      });

      this.equipmentList = [...this.equipmentList];

      this.saveEquipmentIdsToLocalStorage();
      this.onSelectEquipment([null]);
      this.autoSelectedEquipment = null;
    }
  }

  checkCylinderChargerHasFinished(): void {
    combineLatest([this.store.select(selectSendServiceInterval), this.store.select(selectUpdateEquipmentsLocation)])
      .pipe(
        rxjsFilter(
          ([charge, updateLocation]: [IStoreApiItem<ICylinderChargerRequest>, IStoreApiItem<ILocationChange>]) =>
            !charge.isLoading && !updateLocation.isLoading,
        ),
        takeUntil(this.destroy),
      )
      .subscribe(
        ([charge, updateLocation]: [IStoreApiItem<ICylinderChargerRequest>, IStoreApiItem<ILocationChange>]) => {
          if (updateLocation.errors) {
            this.notificationsService.requestShowNotification(
              CommonConstants.notificationType.ERROR,
              EquipmentNotificationConstants.notificationCodes.LOCATION_CHANGED_FAILED,
              EquipmentNotificationConstants.notificationCodes,
            );

            this.equipmentServiceTaskActions.resetUpdateEquipmentsLocation();
          } else if ((charge.isSuccess && updateLocation.isSuccess) || charge.isSuccess) {
            this.removeEquipmentFromTable();
            this.onSelectEquipment([null]);
            this.autoSelectedEquipment = null;
            this.isSearchInputFocused = true;
          }
        },
      );
  }

  checkEquipmentsStatus(): void {
    if (
      this.selectedEquipment.some(
        (eq: IEquipment) => eq.generalData.operationalStatus !== this.operationalStatuses.OPERATIONAL,
      )
    ) {
      this.isConfirmCloseModalOpen = true;

      return;
    }

    this.onCylinderCharge();
  }

  userResponseConfirmBanner(response: boolean): void {
    if (response) {
      this.onCylinderCharge();
    }

    this.isConfirmCloseModalOpen = false;
    this.isSearchInputFocused = true;
  }

  completeChecklist(): void {
    localStorage.removeItem(WorkshopConstants.selectedTask);

    this.router.navigate([
      'workshop',
      'workflow',
      this.accessControlService.equipmentBatchChecklistFeatureToggle && this.selectedEquipment.length > 1
        ? WorkshopModuleRoutes.completeBatchChecklist
        : WorkshopModuleRoutes.completeChecklist,
      this.selectedEquipmentForChecklistWorkflow.map((eq: IEquipment) => eq.aggregateId).toString(),
    ]);
  }

  closeMobileLocationModal(selectedEquipment: IEquipment[]): void {
    this.mobileLocation = null;
    this.isMobileSearch = false;

    this.cylinderChargeWorkflowAfterMobileLocationChanged(selectedEquipment);

    if (this.currentlyWorkflowChecked === WorkflowType.TESTING && selectedEquipment && selectedEquipment.length > 1) {
      setTimeout(() => {
        this.notificationsService.requestShowNotification(
          CommonConstants.notificationType.WARNING,
          ConfigurationNotificationConstants.notificationCodes.WORKFLOW_FOR_ONLY_ONE_EQUIPMENT,
          ConfigurationNotificationConstants.notificationCodes,
        );
      }, 0);
    } else if (
      this.currentlyWorkflowChecked === WorkflowType.TESTING &&
      selectedEquipment &&
      selectedEquipment.length === 1 &&
      selectedEquipment[0].generalData?.hasProtectorType
    ) {
      this.startTest(selectedEquipment[0], 'workshop/workflow');
    } else if (
      this.currentlyWorkflowChecked === WorkflowType.CHECKING &&
      selectedEquipment &&
      selectedEquipment.length === 1 &&
      selectedEquipment[0].generalData?.hasCheckList
    ) {
      this.completeChecklist();
    } else if (
      this.currentlyWorkflowChecked === WorkflowType.TESTING &&
      selectedEquipment &&
      selectedEquipment.length === 1 &&
      !selectedEquipment[0].generalData?.hasProtectorType
    ) {
      setTimeout(() => {
        this.notificationsService.requestShowNotification(
          CommonConstants.notificationType.WARNING,
          ConfigurationNotificationConstants.notificationCodes.NO_WORKFLOW_FOR_SELECTED_EQUIPMENT,
          ConfigurationNotificationConstants.notificationCodes,
        );
      }, 0);
    }

    this.isSearchInputFocused = true;
  }

  cylinderChargeWorkflowAfterMobileLocationChanged(selectedEquipment: IEquipment[]): void {
    if (this.currentlyWorkflowChecked === WorkflowType.CYLINDER_CHARGING && selectedEquipment) {
      this.selectedEquipment = filter(selectedEquipment, (equipment: IEquipment) => {
        return equipment.generalData.isCylinderType;
      });

      if (
        selectedEquipment.some((equipment: IEquipment) => {
          return !equipment.generalData.isCylinderType;
        })
      ) {
        setTimeout(() => {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.WARNING,
            ConfigurationNotificationConstants.notificationCodes.EQUIPMENTS_WITHOUT_CYLINDER_TEST_TYPE,
            ConfigurationNotificationConstants.notificationCodes,
          );
        }, 0);
      } else {
        this.updateTableData = false;
        const savedEquipmentsIds: string[] = this.selectedEquipment.map((eq: IEquipment) => {
          return eq.aggregateId;
        });

        if (savedEquipmentsIds.length) {
          this.equipmentCRUDActions.requestSavedEquipments(savedEquipmentsIds);
          this.checkEquipmentsStatus();
          this.autoSelectedEquipment = [...this.selectedEquipment];
        }
      }
    }
  }

  startRAMConnectorAutoUpdateFromWorkflowPage(event: boolean): void {
    this.ramConnectorStatus = null;
    this.startAutoUpdate = event;
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/dot-notation
    super['ngOnDestroy'] && super['ngOnDestroy']();

    this.equipmentList = [];
    this.equipmentCRUDActions.resetSavedEquipments();
    this.equipmentTestActions.resetWorkflowAssetState();
  }
}
