import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, map, of, take, takeUntil } from 'rxjs';
import {
  IApplicationState,
  IErrorCodesForCSV,
  IStoreApiItem,
  ITableColumn,
  NotificationsService,
  PropertyBag,
  StorageConstants,
  TableFilters,
} from 'src/app/common';
import { BAWearerTableFiltersConstants, CommonConstants, NotificationConstants } from 'src/app/common/constants';
import {
  formatLocaleTime,
  processEndDateForTableFilter,
  processStartDateForTableFilter,
  TableHelperComponent,
} from 'src/app/common/utils';
import { AppModulesTypes } from 'src/app/root/models';
import { BAWearerConstants, BAWearerModuleRoutes, BAWearerNotificationConstants } from '../../constants';
import { IBAWearer, IBAWearerPage } from '../../models';
import { BAWearerActions } from '../../state/actions/ba-wearer.actions';
import { selectBAWearerPage, selectCSVFile, selectDeletedBAWearer } from './../../state/selectors/ba-wearer.selector';
import { baWearerImportCSVColumns, tableColumns } from './table-columns';
import { readModelSortKeys, tableCustomFiltersLayout, tableDefaultSorting } from './table-settings';

@Component({
  selector: 'ignis-ba-wearer-list',
  templateUrl: './ba-wearer-list.component.html',
  providers: [DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaWearerListComponent extends TableHelperComponent implements OnInit {
  @Input() formatDate: string;

  @ViewChild('fileInput', { read: ElementRef })
  public fileInput: ElementRef;

  tableName: string = AppModulesTypes.baWearer;
  localTableColumns: ITableColumn[] = tableColumns;
  list: IBAWearer[];
  customFilterLayouts: string[] = tableCustomFiltersLayout;
  defaultSorting: string = tableDefaultSorting;
  tableFiltersKey: string = StorageConstants.tablesStorageKeys.BA_WEARER;
  readModelSortKeys: PropertyBag = readModelSortKeys;
  selectedBAWearer: IBAWearer;
  openConfirmationDeleteDialog: boolean = false;
  activateClickOutside: boolean = true;
  httpCustomErrorCode: string | number;

  importCSVColumns: ITableColumn[] = baWearerImportCSVColumns;
  importCSVErrorCodes: IErrorCodesForCSV = BAWearerConstants.errorCodesForCSVFile;
  pageUrl: string = BAWearerModuleRoutes.baWearerOverview;
  openModal: boolean = false;
  disableFooterBtns: boolean = false;

  baWearerActions: BAWearerActions = inject(BAWearerActions);
  datePipe: DatePipe = inject(DatePipe);
  router: Router = inject(Router);
  notificationsService: NotificationsService = inject(NotificationsService);
  store: Store<IApplicationState> = inject(Store<IApplicationState>);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  constructor() {
    super();

    this.tableHelperReadSavedFiltersValues(null, BAWearerTableFiltersConstants.selectedDatesFilterValues);
  }

  ngOnInit(): void {
    this.initiateLoadingSubscription();
    this.initiateBAWearerPageSubscription();
  }

  initiateLoadingSubscription(): void {
    this.isLoading$ = this.store.pipe(
      select(selectBAWearerPage),
      takeUntil(this.destroy),
      map((state: IStoreApiItem<IBAWearerPage>) => state.isLoading),
    );
  }

  initiateBAWearerPageSubscription(): void {
    this.store
      .pipe(
        select(selectBAWearerPage),
        map((state: IStoreApiItem<IBAWearerPage>) => state.data),
        takeUntil(this.destroy),
      )
      .subscribe((response: IBAWearerPage) => {
        if (response) {
          this.list = structuredClone(response.entries);
          this.totalRecords = response.totalRecords;
          this.totalPages = response.totalPages;

          this.tableHelperCheckOutOfRangePageFilter();

          this.list.forEach((baWearer: IBAWearer) => {
            baWearer.displayLastUpdate = baWearer.lastUpdate
              ? `${this.datePipe.transform(baWearer.lastUpdate, this.formatDate)} ${formatLocaleTime(new Date(baWearer.lastUpdate))}`
              : null;
          });
        }

        this.cdr.detectChanges();
      });
  }

  fetchTableData(params: PropertyBag): void {
    this.baWearerActions.requestBAWearerPage(params);
  }

  getFilterTableValue(event: TableFilters): void {
    if ('displayLastUpdate' in event) {
      event = this.processLastUpdateFilter(event, 'displayLastUpdate', 'startLastUpdate', 'endLastUpdate');
    }

    Object.assign(this.filtersData, event);

    this.tableHelperSetAllTableFilters(event);
  }

  processLastUpdateFilter(event: PropertyBag, oldParamName: string, minParamName: string, maxParamName: string): any {
    if (oldParamName in event && event[oldParamName] && event[oldParamName][1]) {
      if (Object.values(event)[0] !== 'reset-date') {
        return {
          ...this.tableService.tableDateParameter(
            processStartDateForTableFilter(new Date(Object.values(event)[0][0])),
            minParamName,
          ),
          ...this.tableService.tableDateParameter(
            processEndDateForTableFilter(new Date(Object.values(event)[0][1])),
            maxParamName,
          ),
        };
      } else {
        return {
          [oldParamName]: null,
          [minParamName]: null,
          [maxParamName]: null,
        };
      }
    }
  }

  changeColumnVisibility(columns: ITableColumn[]): void {
    this.tableHelperResetTablePaging();

    columns.forEach((column: ITableColumn) => {
      if (!column.visible) {
        if (this.tableHelperCheckForSorting(column.field)) {
          this.tableHelperResetTableSorting();
        }

        this.tableHelperHideAndResetTableColumn(this.localTableColumns, column);
        this.tableService.resetTableSort(column.field, 'ASC', this.tableRef, this.loadDataEvent, 'personalId');
      }
    });

    this.tableHelperReadSavedFiltersValues(null, BAWearerTableFiltersConstants.selectedDatesFilterValues);
    this.tableHelperSaveTableSettings(columns);
    this.tableHelperSetAllTableFilters(this.filtersData);

    this.tableHelperPrepareTableParameters();
  }

  onBAWearerSelect(baWearer: IBAWearer): void {
    this.selectedBAWearer = baWearer;
  }

  createBAWearer(): void {
    this.router.navigate([BAWearerModuleRoutes.baWearerOverview, BAWearerModuleRoutes.createBAWearer]);
  }

  editBAWearer(baWearer: IBAWearer): void {
    this.router.navigate([
      BAWearerModuleRoutes.baWearerOverview,
      BAWearerModuleRoutes.updateBAWearer,
      baWearer.aggregateId,
    ]);
  }

  openDeleteBAWearerDialog(): void {
    this.openConfirmationDeleteDialog = true;
    this.activateClickOutside = false;
  }

  closeDeleteBAWearerDialog(confirmation: boolean): void {
    if (confirmation) {
      this.deleteBAWearer();
    } else {
      this.openConfirmationDeleteDialog = false;
      this.activateClickOutside = true;
    }
  }

  deleteBAWearer(): void {
    this.baWearerActions.requestDeleteBAWearer({
      aggregateId: this.selectedBAWearer.aggregateId,
      version: this.selectedBAWearer.version,
    });

    this.initBAWearerDeletionSubscription();
  }

  initBAWearerDeletionSubscription(): void {
    this.store
      .pipe(
        select(selectDeletedBAWearer),
        filter((baWearer: IStoreApiItem<IBAWearer>) => !baWearer.isLoading),
        take(1),
      )
      .subscribe((addressBooks: IStoreApiItem<IBAWearer>) => {
        if (addressBooks.isSuccess) {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            BAWearerNotificationConstants.notificationCodes.DELETE_BA_WEARER_SUCCESS,
            BAWearerNotificationConstants.notificationCodes,
            null,
            BAWearerConstants.notificationDescriptionTransKey,
          );
          this.tableHelperPrepareTableParameters();
          this.onBAWearerSelect(null);
        } else {
          this.httpCustomErrorCode = addressBooks.errors?.error.code.toString();

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.ERROR,
            this.httpCustomErrorCode,
            BAWearerNotificationConstants.notificationCodes,
          );
        }

        this.cdr.detectChanges();
      });
  }

  downloadCSVTemplate(): void {
    window.open('/assets/BA-Wearer-Import-CSV-Template.csv');
  }

  uploadCSVFile(event: File[]): void {
    this.isLoading$ = of(true);
    this.disableFooterBtns = true;
    const formData: FormData = new FormData();

    formData.append('file', event[0], event[0].name);

    this.baWearerActions.uploadCSVFile(formData);

    this.fileInput.nativeElement.value = '';

    this.checkUploadedBAWearers();
  }

  checkUploadedBAWearers(): void {
    this.store
      .pipe(
        select(selectCSVFile),
        filter((response: IStoreApiItem<unknown>) => !response.isLoading),
        take(1),
      )
      .subscribe((response: IStoreApiItem<unknown>) => {
        this.disableFooterBtns = false;
        this.isLoading$ = of(false);
        this.cdr.detectChanges();

        if (response.errors) {
          if (Array.isArray(response.errors?.error?.data)) {
            this.openModal = true;

            this.httpCustomErrorCode = response.errors?.error?.data.map((error) => {
              return {
                ...error,
                code: error.code === 1 && error.invalidColumn ? error.invalidColumn : error.code,
              };
            });

            this.notificationsService.requestShowNotification(
              CommonConstants.notificationType.HIDDEN,
              NotificationConstants.commonCodes.UNEXPECTED_ERROR,
              BAWearerNotificationConstants.notificationCodes,
            );
          } else {
            this.notificationsService.requestShowNotification(
              CommonConstants.notificationType.ERROR,
              response.errors?.error?.code?.toString(),
              BAWearerNotificationConstants.notificationCodes,
            );
          }

          return;
        }

        this.notificationsService.requestShowNotification(
          CommonConstants.notificationType.SUCCESS,
          BAWearerNotificationConstants.notificationCodes.UPLOAD_BA_WEARERS_CSV_SUCCESS,
          BAWearerNotificationConstants.notificationCodes,
          null,
          BAWearerConstants.notificationDescriptionTransKey,
        );

        this.tableHelperPrepareTableParameters();
      });
  }

  closeModal(event: boolean): void {
    this.openModal = event;
  }
}
