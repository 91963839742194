<ignis-progress-bar *ngIf="isLoading"></ignis-progress-bar>

<ngx-simplebar class="incident-analysis-container">
  <div id="incident-analysis">
    <div class="c-update-incident-wrapper incident-timeline-wrapper" #timelineWrapper>
      <div class="uib-accordion mt-3">
        <div class="uib-accordion__section">
          <input
            class="uib-accordion__toggle"
            type="checkbox"
            checked="checked"
            disabled
            id="incident-event-timeline-accordion"
            data-test-id="incident_analysis.timeline_accordion"
          />
          <div class="uib-accordion__label timeline-label" for="incident-event-timeline-accordion">
            <div class="col-6 accordion-grid-label incident-event-timeline-title">
              {{ 'INCIDENT_ANALYSIS.STR_INCIDENT_EVENT_TIMELINE' | translate }}
            </div>
            <div class="col-6 accordion-grid-right-label">
              {{ 'INCIDENT_ANALYSIS.STR_START' | translate }}:
              {{ incidentAnalysis?.startTime ? dateFormat(incidentAnalysis?.startTime, formatDate) : '' }}
              <odx-icon size="inline" name="remove"></odx-icon>
              {{ 'INCIDENT_ANALYSIS.STR_END' | translate }}:
              {{ incidentAnalysis?.endTime ? dateFormat(incidentAnalysis?.endTime, formatDate) : '' }}
            </div>
          </div>
          <div class="uib-accordion__content">
            <div class="reload-timeline-mask" *ngIf="isLoading"></div>
            <div class="left-shadow" id="incident-timeline-left-shadow"></div>
            <div class="right-shadow" id="incident-timeline-right-shadow"></div>
            <ul class="uib-list ecp-timeline-list" #timelineList>
              <li
                class="uib-list__item"
                *ngFor="let ecp of incidentAnalysis?.incidentTimeline.ecps; index as i; let last = last"
                [style.width.px]="
                  last
                    ? getIncidentListWidthDependingOnEventLength()
                    : getIncidentListWidthDependingOnEventLength() - timelinesDashLineOffset
                "
              >
                <div class="ecp-description" [class]="currentAppTheme">
                  <div class="vertical-gap-left"></div>
                  {{ ecp.name ? ecp.name : ecp.appId ? ecp.appId : '-' }}
                  <div class="horizontal-gap-bottom"></div>
                  <div class="horizontal-gap-top"></div>
                </div>

                <ignis-event-timeline
                  class="fg-chart"
                  [currentAppTheme]="currentAppTheme"
                  [alarmData]="
                    prepareIncidentTimelineChartData(
                      ecp.alarms,
                      ecp.eventMarkerClusters,
                      ecp.mediaAssets,
                      ecp.incidentEventMarkerClusters
                    )
                  "
                  [startDate]="convertToDateAndStripSeconds(incidentAnalysis?.startTime)"
                  [endDate]="addMinutes(convertToDateAndStripSeconds(incidentAnalysis?.endTime), 2)"
                  [numberEcps]="incidentAnalysis?.incidentTimeline.ecps.length"
                  [activateClickOutside]="activateClickOutside"
                  [currentEcpIndex]="i + 1"
                  [formatDate]="formatDate"
                  [timelineWidth]="getIncidentTimelineWidthDependingOnEventLength()"
                  [timelineList]="timelineList"
                  [alarmLines]="
                    calculateNumberOfAlarmLines(
                      ecp.alarms,
                      ecp.eventMarkerClusters,
                      ecp.mediaAssets,
                      ecp.incidentEventMarkerClusters
                    )
                  "
                  (getManualEvent)="getManualEvent($event)"
                ></ignis-event-timeline>
              </li>
            </ul>
            <div
              *ngIf="!isLessThanOneHour(incidentAnalysis?.startTime, incidentAnalysis?.endTime)"
              class="ecp-timeline-scrollbar"
              [class]="currentAppTheme"
              #ecpTimelineScroll
              (scroll)="timelineList.scrollLeft = ecpTimelineScroll.scrollLeft"
              data-test-id="incident_analysis.left_timeline_scroll"
            >
              <div [style.width.px]="getIncidentTimelineWidthDependingOnEventLength() + 60"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="c-create-incident-analysis__wrapper">
      <div id="ecp-timeline-scroll">
        <div class="c-update-incident-wrapper">
          <div class="c-form-wrapper pb-0">
            <div class="uib-content-section__content">
              <div class="uib-accordion">
                <div
                  class="uib-accordion__section"
                  *ngFor="let ecp of incidentAnalysis?.ecpTimelines.ecps; index as j"
                  id="{{ 'ecp-' + j }}"
                  [attr.data-test-id]="'incident_analysis.ecp-' + j"
                  [ngClass]="{ 'mt-20': j > 0 }"
                >
                  <input
                    class="uib-accordion__toggle"
                    type="checkbox"
                    [attr.data-test-id]="'incident_analysis.ecp_timeline_accordion-' + j"
                    id="{{ 'ecp-timeline-accordion' + j }}"
                    [checked]="
                      !ecpTimelineIsOpen.length ? true : ecpTimelineIsOpen.length && ecpTimelineIsOpen[j] ? true : false
                    "
                    (change)="ecpTimelineOpen($event, j)"
                  />
                  <label class="uib-accordion__label ecp-timeline-label" for="{{ 'ecp-timeline-accordion' + j }}">
                    <div class="col-6 accordion-grid-label title-margins ecp-timeline-title-fs">
                      <span
                        >{{ 'INCIDENT_ANALYSIS.STR_ECP_EVENT_TIMELINE' | translate }}
                        <span *ngIf="ecp.name || ecp.appId"> - </span>
                      </span>
                      <span [title]="ecp.name ? ecp.name : ecp.appId">{{ ecp.name ? ecp.name : ecp.appId }}</span>
                    </div>
                    <div class="col-6 accordion-grid-right-label title-margins">
                      <div
                        class="manually-closed-incident"
                        [pTooltip]="'INCIDENT_ANALYSIS.STR_INCIDENT_CLOSED_MANUALLY_TOOLTIP' | translate"
                        tooltipPosition="left"
                        tooltipStyleClass="left-tooltip"
                        *ngIf="ecp.baSets.length < 1"
                        data-test-id="incident_analysis.manually_closed_incident"
                      >
                        <odx-icon size="inline" name="feedback-warning"></odx-icon>
                      </div>

                      {{ 'INCIDENT_ANALYSIS.STR_START' | translate }}:
                      {{ ecp.startTime ? dateFormat(ecp.startTime, formatDate) : '' }}
                      <odx-icon size="inline" name="remove"></odx-icon>
                      {{ 'INCIDENT_ANALYSIS.STR_END' | translate }}:
                      {{ ecp.endTime ? dateFormat(ecp.endTime, formatDate) : '' }}
                    </div>
                  </label>
                  <div
                    class="timeline-scrollbar"
                    [class]="currentAppTheme"
                    #timelineScroll
                    (scroll)="horizontalTimelineScroll(ecpsList, timelineScroll, baSetsList)"
                    data-test-id="incident_analysis.horizontal_timeline_scroll"
                  >
                    <div
                      [style.width.px]="
                        getEcpListWidthDependingOnEventLength(ecp.endTime, ecp.startTime) - ecpTimelineOffset
                      "
                    ></div>
                  </div>
                  <div class="uib-accordion__content">
                    <div class="reload-timeline-mask" *ngIf="isLoading"></div>
                    <ngx-simplebar
                      [options]="scrollbarOptions"
                      id="{{ 'ecp-content-scroll' + j }}"
                      [attr.data-test-id]="'incident_analysis.ecp_content_scroll-' + j"
                      class="ecp-content-scrollbar"
                    >
                      <div class="left-shadow" id="{{ 'ecp-timeline-left-shadow-' + j }}"></div>
                      <div class="right-shadow" id="{{ 'ecp-timeline-right-shadow-' + j }}"></div>
                      <ul class="uib-list mb-0 ecps-list" #ecpsList id="{{ 'ecpsList' + j }}">
                        <li
                          class="uib-list__item"
                          *ngFor="let baSet of ecp.filteredBaSets; index as i; let last = last"
                          [style.width.px]="
                            last
                              ? getEcpListWidthDependingOnEventLength(ecp.endTime, ecp.startTime)
                              : getEcpListWidthDependingOnEventLength(ecp.endTime, ecp.startTime) -
                                ecpTimelinesDashLineOffset
                          "
                        >
                          <div class="ecp-description" [class]="currentAppTheme">
                            <div class="vertical-gap-left"></div>
                            {{ baSet.equipmentSerialNumber }}
                            <br />
                            <span class="me-2 team-lead-icon" *ngIf="baSet?.teamLeadBaSet">&#9733;</span>
                            {{ baSet.firefighterName }}
                            <div class="horizontal-gap-bottom no-border-bottom"></div>
                            <div class="horizontal-gap-top" *ngIf="displayHorizontalGap"></div>
                          </div>

                          <ignis-ecp-timeline
                            class="fg-chart"
                            [currentAppTheme]="currentAppTheme"
                            [alarmData]="prepareEcpTimelineChartData(baSet.ecpAlarmRow)"
                            [startDate]="convertToDateAndStripSeconds(ecp.startTime)"
                            [endDate]="addMinutes(convertToDateAndStripSeconds(ecp.endTime), 2)"
                            [numberBaSets]="ecp.filteredBaSets.length"
                            [currentBaSetIndex]="i + 1"
                            [currentEventTimelineIndex]="j"
                            [formatDate]="formatDate"
                            [totalAlarms]="ecpTimelineTotalAlarms"
                            [timelineVisibleWidth]="ecpTimelineVisibleWidth"
                            [timelineLeftContentWidth]="ecpTimelineLeftContentWidth"
                            [minuteToPixel]="ecpTimelineMinuteToPixel"
                            [timelineWidth]="getEcpTimelineWidthDependingOnEventLength(ecp.endTime, ecp.startTime)"
                            [activationTimes]="baSet.activationTimes"
                            [deactivationTimes]="baSet.deactivationTimes"
                            [ecpsList]="ecpsList"
                            [showEcpShadows]="showEcpShadows"
                            (timelineIsLoading)="ecpTimelineLoaded($event)"
                          ></ignis-ecp-timeline>
                        </li>
                      </ul>
                      <ul class="uib-list mb-0" #baSetsList>
                        <li class="ba-set-list" *ngFor="let baSet of ecp.baSets; index as i">
                          <div class="uib-accordion__section ba-set-section">
                            <input
                              class="uib-accordion__toggle"
                              type="checkbox"
                              id="{{ 'ba-set-accordion' + i + ecp.appId }}"
                              [attr.data-test-id]="'incident_analysis.ba_set_accordion-' + i"
                              [checked]="
                                !baSetIsOpen.isOpen.length
                                  ? false
                                  : baSetIsOpen.isOpen.length && baSetIsOpen.isOpen[i + ecp.appId]
                                    ? true
                                    : false
                              "
                              (change)="toggleBaSetAccordion($event, i + ecp.appId, i, j)"
                            />
                            <label
                              class="uib-accordion__label timeline-label"
                              [ngClass]="{ 'disabled-color': baSet.teamId === '0' }"
                              for="{{ 'ba-set-accordion' + i + ecp.appId }}"
                              [style.paddingRight.px]="80"
                            >
                              <span
                                *ngIf="baSet.equipmentModel || !baSet.equipmentModel"
                                [ngSwitch]="baSet.equipmentModel"
                                class="equipment-type-icon"
                              >
                                <odx-icon
                                  *ngSwitchCase="'airboss'"
                                  (mouseenter)="showAirbossTooltip = 'ba-set-accordion' + i + ecp.appId"
                                  (mouseleave)="showAirbossTooltip = ''"
                                  size="inline"
                                  name="scba"
                                  data-test-id="incident_analysis.accordion_airboss_model"
                                  [pTooltip]="
                                    showAirbossTooltip === 'ba-set-accordion' + i + ecp.appId
                                      ? airbossTooltipContent
                                      : null
                                  "
                                  tooltipPosition="right"
                                  tooltipStyleClass="right-tooltip"
                                ></odx-icon>
                                <ng-template #airbossTooltipContent>
                                  <div data-test-id="incident_analysis.airboss_tooltip">
                                    {{ 'EQUIPMENT_MODEL.STR_AIRBOSS' | translate }}
                                  </div>
                                </ng-template>

                                <odx-icon
                                  *ngSwitchCase="'proair'"
                                  (mouseenter)="showProairTooltip = 'ba-set-accordion' + i + ecp.appId"
                                  (mouseleave)="showProairTooltip = ''"
                                  size="inline"
                                  name="ldba"
                                  data-test-id="incident_analysis.proair_model"
                                  [pTooltip]="
                                    showProairTooltip === 'ba-set-accordion' + i + ecp.appId
                                      ? proairTooltipContent
                                      : null
                                  "
                                  tooltipPosition="right"
                                  tooltipStyleClass="right-tooltip"
                                >
                                </odx-icon>
                                <ng-template #proairTooltipContent>
                                  <div data-test-id="incident_analysis.proair_tooltip">
                                    {{ 'EQUIPMENT_MODEL.STR_PROAIR' | translate }}
                                  </div>
                                </ng-template>

                                <odx-icon
                                  *ngSwitchCase="'stand_alone'"
                                  (mouseenter)="showStandAloneTooltip = 'ba-set-accordion' + i + ecp.appId"
                                  (mouseleave)="showStandAloneTooltip = ''"
                                  size="inline"
                                  name="telemetry-disabled"
                                  data-test-id="incident_analysis.stand_alone_model"
                                  [pTooltip]="
                                    showStandAloneTooltip === 'ba-set-accordion' + i + ecp.appId
                                      ? standAloneTooltipContent
                                      : null
                                  "
                                  tooltipPosition="right"
                                  tooltipStyleClass="right-tooltip"
                                ></odx-icon>
                                <ng-template #standAloneTooltipContent>
                                  <div data-test-id="incident_analysis.stand_alone_tooltip">
                                    {{ 'EQUIPMENT_MODEL.STR_STAND_ALONE' | translate }}
                                  </div>
                                </ng-template>
                              </span>

                              <div class="col-6 accordion-grid-label">
                                {{ baSet.equipmentSerialNumber }}
                                <br />
                                <span class="me-2 team-lead-icon" *ngIf="baSet?.teamLeadBaSet">&#9733;</span>
                                {{ baSet.firefighterName }}
                                <ng-container *ngIf="baSet.teamName && baSet.teamId !== '0'">
                                  - {{ baSet.teamName }}
                                </ng-container>
                                <ng-container *ngIf="baSet.teamId === '0'">
                                  - {{ 'INCIDENT_ANALYSIS.STR_ON_STANDBY' | translate }}
                                </ng-container>
                              </div>
                              <div class="col-6 accordion-grid-right-label">
                                <div *ngIf="isIncidentTypeHazmat" [formGroup]="incidentForm">
                                  <span
                                    class="hazmat-checkbox-group"
                                    formArrayName="firefighterHazmatFlags"
                                    *ngFor="let hazmat of firefighterHazmatFlags.controls; let i = index"
                                  >
                                    <odx-icon
                                      name="toxic"
                                      size="inline"
                                      *ngIf="
                                        hazmat.value.equipmentSerialNumber === baSet.equipmentSerialNumber &&
                                        hazmat.value.firefighterId === baSet.firefighterId &&
                                        hazmat.value.appId === ecp.appId
                                      "
                                    ></odx-icon>
                                    <div
                                      class="uib-checkbox"
                                      [attr.data-test-id]="'incident_analysis.hazmat_checkbox-' + i"
                                      [formGroupName]="i"
                                      *ngIf="
                                        hazmat.value.equipmentSerialNumber === baSet.equipmentSerialNumber &&
                                        hazmat.value.firefighterId === baSet.firefighterId &&
                                        hazmat.value.appId === ecp.appId
                                      "
                                    >
                                      <input
                                        type="checkbox"
                                        class="uib-checkbox__input custom-checkbox"
                                        formControlName="isHazmat"
                                      />
                                      <span class="uib-checkbox__checkmark"></span>
                                    </div>
                                  </span>
                                </div>

                                <span>
                                  {{
                                    'INCIDENT_ANALYSIS.STR_BA_USAGE_DURATION'
                                      | translate: { baWearerDuration: baSet.baWearerDuration }
                                  }}
                                </span>
                              </div>
                            </label>
                            <div class="uib-accordion__content">
                              <div class="reload-timeline-mask" *ngIf="isLoading"></div>
                              <div
                                class="left-shadow ba-set-timeline-left-shadow"
                                id="{{ 'ba-set-timeline-left-shadow-' + j + i }}"
                              ></div>
                              <div
                                class="right-shadow ba-set-timeline-right-shadow"
                                id="{{ 'ba-set-timeline-right-shadow-' + j + i }}"
                              ></div>
                              <ul class="uib-list mb-0 ba-set-item" #baSetItem>
                                <li
                                  class="uib-list__item"
                                  [style.width.px]="
                                    getBaSetListWidthDependingOnEventLength(ecp.endTime, ecp.startTime, i, j)
                                  "
                                  [style.zIndex]="1"
                                >
                                  <div class="ba-set-description" [class]="currentAppTheme">
                                    <div class="vertical-gap-left"></div>
                                    {{ 'INCIDENT_ANALYSIS.PRESSURE' | translate }}
                                    <div class="horizontal-gap-bottom"></div>
                                    <div class="horizontal-gap-top"></div>
                                  </div>

                                  <ignis-pressure-timeline
                                    class="fg-chart"
                                    [currentAppTheme]="currentAppTheme"
                                    [pressureData]="baSet.pressures"
                                    [startDate]="convertToDateAndStripSeconds(ecp.startTime)"
                                    [endDate]="addMinutes(convertToDateAndStripSeconds(ecp.endTime), 2)"
                                    [formatDate]="formatDate"
                                    [timelineVisibleWidth]="baSetTimelineVisibleWidth"
                                    [timelineLeftContentWidth]="baSetTimelineLeftContentWidth"
                                    [minuteToPixel]="baSetTimelineMinuteToPixel"
                                    [timelineWidth]="
                                      getBaSetTimelineWidthDependingOnEventLength(ecp.endTime, ecp.startTime)
                                    "
                                    [ecpsList]="ecpsList"
                                    [currentBaSet]="i"
                                    [currentEventTimelineIndex]="j"
                                    [pressureDisplayUnit]="pressureDisplayUnit | async"
                                  ></ignis-pressure-timeline>
                                </li>
                                <li
                                  class="uib-list__item"
                                  [style.width.px]="
                                    getEcpListWidthDependingOnEventLength(ecp.endTime, ecp.startTime, i)
                                  "
                                >
                                  <div class="ba-set-description" [class]="currentAppTheme">
                                    <div class="vertical-gap-left"></div>
                                    {{ 'INCIDENT_ANALYSIS.ALARMS' | translate }}
                                    <div class="horizontal-gap-bottom"></div>
                                    <div class="horizontal-gap-top"></div>
                                  </div>

                                  <ignis-ecp-timeline
                                    class="fg-chart ba-set-ecp-timeline"
                                    [currentAppTheme]="currentAppTheme"
                                    [alarmData]="prepareEcpTimelineChartData(baSet.firefighterAlarmRow)"
                                    [startDate]="convertToDateAndStripSeconds(ecp.startTime)"
                                    [endDate]="addMinutes(convertToDateAndStripSeconds(ecp.endTime), 2)"
                                    [numberBaSets]="ecp.baSets.length"
                                    [currentBaSetIndex]="i + 1"
                                    [formatDate]="formatDate"
                                    [totalAlarms]="ecpTimelineTotalAlarms"
                                    [timelineVisibleWidth]="ecpTimelineVisibleWidth"
                                    [timelineLeftContentWidth]="ecpTimelineLeftContentWidth"
                                    [minuteToPixel]="ecpTimelineMinuteToPixel"
                                    [timelineWidth]="
                                      getEcpTimelineWidthDependingOnEventLength(ecp.endTime, ecp.startTime)
                                    "
                                    [activationTimes]="baSet.activationTimes"
                                    [deactivationTimes]="baSet.deactivationTimes"
                                    [ecpsList]="ecpsList"
                                    [baSetItem]="baSetItem"
                                    [showEcpShadows]="showEcpShadows"
                                    [isAlarmTimeline]="true"
                                    [style.height.px]="calculateEcpTimelineHeight(baSet.firefighterAlarmRow)"
                                  ></ignis-ecp-timeline>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </ngx-simplebar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ngx-simplebar>
