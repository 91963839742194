<form [formGroup]="mergeIncidentsForm">
  <ng-template uibModal #mergeIncidentsModal let-modal="close">
    <div data-test-id="merge_incidents.confirm_modal">
      <uib-modal-header> {{ 'MERGE_INCIDENTS.STR_TITLE' | translate }} </uib-modal-header>
      <uib-modal-content class="custom-container">
        <p>
          {{ 'MERGE_INCIDENTS.STR_DESCRIPTION' | translate }}
        </p>

        <div class="row">
          <div class="col-6 mb-3">
            <h5>
              {{ 'MERGE_INCIDENTS.STR_INCIDENT_ONE' | translate
              }}<span *ngIf="selectedIncidents[0].primaryEcp.location.address; else displayIncidentNumber1"
                >: "{{ selectedIncidents[0].primaryEcp.location.address }}"</span
              >

              <ng-template #displayIncidentNumber1>
                <span *ngIf="selectedIncidents[0].incidentNumber">: "{{ selectedIncidents[0].incidentNumber }}"</span>
              </ng-template>
            </h5>
          </div>

          <div class="col-6 mb-3">
            <h5>
              {{ 'MERGE_INCIDENTS.STR_INCIDENT_TWO' | translate }}
              <span *ngIf="selectedIncidents[1].primaryEcp.location.address; else displayIncidentNumber2"
                >: " {{ selectedIncidents[1].primaryEcp.location.address }}"</span
              >

              <ng-template #displayIncidentNumber2>
                <span *ngIf="selectedIncidents[1].incidentNumber">: "{{ selectedIncidents[1].incidentNumber }}"</span>
              </ng-template>
            </h5>
          </div>

          <div class="col-6">
            <div class="col-md-9">
              <div class="uib-form-filed uib-form-field--horizontal">
                <label class="uib-form-field__label">
                  <odx-icon size="inline" name="document"></odx-icon>
                  {{ 'MERGE_INCIDENTS.STR_INCIDENT_DATE' | translate }}
                </label>
                <div class="uib-textfield">
                  <input
                    type="text"
                    ignisDisableAutocomplete
                    id="first_date"
                    data-test-id="merge_incidents.first_incident_date"
                    formControlName="first_date"
                    class="uib-textfield__input"
                  />
                </div>
              </div>
            </div>

            <div class="col-md-5 mt-3">
              <div class="uib-form-filed uib-form-field--horizontal">
                <label class="uib-form-field__label">
                  <odx-icon size="inline" name="document"></odx-icon>
                  {{ 'MERGE_INCIDENTS.STR_INCIDENT_NUMBER' | translate }}
                </label>
                <div class="uib-textfield custom-input-width">
                  <input
                    type="text"
                    ignisDisableAutocomplete
                    id="first_number"
                    data-test-id="merge_incidents.first_incident_number"
                    formControlName="first_number"
                    class="uib-textfield__input"
                  />
                </div>
              </div>
            </div>

            <div class="col-md-12 mt-3">
              <div class="row">
                <div class="col-md-5">
                  <div class="uib-form-filed uib-form-field--horizontal">
                    <label class="uib-form-field__label">
                      <span class="baselocation-icon" [class]="currentAppTheme"></span>
                      {{ 'MERGE_INCIDENTS.STR_ECPS_NUMBER' | translate }}
                    </label>
                    <div class="uib-textfield custom-input-width">
                      <input
                        type="text"
                        ignisDisableAutocomplete
                        id="first_ecps_number"
                        data-test-id="merge_incidents.first_ecps_number"
                        formControlName="first_ecps_number"
                        class="uib-textfield__input"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-5">
                  <div class="uib-form-filed uib-form-field--horizontal">
                    <label class="uib-form-field__label">
                      <odx-icon name="tablet" size="inline"></odx-icon>
                      {{ 'MERGE_INCIDENTS.STR_ECP_NAME' | translate }}
                    </label>
                    <div class="uib-textfield custom-input-width">
                      <input
                        type="text"
                        ignisDisableAutocomplete
                        id="first_ecp_address"
                        data-test-id="merge_incidents.first_ecp_address"
                        formControlName="first_ecp_address"
                        class="uib-textfield__input"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 mt-3">
              <div class="uib-form-filed uib-form-field--horizontal">
                <label class="uib-form-field__label">
                  <odx-icon size="inline" name="breathing-apparatus"></odx-icon>
                  {{ 'MERGE_INCIDENTS.STR_DEPLOYED_FIREFIGHTERS' | translate }}
                </label>
                <div class="uib-textfield custom-input-width">
                  <input
                    type="text"
                    ignisDisableAutocomplete
                    id="first_deployed_firefighters"
                    data-test-id="merge_incidents.first_deployed_firefighters"
                    formControlName="first_deployed_firefighters"
                    class="uib-textfield__input"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="merge-icons-group">
            <odx-icon size="inline" name="arrow2-right"></odx-icon>
            <odx-icon size="inline" name="collection-add"></odx-icon>
          </div>

          <div class="col-6">
            <div class="col-md-9">
              <div class="uib-form-filed uib-form-field--horizontal">
                <label class="uib-form-field__label">
                  <odx-icon size="inline" name="document"></odx-icon>
                  {{ 'MERGE_INCIDENTS.STR_INCIDENT_DATE' | translate }}
                </label>
                <div class="uib-textfield">
                  <input
                    type="text"
                    ignisDisableAutocomplete
                    id="second_date"
                    data-test-id="merge_incidents.second_incident_date"
                    formControlName="second_date"
                    class="uib-textfield__input"
                  />
                </div>
              </div>
            </div>

            <div class="col-md-5 mt-3">
              <div class="uib-form-filed uib-form-field--horizontal">
                <label class="uib-form-field__label">
                  <odx-icon size="inline" name="document"></odx-icon>
                  {{ 'MERGE_INCIDENTS.STR_INCIDENT_NUMBER' | translate }}
                </label>
                <div class="uib-textfield custom-input-width">
                  <input
                    type="text"
                    ignisDisableAutocomplete
                    id="second_number"
                    data-test-id="merge_incidents.second_incident_number"
                    formControlName="second_number"
                    class="uib-textfield__input"
                  />
                </div>
              </div>
            </div>

            <div class="col-md-12 mt-3">
              <div class="row">
                <div class="col-md-5">
                  <div class="uib-form-filed uib-form-field--horizontal">
                    <label class="uib-form-field__label">
                      <span class="baselocation-icon" [class]="currentAppTheme"></span>
                      {{ 'MERGE_INCIDENTS.STR_ECPS_NUMBER' | translate }}
                    </label>
                    <div class="uib-textfield custom-input-width">
                      <input
                        type="text"
                        ignisDisableAutocomplete
                        id="second_ecps_number"
                        data-test-id="merge_incidents.second_ecps_number"
                        formControlName="second_ecps_number"
                        class="uib-textfield__input"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-5">
                  <div class="uib-form-filed uib-form-field--horizontal">
                    <label class="uib-form-field__label">
                      <odx-icon name="tablet" size="inline"></odx-icon>
                      {{ 'MERGE_INCIDENTS.STR_ECP_NAME' | translate }}
                    </label>
                    <div class="uib-textfield custom-input-width">
                      <input
                        type="text"
                        ignisDisableAutocomplete
                        id="second_ecp_address"
                        data-test-id="merge_incidents.second_ecp_address"
                        formControlName="second_ecp_address"
                        class="uib-textfield__input"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 mt-3">
              <div class="uib-form-filed uib-form-field--horizontal">
                <label class="uib-form-field__label">
                  <odx-icon size="inline" name="breathing-apparatus"></odx-icon>
                  {{ 'MERGE_INCIDENTS.STR_DEPLOYED_FIREFIGHTERS' | translate }}
                </label>
                <div class="uib-textfield custom-input-width">
                  <input
                    type="text"
                    ignisDisableAutocomplete
                    id="second_deployed_firefighters"
                    data-test-id="merge_incidents.second_deployed_firefighters"
                    formControlName="second_deployed_firefighters"
                    class="uib-textfield__input"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </uib-modal-content>
      <uib-modal-actions>
        <button uibButton variant="primary" (click)="mergeIncidents()" data-test-id="modal_actions.save_btn">
          {{ 'MERGE_INCIDENTS.STR_SAVE_BTN' | translate }}
        </button>

        <button
          uibButton
          uibModalClose
          variant="secondary"
          (click)="closeMergeIncidentsModal()"
          data-test-id="modal_actions.cancel_btn"
        >
          {{ 'MERGE_INCIDENTS.STR_CANCEL_BTN' | translate }}
        </button>
      </uib-modal-actions>
    </div>

    <ignis-confirm-merge-incidents
      class="d-none"
      [openModal]="openConfirmMergeIncidents"
      [selectedIncidents]="selectedIncidents"
      (handleCloseConfirmMergeIncidentModal)="closeConfirmMergeModal($event)"
    >
    </ignis-confirm-merge-incidents>
  </ng-template>
</form>
