import { IEntryModel, PropertyBag } from 'src/app/common/models/common.model';
import * as incidentEntries from './incidentEntries.json';

export class ReportCenterConstants {
  public static readonly incidentEntries: {
    types: IEntryModel[];
    specialExposures: IEntryModel[];
    alarmTypes: IEntryModel[];
    eventMarkerTypes: IEntryModel[];
  } = incidentEntries;

  public static readonly incidentDurations: any = [
    {
      id: 1,
      duration: '0h 00min - 0h 10:00min',
      timestamp: 600,
      minDuration: 0,
    },
    {
      id: 2,
      duration: '0h 10:01min - 0h 30:00min',
      timestamp: 1800,
      minDuration: 600,
    },
    {
      id: 3,
      duration: '0h 30:01min - 1h 00:00min',
      timestamp: 3600,
      minDuration: 1800,
    },
    {
      id: 4,
      duration: '1h 00:01min - 2h 00:00min',
      timestamp: 7200,
      minDuration: 3600,
    },
    {
      id: 5,
      duration: '2h 00:01min - 5h 00:00min',
      timestamp: 18000,
      minDuration: 7200,
    },
    {
      id: 6,
      duration: '5h 00:01min - 10h 00:00min',
      timestamp: 36000,
      minDuration: 18000,
    },
    {
      id: 7,
      duration: '10h 00:01min and longer',
      localizedName: 'REPORT_CENTER.LAST_DURATION_OPTION',
      timestamp: -1,
      minDuration: 36000,
    },
  ];

  public static readonly viewEditModalTabs: PropertyBag = {
    INCIDENT_INFORMATION: 'INCIDENT_INFORMATION',
    INCIDENT_ANALYSIS: 'INCIDENT_ANALYSIS',
    INCIDENT_MEDIA_ASSETS: 'INCIDENT_MEDIA_ASSETS',
  };

  public static readonly graphLevel: PropertyBag = {
    HIGH: 'high',
    MEDIUM: 'medium',
    LOW: 'low',
  };

  public static readonly exportTranslationKeys: PropertyBag = {
    timestamp: 'INCIDENT_EXCEL.STR_TIMESTAMP',
    appName: 'INCIDENT_EXCEL.STR_APP_NAME',
    hubId: 'INCIDENT_EXCEL.STR_HUB_ID',
    equipmentId: 'INCIDENT_EXCEL.STR_SCBA_EQUIPMENT_ID',
    personalId: 'INCIDENT_EXCEL.STR_PERSONAL_ID',
    firefighterName: 'INCIDENT_EXCEL.STR_FIREFIGHTER_NAME',
    teamName: 'INCIDENT_EXCEL.STR_TEAM_NAME',
    event: 'INCIDENT_EXCEL.STR_EVENT',
    data: 'INCIDENT_EXCEL.STR_DATA',
  };

  public static readonly entryControlPointTransKey: PropertyBag = {
    entryControlPoint: 'INCIDENT_EXCEL.STR_ENTRY_CONTROL_POINT',
    appName: 'INCIDENT_EXCEL.STR_APP_NAME',
    hubId: 'INCIDENT_EXCEL.STR_HUB_ID',
    user: 'INCIDENT_EXCEL.STR_ECP_USER',
  };

  public static readonly dataTransKey: PropertyBag = {
    incidentNumber: 'INCIDENT_EXCEL.STR_INCIDENT_NUMBER',
    incidentData: 'INCIDENT_EXCEL.STR_INCIDENT_DATA',
    startTime: 'INCIDENT_EXCEL.STR_START_TIME',
    endTime: 'INCIDENT_EXCEL.STR_END_TIME',
    object: 'INCIDENT_EXCEL.STR_OBJECT',
    incidentCategory: 'INCIDENT_EXCEL.STR_EXPOSURE_INFORMATION',
    incidentType: 'INCIDENT_EXCEL.STR_TYPE',
    hazmat: 'INCIDENT_EXCEL.STR_HAZMAT',
    incidentSpecialExposure: 'INCIDENT_EXCEL.STR_SPECIAL_EXPOSURE',
    deployedVehicles: 'INCIDENT_EXCEL.STR_DEPLOYED_VEHICLES',
    deployedResources: 'INCIDENT_EXCEL.STR_DEPLOYED_RESOURCES',
    summary: 'INCIDENT_EXCEL.STR_SUMMARY',
    comment: 'INCIDENT_EXCEL.STR_COMMENT',
  };

  public static readonly firefightersTransKey: PropertyBag = {
    personalId: 'INCIDENT_EXCEL.STR_PERSONAL_ID',
    name: 'INCIDENT_EXCEL.STR_PARTICIPATING_BA_WEARER',
    teamName: 'INCIDENT_EXCEL.STR_TEAM_NAME',
    equipmentSerialNumber: 'INCIDENT_EXCEL.STR_EQUIPMENT_ID',
    usageDuration: 'INCIDENT_EXCEL.STR_USAGE_DURATION',
  };

  public static readonly notesTransKey: PropertyBag = {
    timestamp: 'INCIDENT_EXCEL.STR_TIMESTAMP',
    appName: 'INCIDENT_EXCEL.STR_APP_NAME',
    user: 'INCIDENT_EXCEL.STR_USER',
    hubId: 'INCIDENT_EXCEL.STR_HUB_ID',
    teamInfo: 'INCIDENT_EXCEL.STR_TEAM_INFO',
    note: 'INCIDENT_EXCEL.STR_NOTE',
  };

  public static readonly locationTransKey: PropertyBag = {
    locationInfo: 'INCIDENT_EXCEL.STR_LOCATION_INFO',
    coordinates: 'INCIDENT_EXCEL.STR_COORDINATES',
    address: 'INCIDENT_EXCEL.STR_ADDRESS',
    postcode: 'INCIDENT_EXCEL.STR_POSTCODE',
  };

  public static readonly alarmColors: PropertyBag = {
    MANUAL_EVENT_COLOR: '#FFFFFF',
    MANUAL_EVENT_BORDER: '#0074FF',
    MEDIA_ASSET_COLOR: '#FFFFFF',
    MEDIA_ASSET_BORDER: '#FFFFFF',
    DISTRESS_BUTTON_COLOR: '#880000',
    DISTRESS_BUTTON_BORDER: '#FF2727',
    MOTION_ALARM_COLOR: '#E6141E',
    MOTION_ALARM_BORDER: '#FFA2A7',
    ECB_EVACUATION_COLOR: '#BCDC3C',
    ECB_EVACUATION_BORDER: '#E2FF6E',
    WITHDRAW_SIGNAL_COLOR: '#00491A',
    WITHDRAW_SIGNAL_BORDER: '#009C37',
    THERMAL_ALARM_COLOR: '#FFB200',
    THERMAL_ALARM_BORDER: '#FFD573',
    LOW_BATTERY_ALARM_COLOR: '#FFE600',
    LOW_BATTERY_ALARM_BORDER: '#FFF9C2',
    LOSS_CONNECTION_COLOR: '#E2E6EA',
    LOSS_CONNECTION_BORDER: '#999999',
    FULL_HOUR_LINE: '#0074FF',
    X_AXIS_COLOR: '#3A4C61',
    ACTIVATION_LINE_COLOR: '#00AF3E',
    DEACTIVATION_LINE_COLOR: '#E6141E',
    OK_PRESSURE_COLOR: '#0074FF',
    WARNING_PRESSURE_COLOR: '#FFBF00',
    ZERO_PRESSURE_COLOR: '#CC0000',
  };

  public static readonly graphColors: PropertyBag = {
    high: this.alarmColors.OK_PRESSURE_COLOR,
    medium: this.alarmColors.WARNING_PRESSURE_COLOR,
    low: this.alarmColors.ZERO_PRESSURE_COLOR,
  };

  public static readonly currentIncidentAnalysis: string = 'currentIncidentAnalysis';
  public static readonly dangerCharacters: string[] = ['=', '+', '-', '@', '0x09', '0x0D'];
  public static readonly mediaAssetsVersion: string = 'mediaAssetsVersion';
}
