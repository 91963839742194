import { IErrorCodesForCSV } from 'src/app/common';
import { IUserRole } from './../models/user.model';

export class UserManagementConstants {
  public static readonly userRoles: {
    roles: IUserRole[];
  } = {
    roles: [
      {
        value: 'admin',
        label: 'Admin',
        localizedName: 'USER_ROLES.ADMIN_OPTION',
      },
      {
        value: 'firefighter',
        label: 'Firefighter',
        localizedName: 'USER_ROLES.FIREFIGHTER_OPTION',
      },
      {
        value: 'fire_chief',
        label: 'Fire Chief',
        localizedName: 'USER_ROLES.FIRE_CHIEF_OPTION',
      },
      {
        value: 'technician',
        label: 'Technician',
        localizedName: 'USER_ROLES.TECHNICIAN_OPTION',
      },
      {
        value: 'tester',
        label: 'Tester',
        localizedName: 'USER_ROLES.TESTER_OPTION',
      },
      {
        value: 'it_manager',
        label: 'IT Manager',
        localizedName: 'USER_ROLES.IT_MANAGER_OPTION',
      },
    ],
  };

  public static readonly errorCodesForCSVFile: IErrorCodesForCSV = {
    8014: {
      value: 8014,
      text: 'USER_MANAGEMENT.STR_DUPLICATE_EMAIL',
    },
    8007: {
      value: 8007,
      text: 'USER_MANAGEMENT.STR_INVALID_EMAIL',
    },
    8006: {
      value: 8006,
      text: 'USER_MANAGEMENT.STR_EMAIL_EXIST',
    },
  };
}
