<ignis-progress-bar *ngIf="isLoading$ | async"></ignis-progress-bar>

<ignis-table
  [tableName]="tableName"
  [localColumns]="localTableColumns"
  [dataTable]="addressBookList"
  [resizableColumns]="true"
  [reorderableColumns]="true"
  [displayFilters]="true"
  [activateClickOutside]="activateClickOutside"
  [customFilterLayout]="customFilterLayouts"
  [selectActiveOptions]="selectedDropdowns"
  [lazy]="'true'"
  [isLoading]="isLoading$ | async"
  [paginator]="'true'"
  [first]="first"
  class="address-book-table"
  [totalRecords]="totalRecords"
  [pageNumber]="pageNumber"
  [sortColumn]="sortColumn"
  [totalRecords]="totalRecords"
  [savedFiltersName]="tableFiltersKey"
  (handleTableRef)="tableHelperGetTableRef($event)"
  (handleOnSortChange)="tableHelperOnChangeSort($event)"
  (handleOnPageChange)="tableHelperOnPageChange($event)"
  (columns)="processingTableSettings($event)"
  (handleOnLazyLoading)="tableHelperOnLazyLoading($event)"
  (handleFilterValue)="getFilterTableValue($event)"
  (handleRowSelection)="onAddressBookSelect($event)"
  (handleDoubleClickRowSelection)="editAddressBook()"
  (handleColumnVisibility)="changeColumnVisibility($event)"
>
</ignis-table>

<footer class="blue-bar">
  <ignis-address-book-list-view-footer
    [selectedItem]="selectedAddressBook"
    [isModalOpened]="openConfirmationDeleteDialog"
    (handleEdit)="editAddressBook()"
    (handleCreate)="createNewAddressBook()"
    (handleDelete)="openDeleteAddressBookDialog()"
  ></ignis-address-book-list-view-footer>
</footer>

<ignis-delete-confirmation-modal
  [isOpen]="openConfirmationDeleteDialog"
  [isLoading]="isLoading$ | async"
  (handleDeleteModal)="closeDeleteAddressBookDialog($event)"
>
  <div slot="header">
    {{ 'CONFIGURATION_ADDRESS_TABLE.STR_DELETE_ADDRESS_BOOK' | translate }}
  </div>
  <div slot="content">
    <span>
      {{
        'CONFIGURATION_ADDRESS_TABLE.STR_DELETE_ADDRESS_BOOK_MSG'
          | translate
            : {
                types: selectedAddressBook?.types.length ? selectedAddressBook.types + ': ' : '',
                organization: selectedAddressBook?.organizationName,
                contactName: selectedAddressBook?.contactName ? ', ' + selectedAddressBook.contactName : '',
              }
      }}
    </span>
  </div>
</ignis-delete-confirmation-modal>
