<div class="c-create-media-gallery__wrapper">
  <ngx-simplebar [options]="scrollbarOptions">
    <div class="c-update-incident-wrapper">
      <div class="mt-2 c-form-wrapper">
        <div class="c-information-tabs__wrapper c-incident-information-tabs__wrapper--incident">
          <div>
            <div class="row media-items-container">
              <div class="col-6" *ngFor="let mediaItem of mediaItems">
                <ignis-media-item [media]="mediaItem" [formatDate]="formatDate"></ignis-media-item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ngx-simplebar>
</div>
