import { ChangeDetectionStrategy, Component, Input, OnChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonConstants } from 'src/app/common';
import { IChecklistItem, IChecklistOption } from 'src/app/configuration/models';

interface IChecklistMultipleItem {
  value: boolean;
  label: string;
  id: number;
}

@Component({
  selector: 'ignis-checklist-multiple-selection',
  styleUrls: ['./checklist-multiple-selection.component.scss'],
  templateUrl: './checklist-multiple-selection.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ChecklistMultipleSelectionComponent implements OnChanges {
  @Input() item: IChecklistItem;
  @Input() form: FormGroup;
  @ViewChild('completeChecklistModal') completeChecklistModal!: any;

  multiSelectOptions: IChecklistMultipleItem[] = [];
  copyOfMultiSelectOptions: IChecklistMultipleItem[];
  selectedOptions: IChecklistMultipleItem[];
  searchedOption: IChecklistMultipleItem;

  dropdownIconCSSClass: string = CommonConstants.tableDropdownIconCSSClass;

  ngOnChanges(): void {
    this.item.itemValue.options.forEach((option: IChecklistOption, index: number) => {
      this.multiSelectOptions.push({ value: option.checked, label: option.name, id: index });
      this.copyOfMultiSelectOptions = this.multiSelectOptions;
    });
  }

  removeUnusedElements(): void {
    const lastMultiselectFilterContainer: Element = document.getElementsByClassName(
      'p-multiselect-filter-container',
    )[1];

    lastMultiselectFilterContainer.remove();

    const multiSelectItemsWrapperElem: Element = document.getElementsByClassName('p-multiselect-items-wrapper')[0];

    (multiSelectItemsWrapperElem as HTMLElement).style.maxHeight = '185px';

    setTimeout(() => {
      document.getElementById('multi-select-input')?.focus();
    }, 250);
  }

  removeSelectedItems(): void {
    this.selectedOptions = [];
    this.searchedOption = null;
    this.multiSelectOptions = this.copyOfMultiSelectOptions;
    this.form.get(this.item.id).setValue(null);
  }

  onItemsFilter(filteredValue: string): void {
    this.multiSelectOptions = this.copyOfMultiSelectOptions;
    this.multiSelectOptions = this.multiSelectOptions.filter((item: IChecklistMultipleItem) =>
      item.label.toLocaleLowerCase().includes(filteredValue.toLocaleLowerCase()),
    );
  }

  markMultipleItem(event: IChecklistMultipleItem[]): void {
    let allOptions: IChecklistMultipleItem[] = this.multiSelectOptions;

    allOptions.forEach((elem: IChecklistMultipleItem) => {
      elem.value = false;

      event.forEach((modifiedElem: IChecklistMultipleItem) => {
        if (elem.id === modifiedElem.id) {
          elem.value = true;
        }
      });
    });

    allOptions = [...this.copyOfMultiSelectOptions];

    if (event.length < 1) {
      this.form.get(this.item.id).setValue(null);
    } else {
      this.form.get(this.item.id).setValue(allOptions.map((item: IChecklistMultipleItem) => item.value));
    }

    this.form.get(this.item.id).markAsDirty();
  }
}
