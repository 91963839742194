<ng-template #loadingDeviceConnection>
  <ignis-progress-bar></ignis-progress-bar>
</ng-template>

<ignis-progress-bar *ngIf="migrationIsInProgress"></ignis-progress-bar>
<ignis-protector-software-migration-progress-bar></ignis-protector-software-migration-progress-bar>

<div class="odx-app-page__content">
  <div class="custom-odx-tab-bar odx-margin-bottom-24">
    <div class="odx-tab-bar__inner">
      <div class="odx-tab-bar__panel">
        <div class="back-btn-group ml--10">
          <button
            odxNavigationBack
            class="odx-tab-bar-item"
            (click)="deviceConnectionForm.dirty ? confirmCloseModalOpen() : navigateBack(true)"
            data-test-id="create_update_device_connection.device_connection_overview"
          ></button>
          <span class="odx-fs-normal">
            {{ 'CREATE_UPDATE_DEVICE_CONNECTION.STR_DEVICE_CONNECTION_OVERVIEW' | translate }}
          </span>
        </div>

        <div
          [ngClass]="{
            'tab-is-active': activeTab === tabs.GENERAL,
          }"
        >
          <button odxButton (click)="setActiveTab(tabs.GENERAL)" class="odx-tab-bar-item">
            {{
              deviceConnectionForm.get('aggregateId').value
                ? ('CREATE_UPDATE_DEVICE_CONNECTION.STR_UPDATE_DEVICE_CONNECTION_TAB' | translate)
                : ('CREATE_UPDATE_DEVICE_CONNECTION.STR_CREATE_DEVICE_CONNECTION_TAB' | translate)
            }}
          </button>
        </div>

        <div
          [ngClass]="{
            'tab-is-active': activeTab === tabs.MIGRATION,
          }"
        >
          @if (
            ramProtectorMigrationFeatureToggleIsEnabled &&
            selectedDeviceConnection?.category === deviceCategories.RAM_CONNECTOR &&
            modalType === 'update'
          ) {
            <button odxButton (click)="setActiveTab(tabs.MIGRATION)" class="odx-tab-bar-item">
              {{ 'CREATE_UPDATE_DEVICE_CONNECTION.STR_PROTECTOR_SOFTWARE_DATA_MIGRATION_TAB' | translate }}
            </button>
          }
        </div>
      </div>
    </div>
  </div>

  <ngx-simplebar
    *ngIf="!isLoading; else loadingDeviceConnection"
    [options]="scrollbarOptions"
    class="create-update-device-connection-custom-scrollbar"
  >
    <ignis-device-connection-form
      *ngIf="activeTab === tabs.GENERAL"
      (closeModal)="closeModal($event)"
      [selectedDeviceConnection]="selectedDeviceConnection"
      [modalType]="modalType"
      [isLoading]="isLoading"
      [isSubmitted]="isSubmitted"
      [deviceConnectionForm]="deviceConnectionForm"
      [errors]="httpCustomErrorCode"
      [apiKeyRoles]="apiKeyRoles"
      [deviceConnectionToken]="deviceConnectionToken | async"
      [deviceConnectionIdentifier]="deviceConnectionIdentifier | async"
      [categories]="categories"
      (generateToken)="generateNewToken()"
      (setSelectedCategory)="setSelectedCategory($event)"
    ></ignis-device-connection-form>

    <ignis-protector-software-migration-tab
      *ngIf="ramProtectorMigrationFeatureToggleIsEnabled && activeTab === tabs.MIGRATION"
      [selectedDeviceConnection]="selectedDeviceConnection"
      (emitMigrationInProgress)="migrationIsInProgress = $event"
      (handleCancelMigrationBannerState)="getCancelMigrationBannerState($event)"
      (handleNetworkInstallBannerState)="openMigrationConfirmationBannerForNetworkInstallation = $event"
    ></ignis-protector-software-migration-tab>
  </ngx-simplebar>
</div>

<ignis-confirm-change-token
  [isOpen]="openConfirmChangeTokenModal"
  (closeConfirmModal)="closeConfirmModal($event)"
  [category]="deviceConnectionForm.get('category').value"
>
</ignis-confirm-change-token>

<ignis-confirmation-modal
  [isOpen]="isConfirmCloseModalOpen"
  (handleConfirmationModal)="navigateBack($event)"
  class="is-closed-modal"
>
  <div slot="content">
    {{ 'CONFIRM_CLOSE_MODAL.STR_DESCRIPTION_DEVICE_CONNECTION' | translate }}
  </div>
</ignis-confirmation-modal>

<footer class="blue-bar">
  <ignis-device-connection-module-footer
    [activeEdit]="!!selectedDeviceConnection"
    [form]="deviceConnectionForm"
    [isModalOpened]="
      isConfirmCloseModalOpen ||
      openConfirmChangeTokenModal ||
      openCancelMigrationConfirmationBanner ||
      openMigrationConfirmationBannerForNetworkInstallation
    "
    [isSubmitDisabled]="isSubmitting | async"
    (handleSubmit)="onSubmit()"
  >
  </ignis-device-connection-module-footer>
</footer>
