<div class="c-create-form__wrapper">
  <ngx-simplebar [options]="scrollbarOptions">
    <div class="c-update-form-wrapper">
      <div class="mt-2 c-form-wrapper">
        <div class="c-information-tabs__wrapper c-incident-information-tabs__wrapper--incident">
          <div>
            <div class="col-12 mb-4 position-relative">
              <h1 class="tab-menu-header">
                {{ 'INCIDENT_INFORMATION.STR_INCIDENT_DATA' | translate }}
              </h1>

              <div
                class="corrected-incident"
                *ngIf="selectedIncident.manuallyEdited"
                data-test-id="incident_information.manually_edited"
              >
                <odx-icon size="inline" name="log-edit"></odx-icon>
                <span class="manually-edited-label">{{
                  'INCIDENT_INFORMATION.STR_CORRECTED_INCIDENT_TOOLTIP' | translate
                }}</span>
              </div>
            </div>

            <form [formGroup]="incidentForm">
              <div class="col-12" formGroupName="incidentData">
                <div class="row">
                  <div class="col-8">
                    <div class="row">
                      <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12 mb-2">
                        <div class="uib-form-field uib-form-field--horizontal">
                          <label class="uib-form-field__label">
                            {{ 'INCIDENT_INFORMATION.STR_INCIDENT_NUMBER' | translate }}
                          </label>
                          <div class="uib-textfield">
                            <input
                              type="text"
                              ignisDisableAutocomplete
                              id="incidentNumber"
                              data-test-id="incident_information.incident_number"
                              formControlName="incidentNumber"
                              class="uib-textfield__input"
                              placeholder="{{ 'INCIDENT_INFORMATION.STR_INCIDENT_NUMBER_PLACEHOLDER' | translate }}"
                            />
                            <span class="uib-textfield__validation-icon"></span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12 mb-2">
                        <div class="uib-form-field uib-form-field--horizontal">
                          <label class="uib-form-field__label">
                            {{ 'INCIDENT_INFORMATION.STR_SPECIAL_EXPOSURE' | translate }}
                          </label>

                          <div class="uib-textfield">
                            <ng-select
                              #specialExposureDD
                              [appendTo]="'.uib-form-field--horizontal'"
                              class="uib-dropdown"
                              ngDefaultControl
                              data-test-id="incident_information.special_exposure"
                              formControlName="incidentSpecialExposure"
                              [items]="specialExposuresToShow"
                              bindValue="label"
                              bindValue="value"
                              [clearable]="true"
                              clearAllText=""
                              [placeholder]="'INCIDENT_INFORMATION.STR_SPECIAL_EXPOSURE_PLACEHOLDER' | translate"
                              [notFoundText]="'INCIDENT_INFORMATION.STR_NO_ENTRIES' | translate"
                              (input)="
                                addNewEntryInIncidentDropdown($event, 'specialExposuresToShow', 'specialExposures')
                              "
                              (change)="onEntryChange($event, 'incidentData.incidentSpecialExposure')"
                              (click)="toggleDropdown('specialExposureDD')"
                              (blur)="ddAreOpened = []"
                            >
                            </ng-select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12 mb-2">
                        <div class="uib-form-field uib-form-field--horizontal">
                          <label class="uib-form-field__label">
                            {{ 'INCIDENT_INFORMATION.STR_INCIDENT_TYPE' | translate }}
                          </label>

                          <div class="uib-textfield">
                            <ng-select
                              #incidentTypeDD
                              [appendTo]="'.uib-form-field--horizontal'"
                              class="uib-dropdown"
                              ngDefaultControl
                              data-test-id="incident_information.incident_type"
                              formControlName="incidentType"
                              [items]="incidentTypesToShow"
                              bindValue="label"
                              bindValue="value"
                              [clearable]="true"
                              [placeholder]="'INCIDENT_INFORMATION.STR_INCIDENT_TYPE_PLACEHOLDER' | translate"
                              [notFoundText]="'INCIDENT_INFORMATION.STR_NO_ENTRIES' | translate"
                              (input)="addNewEntryInIncidentDropdown($event, 'incidentTypesToShow', 'incidentTypes')"
                              (change)="onEntryChange($event, 'incidentData.incidentType')"
                              (click)="toggleDropdown('incidentTypeDD')"
                              (blur)="ddAreOpened = []"
                            >
                            </ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12 mb-2">
                        <div class="uib-form-field uib-form-field--horizontal">
                          <label class="uib-form-field__label">
                            {{ 'INCIDENT_INFORMATION.STR_EXPOSURE_INFORMATION' | translate }}
                          </label>

                          <div class="uib-textfield">
                            <input
                              type="text"
                              ignisDisableAutocomplete
                              id="incidentCategory"
                              data-test-id="incident_information.exposure_information"
                              formControlName="incidentCategory"
                              class="uib-textfield__input"
                              placeholder="{{
                                'INCIDENT_INFORMATION.STR_EXPOSURE_INFORMATION_PLACEHOLDER' | translate
                              }}"
                            />
                            <span class="uib-textfield__validation-icon"></span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12 mb-2">
                        <div class="uib-form-field uib-form-field--horizontal">
                          <label class="uib-form-field__label">
                            {{ 'INCIDENT_INFORMATION.STR_START_TIME' | translate }}
                          </label>
                          <div
                            class="uib-textfield"
                            data-test-id="incident_information.start_time_wrap"
                            (mouseenter)="showStartTimeTooltip = true"
                            (mouseleave)="showStartTimeTooltip = false"
                            [ngClass]="{ 'error-border': startTimeError }"
                          >
                            <div
                              [odxTooltipVisible]="startTimeError && errors"
                              [odxTooltip]="
                                startTimeError === incidentErrors.START_TIME_ERROR.value
                                  ? ('INCIDENT_NOTIFICATION.STR_START_TIME_ERROR' | translate)
                                  : startTimeError === incidentErrors.OVERLAPPING_TIME_RANGE.value ||
                                      startTimeError === incidentErrors.OVERLAPPING_START_TIME.value
                                    ? ('INCIDENT_NOTIFICATION.STR_OVERLAPPING_TIME_RANGE'
                                      | translate
                                        : {
                                            displayedValue: overlappingIncidentNumber || '-',
                                          })
                                    : ''
                              "
                              data-test-id="incident_information.start_time_error_tooltip"
                            ></div>
                            <ignis-datepicker
                              formControlName="startTime"
                              ngDefaultControl
                              appendTo="body"
                              [selectionMode]="'single'"
                              [formatDate]="formatDate"
                              [showTime]="true"
                              [showSeconds]="true"
                              [defaultRange]="formatLocaleDate(incidentForm.get('incidentData.startTime').value)"
                              [maxDate]="formatLocaleDate(incidentForm.get('incidentData.endTime').value)"
                              (dateSelect)="onStartTimeSelect($event)"
                              [changeToPreviousValue]="true"
                              [clearable]="false"
                              data-test-id="incident_information.start_time"
                            >
                            </ignis-datepicker>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12 mb-2">
                        <div class="uib-form-field uib-form-field--horizontal">
                          <label class="uib-form-field__label">
                            {{ 'INCIDENT_INFORMATION.STR_DEPLOYED_VEHICLES' | translate }}
                          </label>
                          <div
                            class="uib-textfield"
                            [pTooltip]="
                              isTextOverflow
                                ? composeListTooltip(incidentForm.get('incidentData.deployedVehicles').value)
                                : null
                            "
                            tooltipPosition="top"
                            positionTop="-10"
                          >
                            <input
                              #deployedVehicleElem
                              type="text"
                              ignisDisableAutocomplete
                              id="deployedVehicles"
                              data-test-id="incident_information.deployed_vehicles"
                              formControlName="deployedVehicles"
                              class="uib-textfield__input ellipsed-text"
                              placeholder="{{ 'INCIDENT_INFORMATION.STR_DEPLOYED_VEHICLES_PLACEHOLDER' | translate }}"
                            />
                            <span class="uib-textfield__validation-icon"></span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12 mb-2">
                        <div class="uib-form-field uib-form-field--horizontal">
                          <label class="uib-form-field__label">
                            {{ 'INCIDENT_INFORMATION.STR_END_TIME' | translate }}
                          </label>
                          <div
                            class="uib-textfield"
                            data-test-id="incident_information.end_time_wrap"
                            (mouseenter)="showEndTimeTooltip = true"
                            (mouseleave)="showEndTimeTooltip = false"
                            [ngClass]="{ 'error-border': endTimeError }"
                          >
                            <div
                              [odxTooltipVisible]="endTimeError && errors"
                              [odxTooltip]="
                                endTimeError === incidentErrors.END_TIME_ERROR.value
                                  ? ('INCIDENT_NOTIFICATION.STR_END_TIME_ERROR' | translate)
                                  : endTimeError === incidentErrors.OVERLAPPING_TIME_RANGE.value ||
                                      endTimeError === incidentErrors.OVERLAPPING_END_TIME.value
                                    ? ('INCIDENT_NOTIFICATION.STR_OVERLAPPING_TIME_RANGE'
                                      | translate
                                        : {
                                            displayedValue: overlappingIncidentNumber || '-',
                                          })
                                    : ''
                              "
                              data-test-id="incident_information.end_time_error_tooltip"
                            ></div>
                            <ignis-datepicker
                              formControlName="endTime"
                              ngDefaultControl
                              appendTo="body"
                              [selectionMode]="'single'"
                              [formatDate]="formatDate"
                              [showTime]="true"
                              [showSeconds]="true"
                              [minDate]="formatLocaleDate(incidentForm.get('incidentData.startTime').value)"
                              [defaultRange]="formatLocaleDate(incidentForm.get('incidentData.endTime').value)"
                              (dateSelect)="onEndTimeSelect($event)"
                              [changeToPreviousValue]="true"
                              [clearable]="false"
                              data-test-id="incident_information.end_time"
                            >
                            </ignis-datepicker>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12 mb-2">
                        <div class="uib-form-field uib-form-field--horizontal">
                          <label class="uib-form-field__label">
                            {{ 'INCIDENT_INFORMATION.STR_DEPLOYED_RESOURCES' | translate }}
                          </label>
                          <div class="uib-textfield">
                            <input
                              type="text"
                              ignisDisableAutocomplete
                              id="deployedResources"
                              data-test-id="incident_information.deployed_resources"
                              formControlName="deployedResources"
                              class="uib-textfield__input"
                              placeholder="{{ 'INCIDENT_INFORMATION.STR_DEPLOYED_RESOURCES_PLACEHOLDER' | translate }}"
                            />
                            <span class="uib-textfield__validation-icon"></span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12 mb-2">
                        <div class="uib-form-field uib-form-field--horizontal">
                          <label class="uib-form-field__label">
                            {{ 'INCIDENT_INFORMATION.STR_OBJECT' | translate }}
                          </label>
                          <div class="uib-textfield">
                            <input
                              type="text"
                              ignisDisableAutocomplete
                              id="object"
                              data-test-id="incident_information.object"
                              formControlName="object"
                              class="uib-textfield__input"
                              placeholder="{{ 'INCIDENT_INFORMATION.STR_OBJECT_PLACEHOLDER' | translate }}"
                            />
                            <span class="uib-textfield__validation-icon"></span>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12 mb-2">
                        <div class="uib-form-field uib-form-field--horizontal">
                          <label class="uib-form-field__label">
                            {{ 'INCIDENT_INFORMATION.STR_ECP_LOCATIONS' | translate }}
                          </label>
                          <div class="uib-textfield">
                            <button
                              uibButton
                              variant="primary"
                              [disabled]="!incidentImage"
                              (click)="showMap()"
                              data-test-id="incident_information.show_map_btn"
                            >
                              {{ 'INCIDENT_INFORMATION.STR_SHOW_MAP_BTN' | translate }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-2">
                      <div class="uib-form-field comment-row uib-form-field--horizontal">
                        <label class="uib-form-field__label">
                          {{ 'INCIDENT_INFORMATION.STR_SUMMARY' | translate }}
                        </label>
                        <div class="summary-box">
                          <ngx-simplebar>
                            <p class="mb-0">
                              {{ selectedIncident.incidentData.summary?.firefighterNo }}
                              <span
                                *ngIf="
                                  selectedIncident.incidentData.summary?.firefighterNo < 2;
                                  else displayFirefighters
                                "
                                >{{ 'INCIDENT_INFORMATION.STR_SINGLE_FIREFIGHTER' | translate }}
                              </span>
                              <ng-template #displayFirefighters>
                                <span>{{ 'INCIDENT_INFORMATION.STR_MULTIPLE_FIREFIGHTERS' | translate }}</span>
                              </ng-template>
                            </p>
                            <p class="mb-0">
                              {{ selectedIncident.incidentData.summary?.baSetNo }}
                              <span *ngIf="selectedIncident.incidentData.summary?.baSetNo < 2; else displayBASets">
                                {{ 'INCIDENT_INFORMATION.STR_ONE_BA_SET' | translate }}
                              </span>
                              <ng-template #displayBASets>
                                <span>
                                  {{ 'INCIDENT_INFORMATION.STR_MULTIPLE_BA_SETS' | translate }}
                                </span>
                              </ng-template>
                            </p>
                            <p class="mb-0" *ngIf="selectedIncident.incidentData.summary?.manualEventsNo > 0">
                              <span
                                *ngIf="
                                  selectedIncident.incidentData.summary?.manualEventsNo < 2;
                                  else displayMultipleEvents
                                "
                              >
                                {{ selectedIncident.incidentData.summary?.manualEventsNo }}
                                {{ 'INCIDENT_INFORMATION.STR_SINGLE_MANUALLY_INCIDENT' | translate }}
                              </span>
                              <ng-template #displayMultipleEvents>
                                {{ selectedIncident.incidentData.summary?.manualEventsNo }}
                                {{ 'INCIDENT_INFORMATION.STR_MULTIPLE_MANUALLY_INCIDENT' | translate }}
                              </ng-template>
                            </p>
                            <p class="mb-0" *ngIf="noteworthyAlarms?.length > 0">
                              <span *ngIf="relevantAlarmsNo < 2; else multipleRelevantAlarms">
                                {{ relevantAlarmsNo }}
                                {{ 'INCIDENT_INFORMATION.STR_SINGLE_RELEVANT_ALARM' | translate }}
                              </span>
                              <ng-template #multipleRelevantAlarms>
                                <span>
                                  {{ relevantAlarmsNo }}
                                  {{ 'INCIDENT_INFORMATION.STR_MULTIPLE_RELEVANT_ALARMS' | translate }}
                                </span>
                              </ng-template>
                              <span *ngFor="let alarm of noteworthyAlarms; last as isLast">
                                {{ alarm.number }}
                                {{ alarm.name }}<span *ngIf="!isLast">,</span>
                              </span>
                            </p>
                          </ngx-simplebar>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-2">
                      <div class="uib-form-field comment-row uib-form-field--horizontal">
                        <label class="uib-form-field__label">
                          {{ 'INCIDENT_INFORMATION.STR_COMMENT' | translate }}
                        </label>
                        <textarea
                          ignisDisableAutocomplete
                          class="form-control text-area"
                          id="comment"
                          data-test-id="incident_information.comment"
                          formControlName="comment"
                          rows="10"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12" formArrayName="ecps" *ngFor="let ecp of ecps.controls; let i = index">
                <div class="mt-4">
                  <hr class="incident-divider" />
                </div>
                <div class="row" [formGroupName]="i">
                  <div class="col-8">
                    <div class="row">
                      <div class="col-lg-6 col-md-12 col-sm-12">
                        <div class="col-12 mb-4 mb-sm-2 mb-md-3 mb-lg-3 mt-5 mt-sm-2 mt-md-3 mt-lg-5 ps-0">
                          <h1 class="tab-menu-header">
                            {{ 'INCIDENT_INFORMATION.STR_ENTRY_CONTROL_POINT' | translate }}
                            {{ i + 1 < 10 ? '0' : '' }}{{ i + 1 }}
                          </h1>
                        </div>

                        <div class="row">
                          <div class="col-12">
                            <div class="row">
                              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-2">
                                <div class="uib-form-field uib-form-field--horizontal">
                                  <label class="uib-form-field__label">
                                    {{ 'INCIDENT_INFORMATION.STR_UPLOAD_DEVICE' | translate }}
                                  </label>
                                  <div class="uib-textfield uib-textfield--error">
                                    <input
                                      type="text"
                                      ignisDisableAutocomplete
                                      formControlName="name"
                                      data-test-id="incident_information.upload_device_name"
                                      [readOnly]="true"
                                      placeholder="{{
                                        'INCIDENT_INFORMATION.STR_UPLOAD_DEVICE_PLACEHOLDER' | translate
                                      }}"
                                      class="uib-textfield__input no-border-error"
                                      maxlength="50"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-2">
                            <div class="uib-form-field uib-form-field--horizontal">
                              <label class="uib-form-field__label">
                                {{ 'INCIDENT_INFORMATION.STR_HUB_ID' | translate }}
                              </label>
                              <div class="uib-textfield">
                                <input
                                  type="text"
                                  ignisDisableAutocomplete
                                  id="hubId"
                                  data-test-id="incident_information.hub_id"
                                  formControlName="hubId"
                                  [readOnly]="true"
                                  placeholder="{{ 'INCIDENT_INFORMATION.STR_HUB_ID_PLACEHOLDER' | translate }}"
                                  class="uib-textfield__input"
                                />
                                <span class="uib-textfield__validation-icon"></span>
                              </div>
                            </div>
                            <div class="uib-form-field uib-form-field--horizontal">
                              <label class="uib-form-field__label">
                                {{ 'INCIDENT_INFORMATION.STR_USER' | translate }}
                              </label>
                              <div class="uib-textfield">
                                <input
                                  type="text"
                                  ignisDisableAutocomplete
                                  id="ecp_user"
                                  data-test-id="incident_information.ecp_user"
                                  formControlName="user"
                                  [readOnly]="true"
                                  placeholder="{{ 'INCIDENT_INFORMATION.STR_USER_PLACEHOLDER' | translate }}"
                                  class="uib-textfield__input"
                                />
                                <span class="uib-textfield__validation-icon"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6 col-md-12 col-sm-12" formGroupName="location">
                        <div class="col-12 mb-4 mb-sm-2 mb-md-3 mb-lg-3 mt-5 mt-sm-2 mt-md-3 mt-lg-5 ps-0">
                          <h1 class="tab-menu-header">
                            {{ 'INCIDENT_INFORMATION.STR_LOCATION_INFO' | translate }}
                          </h1>
                        </div>
                        <div class="row">
                          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-2">
                            <div class="uib-form-field uib-form-field--horizontal">
                              <label class="uib-form-field__label">
                                {{ 'INCIDENT_INFORMATION.STR_COORDINATES' | translate }}
                              </label>
                              <div class="uib-textfield">
                                <input
                                  type="text"
                                  ignisDisableAutocomplete
                                  id="displayedCoordinates"
                                  data-test-id="incident_information.coordinates"
                                  formControlName="displayedCoordinates"
                                  placeholder="{{ 'INCIDENT_INFORMATION.STR_COORDINATES' | translate }}"
                                  class="uib-textfield__input"
                                />
                                <span class="uib-textfield__validation-icon"></span>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-2">
                            <div class="uib-form-field uib-form-field--horizontal">
                              <label class="uib-form-field__label">
                                {{ 'INCIDENT_INFORMATION.STR_ADDRESS' | translate }}
                              </label>
                              <div class="uib-textfield">
                                <input
                                  type="text"
                                  ignisDisableAutocomplete
                                  id="location_address"
                                  data-test-id="incident_information.location_address"
                                  formControlName="address"
                                  placeholder="{{ 'INCIDENT_INFORMATION.STR_ADDRESS_PLACEHOLDER' | translate }}"
                                  class="uib-textfield__input"
                                />
                                <span class="uib-textfield__validation-icon"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-2">
                            <div class="uib-form-field uib-form-field--horizontal">
                              <label class="uib-form-field__label">
                                {{ 'INCIDENT_INFORMATION.STR_POSTCODE' | translate }}
                              </label>
                              <div class="uib-textfield">
                                <input
                                  type="text"
                                  ignisDisableAutocomplete
                                  id="postcode"
                                  data-test-id="incident_information.postcode"
                                  formControlName="postcode"
                                  placeholder="{{ 'INCIDENT_INFORMATION.STR_POSTCODE_PLACEHOLDER' | translate }}"
                                  class="uib-textfield__input"
                                />
                                <span class="uib-textfield__validation-icon"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 mt-sm-2 mt-md-3 mt-lg-5">
                    <label class="uib-radio">
                      {{ 'INCIDENT_INFORMATION.STR_DISPLAY_IN_INCIDENT_LIST' | translate }}
                      <input
                        class="uib-radio__input"
                        type="checkbox"
                        formControlName="primary"
                        name="primary"
                        id="primary-{{ i }}"
                        [attr.data-test-id]="'incident_information.primary_incident-' + i"
                        (change)="selectPrimaryEcp(ecp, i)"
                      />
                      <span class="uib-radio__circle"></span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="col-12 mt-4">
                <hr class="incident-divider" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </ngx-simplebar>
</div>

<ignis-image-modal
  [openModal]="isModalOpened"
  [image]="incidentImage"
  (handleCloseNoteImageModal)="closeMediaImageModal()"
></ignis-image-modal>
