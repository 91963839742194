<ignis-progress-bar *ngIf="isLoading | async"></ignis-progress-bar>

<div class="d-flex">
  <p-tree
    class="configuration-tree equipment-tree"
    [value]="hierarchyData"
    data-test-id="equipment_hierarchy.tree"
    selectionMode="single"
    [(selection)]="selectedTreeNode"
    (onNodeSelect)="nodeSelect($event)"
    (clickOutside)="removeSelectedNode()"
  ></p-tree>

  <button
    odxButton
    variant="secondary"
    class="import-eq-hierarchy-btn"
    data-test-id="configuration.import_eq_hierarchy_btn"
    *ngIf="hierarchyData[0].children.length < 1 && !(isLoading | async)"
    (click)="openImportEquipmentHierarchyModal()"
  >
    {{ 'CONFIGURATION.STR_IMPORT_EQUIPMENT_HIERARCHY' | translate }}
  </button>
</div>
<footer class="blue-bar">
  <ignis-equipment-hierarchy-view-footer
    [disableAddBtn]="disableAddBtn"
    [selectedItem]="selectedTreeNode"
    [isModalOpened]="openConfirmationDeleteDialog"
    (handleCreate)="createNewConfiguration()"
    (handleEdit)="editConfiguration()"
    (handleDelete)="openDeleteEquipmentHierarchyDialog()"
  >
  </ignis-equipment-hierarchy-view-footer>
</footer>

<ignis-delete-confirmation-modal
  [isOpen]="openConfirmationDeleteDialog"
  [isLoading]="isLoading | async"
  (handleDeleteModal)="closeDeleteEquipmentHierarchyDialog($event)"
>
  <div slot="header">
    {{ 'DELETE_CONFIGURATION.STR_MODAL_HEADER' | translate }}
  </div>
  <div slot="content">
    <div [ngSwitch]="equipmentConfigType">
      <span *ngSwitchCase="configType.CATEGORY">
        {{ 'DELETE_CONFIGURATION.STR_MODAL_CONTENT_FOR_CATEGORY' | translate }}
      </span>
      <span *ngSwitchCase="configType.TYPE">
        {{ 'DELETE_CONFIGURATION.STR_MODAL_CONTENT_FOR_TYPE' | translate }}
      </span>
      <span *ngSwitchCase="configType.MODEL">
        {{ 'DELETE_CONFIGURATION.STR_MODAL_CONTENT_FOR_MODEL' | translate }}
      </span>
    </div>
  </div>
</ignis-delete-confirmation-modal>

<ignis-import-equipment-hierarchy-modal
  [isOpen]="openImportEQHierarchyModal"
  (closeModal)="closeImportEquipmentHierarchyModal($event)"
></ignis-import-equipment-hierarchy-modal>
