<ng-template uibModal #noteImageModal let-modal="close">
  <div>
    <uib-modal-content [ngStyle]="{ overflow: 'visible' }">
      <div class="image-wrapp">
        <odx-icon
          name="close"
          class="close-modal"
          size="inline"
          (click)="close()"
          data-test-id="image_modal.close_btn"
        ></odx-icon>
        <img [src]="image" class="image" [alt]="image" (clickOutside)="close()" />
      </div>
    </uib-modal-content>
  </div>
</ng-template>
