import { INotificationConstant } from 'src/app/common';

export class UserNotificationConstants {
  public static readonly notificationCodes: INotificationConstant = {
    USER_NOT_FOUND: {
      value: '404',
      text: 'USER_NOTIFICATION.STR_USER_NOT_FOUND',
    },
    USER_ENTITY_NOT_FOUND: {
      value: '8001',
      text: 'USER_NOTIFICATION.STR_USER_ENTITY_NOT_FOUND',
    },
    USER_AGGREGATE_VERSION_CONFLICT: {
      value: '8002',
      text: 'USER_NOTIFICATION.STR_USER_AGGREGATE_VERSION_CONFLICT',
    },
    ADMIN_DELETE_ERROR: {
      value: '8004',
      text: 'USER_NOTIFICATION.STR_ADMIN_DELETE_ERROR',
    },
    AGGREGATE_VERSION_CONFLICT: {
      value: '8005',
      text: 'USER_NOTIFICATION.STR_USER_AGGREGATE_VERSION_CONFLICT',
    },
    EMAIL_ALREADY_EXISTS: {
      value: '8006',
      text: 'USER_NOTIFICATION.STR_EMAIL_ALREADY_EXISTS',
    },
    INVALID_EMAIL: {
      value: '8007',
      text: 'USER_NOTIFICATION.STR_LOCATION_ERROR_CODES_INVALID_EMAIL',
    },
    USER_NOT_AUTHORISED: {
      value: '8008',
      text: 'USER_NOTIFICATION.STR_USER_NOT_AUTHORISED',
    },
    USER_ALREADY_ASSIGNED: {
      value: '8011',
      text: 'USER_NOTIFICATION.STR_USER_ALREADY_ASSIGNED',
    },
    USER_TRY_TO_UPDATE_OWN_ROLE: {
      value: '8013',
      text: 'USER_NOTIFICATION.STR_USER_TRY_TO_UPDATE_OWN_ROLE',
    },
    LARGE_CSV_FILE: {
      value: '8016',
      text: 'USER_NOTIFICATION.STR_LARGE_CSV_FILE',
    },
    IT_MANAGER_NOT_EDIT_ADMIN: {
      value: '8020',
      text: 'USER_NOTIFICATION.STR_IT_MANAGER_NOT_EDIT_ADMIN',
    },
    CSV_FILE_FORMAT_INCORRECT: {
      value: '8024',
      text: 'USER_NOTIFICATION.STR_CSV_FILE_FORMAT_INCORRECT',
    },
    LICENSE_IS_INVALID: {
      value: '8025',
      text: 'USER_NOTIFICATION.STR_INVALID_LICENSE',
    },
    LICENSE_ALREADY_USED: {
      value: '8027',
      text: 'USER_NOTIFICATION.STR_USED_LICENSE',
    },
    LICENSE_EXPIRED: {
      value: '8028',
      text: 'USER_NOTIFICATION.STR_EXPIRED_LICENSE',
    },
    TICKET_SAVED_SUCCESSFULLY: {
      value: 'TICKET_SAVED_SUCCESSFULLY',
      text: 'USER_NOTIFICATION.STR_TICKET_SAVED_SUCCESSFULLY',
    },

    DELETE_USER_SUCCESS: {
      value: 'DELETE_USER_SUCCESS',
      text: 'USER_NOTIFICATION.STR_DELETE_USER_SUCCESS',
    },
    ADD_USER_SUCCESS: {
      value: 'ADD_USER_SUCCESS',
      text: 'USER_NOTIFICATION.STR_ADD_USER_SUCCESS',
    },
    UPDATE_USER_SUCCESS: {
      value: 'UPDATE_USER_SUCCESS',
      text: 'USER_NOTIFICATION.STR_UPDATE_USER_SUCCESS',
    },
    UPLOAD_USERS_CSV_SUCCESS: {
      value: 'UPLOAD_USERS_CSV_SUCCESS',
      text: 'USER_NOTIFICATION.STR_UPLOAD_USERS_CSV_SUCCESS',
    },
    INVALID_ROLE_RESTRICTION: {
      value: '8031',
      text: 'USER_NOTIFICATION.STR_INVALID_ROLE_RESTRICTION',
    },
  };
}
