<router-outlet></router-outlet>
<div class="uib-tabbar [ c-incident-information-tab ]">
  <a
    *ngIf="incidentForm.dirty; else goBack"
    href="javascript:void(0)"
    class="uib-navigation-back"
    (click)="confirmCloseModalOpen()"
    data-test-id="view_update_incident.back"
  >
    <odx-icon name="arrow1-left"></odx-icon>
    <span
      class="uib-navigation-back__label"
      *ngIf="previousRoute?.includes(ReportCenterModuleRoutes.reportCenter); else backToLogbook"
    >
      {{ 'VIEW_UPDATE_INCIDENT.STR_INCIDENT_OVERVIEW' | translate }}
    </span>
  </a>

  <ng-template #backToLogbook>
    @if (previousRoute?.includes(ReportCenterModuleRoutes.remoteMonitoring)) {
      <span>
        {{ 'VIEW_UPDATE_INCIDENT.STR_REMOTE_MONITORING' | translate }}
      </span>
    } @else if (previousRoute?.includes('login-callback')) {
      <span> {{ 'VIEW_UPDATE_INCIDENT.STR_INCIDENT_OVERVIEW' | translate }}</span>
    } @else {
      <span class="uib-navigation-back__label"> {{ 'VIEW_UPDATE_INCIDENT.STR_BA_LOGBOOK' | translate }}</span>
    }
  </ng-template>

  <ng-template #goBack>
    <a
      href="javascript:void(0)"
      class="uib-navigation-back"
      (click)="navigateBack(true)"
      data-test-id="view_update_incident.back"
    >
      <odx-icon name="arrow1-left"></odx-icon>
      <span
        class="uib-navigation-back__label"
        *ngIf="previousRoute?.includes(ReportCenterModuleRoutes.reportCenter); else backToLogbook"
      >
        {{ 'VIEW_UPDATE_INCIDENT.STR_INCIDENT_OVERVIEW' | translate }}</span
      >
    </a>
  </ng-template>
  <button
    (click)="setActiveTab(tabs.INCIDENT_INFORMATION)"
    data-test-id="view_update_incident.incident_information_tab"
    class="uib-tabbar__tab"
    [ngClass]="{
      'uib-is-active': activeTab === tabs.INCIDENT_INFORMATION,
      'tab-has-errors': tabsWithErrors.includes(tabs.INCIDENT_INFORMATION),
    }"
  >
    {{ 'VIEW_UPDATE_INCIDENT.STR_INCIDENT_INFORMATION_TAB' | translate }}
  </button>
  <button
    (click)="setActiveTab(tabs.INCIDENT_ANALYSIS)"
    data-test-id="view_update_incident.incident_analysis_tab"
    class="uib-tabbar__tab"
    [ngClass]="{
      'uib-is-active': activeTab === tabs.INCIDENT_ANALYSIS,
      'tab-has-errors': tabsWithErrors.includes(tabs.INCIDENT_ANALYSIS),
    }"
  >
    {{ 'VIEW_UPDATE_INCIDENT.STR_INCIDENT_ANALYSIS_TAB' | translate }}
  </button>
  <button
    (click)="setActiveTab(tabs.INCIDENT_MEDIA_ASSETS)"
    data-test-id="view_update_incident.media_and_notes_tab"
    class="uib-tabbar__tab"
    [ngClass]="{
      'uib-is-active': activeTab === tabs.INCIDENT_MEDIA_ASSETS,
      'tab-has-errors': tabsWithErrors.includes(tabs.INCIDENT_MEDIA_ASSETS),
    }"
  >
    {{ 'VIEW_UPDATE_INCIDENT.STR_INCIDENT_MEDIA_ASSETS_TAB' | translate }}
  </button>
</div>

<form [formGroup]="incidentForm" *ngIf="!isLoading; else loadingIncident" (ngSubmit)="onSubmit()">
  <ignis-incident-information
    *ngIf="activeTab === tabs.INCIDENT_INFORMATION"
    [selectedIncident]="selectedIncident"
    [isSubmitted]="isSubmitted"
    [incidentForm]="incidentForm"
    [errors]="errors"
    [formatDate]="formatDate"
    [entries]="entries"
    [errors]="errors"
    [overlappingIncidentNumber]="overlappingIncidentNumber"
    [language]="language | async"
  ></ignis-incident-information>
</form>

<ng-template #loadingIncident>
  <ignis-progress-bar *ngIf="isLoading"></ignis-progress-bar>
</ng-template>

<ignis-incident-analysis
  *ngIf="activeTab === tabs.INCIDENT_ANALYSIS"
  [formatDate]="formatDate"
  [incidentForm]="incidentForm"
  [activateClickOutside]="activateClickOutside"
  [hazmatIncidentType]="hazmatIncidentType"
  (getManualEventId)="getManualEventId($event)"
  (resetFormErrors)="resetFormErrors()"
></ignis-incident-analysis>

<ignis-media-assets
  *ngIf="activeTab === tabs.INCIDENT_MEDIA_ASSETS"
  [formatDate]="formatDate"
  [selectedIncident]="selectedIncident"
  [incidentForm]="incidentForm"
></ignis-media-assets>

<ignis-confirmation-modal
  [isOpen]="isConfirmCloseModalOpen"
  (handleConfirmationModal)="navigateBack($event)"
  class="is-closed-modal"
>
  <div slot="content">
    {{ 'CONFIRM_CLOSE_MODAL.STR_DESCRIPTION_INCIDENT' | translate }}
  </div>
</ignis-confirmation-modal>

<footer class="blue-bar">
  <ignis-view-update-incident-footer
    [dirtyForm]="incidentForm.dirty"
    [activeTab]="activeTab"
    [activeEdit]="!!selectedEventMarker?.id"
    [isSubmitDisabled]="isSubmitting | async"
    [isModalOpened]="isConfirmCloseModalOpen || openConfirmationDeleteDialog"
    (handleExportIncidentExcel)="exportExcel()"
    (handleSubmit)="onSubmit()"
    (handleCreate)="openCreateEventMarkerModal()"
    (handleEdit)="openUpdateEventMarkerModal()"
    (handleDelete)="openDeleteEventMarkerDialog()"
    (handleUploadFile)="openUploadFileModal()"
  >
  </ignis-view-update-incident-footer>
</footer>

<ignis-delete-confirmation-modal
  [isOpen]="openConfirmationDeleteDialog"
  [isLoading]="isLoading"
  (handleDeleteModal)="closeDeleteEventMarkerDialog($event)"
>
  <div slot="header">
    {{ 'DELETE_EVENT_MARKER.STR_MODAL_HEADER' | translate }}
  </div>
  <div slot="content">
    <span> {{ 'DELETE_EVENT_MARKER.STR_MODAL_CONTENT' | translate }} {{ selectedEventMarker?.name }}? </span>
    <p>
      {{ 'DELETE_EVENT_MARKER.STR_MODAL_CONTENT_IRREVERSIBLE' | translate }}
    </p>
  </div>
</ignis-delete-confirmation-modal>
