import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  inject,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { sortBy } from 'lodash-es';
import { FeatureModuleBuilderService } from 'src/app/common';
import { FeatureModuleSidebarTitleEnum } from 'src/app/common/enums/feature-module';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { IFeatureModule, PropertyBag } from 'src/app/common/models';
import { LogbookConstants } from 'src/app/logbook/constants/logbook.constants';
import { environment as env } from 'src/environments/environment';
import { AppModuleRoutes } from '../../constants/app-routes.constants';
import { AppUserPermissionList, AppUserRole } from '../../models';

@Component({
  selector: 'ignis-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent extends OnDestroyMixin() implements OnChanges {
  @Input() isOpen: boolean;
  @Input() userRole: string;

  @Output() openSidebar: EventEmitter<any> = new EventEmitter();

  role: AppUserRole = AppUserRole.admin;
  menuItems: IFeatureModule[];

  readonly RAM_VERSION: string = '1.0';
  readonly FIREGROUND_VERSION: string = '0.0';
  readonly AppModuleRoutes: PropertyBag = AppModuleRoutes;
  readonly appUserPermissionList: typeof AppUserPermissionList = AppUserPermissionList;
  readonly sprintAndHotfix: string = `${env.API_URLS.SPRINT_NUMBER}.${env.API_URLS.HOTFIX_NUMBER}`;

  router: Router = inject(Router);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  translateService: TranslateService = inject(TranslateService);
  featureModuleBuilderService: FeatureModuleBuilderService = inject(FeatureModuleBuilderService);

  constructor() {
    super();
  }

  ngOnChanges(): void {
    this.defineMenuItems();
    this.sortMenuItems();
  }

  defineMenuItems(): void {
    this.menuItems = this.featureModuleBuilderService.getFeatureModuleData(
      FeatureModuleSidebarTitleEnum,
      this.userRole,
      this.role,
    );
  }

  sortMenuItems(): void {
    this.menuItems = this.menuItems.filter((item: IFeatureModule) => {
      item.displayedTitle = this.translateService.instant(item.title);

      return true;
    });

    this.menuItems = sortBy(this.menuItems, ['displayedTitle']);

    this.cdr.detectChanges();
  }

  setLogbookPreFilter(): void {
    localStorage.setItem(LogbookConstants.preFilterKey, 'true');
  }

  onCloseMenu(): void {
    this.openSidebar.emit(false);
  }
}
