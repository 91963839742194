import { AddressBook } from './address-book.model';
import { IChecklist } from './checklist.model';
/* eslint-disable @typescript-eslint/unified-signatures */
export interface IEquipmentHierarchy {
  label: string;
  expanded?: boolean;
  data: {
    aggregateId: string;
    version: number;
    name: string;
    testType?: string;
    isCylinderType?: boolean;
  };
  children: IEquipmentHierarchy[];
}

export class EquipmentHierarchy implements IEquipmentHierarchy {
  label: string;
  data: {
    aggregateId: string;
    version: number;
    name: string;
    testType?: string;
    isCylinderType?: boolean;
  };
  children: IEquipmentHierarchy[];

  constructor();
  constructor(hierarchy: IEquipmentHierarchy);
  constructor(hierarchy?: IEquipmentHierarchy) {
    if (!hierarchy) {
      this.label = null;
      this.data = null;
      this.children = null;
    } else {
      this.label = hierarchy.label;
      this.data = hierarchy.data;
      this.children = hierarchy.children;
    }
  }
}

export interface INewTask {
  aggregateId: string;
  version: number;
  taskName: string;
  pattern: string;
  duration: number;
  description: string;
  urlPath?: string;
}

export class NewTask implements INewTask {
  aggregateId: string;
  version: number;
  taskName: string;
  description: string;
  pattern: string;
  duration: number;

  constructor();
  constructor(task: INewTask);
  constructor(task?: INewTask) {
    if (!task) {
      this.aggregateId = null;
      this.version = null;
      this.taskName = null;
      this.description = null;
      this.pattern = null;
      this.duration = null;
    } else {
      this.aggregateId = task.aggregateId;
      this.version = task.version;
      this.taskName = task.taskName;
      this.description = task.description;
      this.pattern = task.pattern;
      this.duration = task.duration;
    }
  }
}

export interface ITask {
  serviceId: string;
  taskId?: string;
  taskName?: string;
  reason?: string;
  description: string;
  checklist: IChecklist;
  isInherited: boolean;
  included?: boolean;
  children: ITaskChildren[] | string[];
  interval: IInterval;
  inheritedFrom?: string;
  hasCheckList?: boolean;
  lastTestDate?: Date | string;
  nextTestDate?: Date | string;
  nextNew?: Date | any;
  origin?: string;
}

export class Task implements ITask {
  serviceId: string;
  taskId?: string;
  taskName?: string;
  reason?: string;
  description: string;
  inheritedFrom?: string;
  checklist: IChecklist;
  isInherited: boolean;
  children: ITaskChildren[] | string[];
  interval: IInterval;
  lastTestDate?: Date | string;
  nextTestDate?: Date | string;
  origin?: string;

  constructor();
  constructor(task: ITask);
  constructor(task?: ITask) {
    if (!task) {
      this.checklist = null;
      this.description = null;
      this.inheritedFrom = null;
      this.serviceId = null;
      this.taskId = null;
      this.isInherited = null;
      this.taskName = null;
      this.reason = null;
      this.children = null;
      this.interval = null;
      this.lastTestDate = null;
      this.nextTestDate = null;
      this.origin = null;
    } else {
      this.serviceId = task.serviceId;
      this.taskId = task.taskId;
      this.isInherited = task.isInherited;
      this.checklist = task.checklist;
      this.inheritedFrom = task.inheritedFrom;
      this.taskName = task.taskName;
      this.reason = task.reason;
      this.description = task.description;
      this.interval = task.interval;
      this.children = task.children;
      this.lastTestDate = task.lastTestDate;
      this.nextTestDate = task.nextTestDate;
      this.origin = task.origin;
    }
  }
}

export interface IUpdateTaskChildren {
  version: number;
  childrenIds: string[];
  urlPath?: string;
}
export interface ITaskChildren {
  serviceId: string;
  taskName?: string;
  reason?: string;
  inheritedFrom?: string;
  interval?: IInterval;
  lastTestDate?: Date | string;
  nextTestDate?: Date | string;
}

export interface IInterval {
  duration: number;
  pattern: string;
}

export interface IEquipmentConfiguration {
  aggregateId: string;
  version: number;
  testType: string;
  protectorType?: string;
  hasCylinderType?: boolean;
  hasModels: boolean;
  maxPressure?: number;
  name: string;
  modelId?: string;
  typeId?: string;
  partNo?: string;
  manufacturer?: AddressBook;
  serviceDefinitions: ITask[];
}

export class EquipmentConfiguration implements IEquipmentConfiguration {
  aggregateId: string;
  version: number;
  testType: string;
  protectorType?: string;
  hasCylinderType?: boolean;
  hasModels: boolean;
  maxPressure?: number;
  name: string;
  partNo?: string;
  modelId?: string;
  typeId?: string;
  manufacturer?: AddressBook;
  serviceDefinitions: ITask[];

  constructor();
  constructor(conf: IEquipmentConfiguration);
  constructor(conf?: IEquipmentConfiguration) {
    if (!conf) {
      this.aggregateId = null;
      this.version = null;
      this.testType = null;
      this.hasCylinderType = null;
      this.protectorType = null;
      this.hasModels = null;
      this.maxPressure = null;
      this.name = null;
      this.partNo = null;
      this.modelId = null;
      this.typeId = null;
      this.manufacturer = null;
      this.serviceDefinitions = null;
    } else {
      this.aggregateId = conf.aggregateId;
      this.version = conf.version;
      this.testType = conf.testType;
      this.protectorType = conf.protectorType;
      this.hasCylinderType = conf.hasCylinderType;
      this.hasModels = conf.hasModels;
      this.maxPressure = conf.maxPressure;
      this.name = conf.name;
      this.partNo = conf.partNo;
      this.typeId = conf.typeId;
      this.modelId = conf.modelId;
      this.manufacturer = conf.manufacturer;
      this.serviceDefinitions = conf.serviceDefinitions.map((service: ITask) => new Task(service));
    }
  }
}

export interface IEditEquipmentHierarchy {
  data: {
    aggregateId: string;
    version: number;
    name: string;
    testType?: string;
    isCylinderType?: boolean;
    id?: string;
  };
  urlPath?: string;
}

export class EditEquipmentHierarchy {
  aggregateId: string;
  version: number;
  name: string;
  testType?: string;
  id?: string;

  constructor();
  constructor(editedHierarchy: IEditEquipmentHierarchy);
  constructor(editedHierarchy?: IEditEquipmentHierarchy) {
    if (!editedHierarchy) {
      this.aggregateId = null;
      this.id = null;
      this.version = null;
      this.name = null;
      this.testType = null;
    } else {
      this.aggregateId = editedHierarchy.data.aggregateId;
      this.id = editedHierarchy.data.id;
      this.version = editedHierarchy.data.version;
      this.name = editedHierarchy.data.name;
      this.testType = editedHierarchy.data.testType;
    }
  }
}

export interface IEditTask {
  serviceId: string;
  version: number;
  name: string;
  description: string;
  urlPath?: string;
  pattern: string;
  duration: number;
}

export class EditTask implements IEditTask {
  serviceId: string;
  version: number;
  name: string;
  description: string;
  pattern: string;
  duration: number;

  constructor();
  constructor(task: IEditTask);
  constructor(task?: IEditTask) {
    if (!task) {
      this.serviceId = null;
      this.version = null;
      this.name = null;
      this.description = null;
      this.pattern = null;
      this.duration = null;
    } else {
      this.serviceId = task.serviceId;
      this.version = task.version;
      this.name = task.name;
      this.description = task.description;
      this.pattern = task.pattern;
      this.duration = task.duration;
    }
  }
}
