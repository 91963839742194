<ng-template uibModal #uploadMediaFilesModal let-modal="close">
  <div>
    <uib-modal-header> {{ 'INCIDENT_UPLOAD_FILE.STR_TITLE' | translate }} </uib-modal-header>
    <uib-modal-content [ngStyle]="{ overflow: 'visible' }">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12 mb-3">
          <div class="uib-form-field uib-form-field--required">
            <label class="uib-form-field__label"> {{ 'EVENT_MARKER.EVENT_ECP' | translate }} </label>
            <div class="uib-dropdown">
              <p-dropdown
                #dd2
                (onShow)="dropdownService.openDropdown(dd2)"
                (onHide)="dropdownService.closeDropdown(dd2)"
                optionValue="telemetryAddress"
                data-test-id="incident_upload_file.event_ecp"
                styleClass="uib-dropdown"
                [dropdownIcon]="dropdownIconCSSClass"
                [options]="ecpsList"
                [placeholder]="'EVENT_MARKER.EVENT_ECP_PLACEHOLDER' | translate"
                [showClear]="true"
                [ngModel]="selectedTelemetryAddress"
                (ngModelChange)="selectedTelemetryAddress = $event"
                (onChange)="selectTelemetryAddress($event)"
              >
                <ng-template let-option pTemplate="item">
                  <span>{{ option.label }}</span>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
        </div>
      </div>

      <p-fileUpload
        #uploadFileControl
        accept=".jpeg, .png, .jpg"
        multiple="true"
        [maxFileSize]="maxSizeOfUploadedImages"
        [invalidFileSizeMessageSummary]="'COMMON_NOTIFICATION.STR_UPLOAD_LARGE_FILE_FAILED' | translate"
        [invalidFileSizeMessageDetail]="''"
        [chooseLabel]="'INCIDENT_UPLOAD_FILE.STR_SELECT_FILE' | translate"
        chooseIcon="''"
        [customUpload]="true"
        [auto]="true"
        data-test-id="incident_upload_file.upload_file_component"
        [invalidFileTypeMessageSummary]="'INCIDENT_UPLOAD_FILE.STR_FILE_TYPE_ERROR' | translate"
        [invalidFileTypeMessageDetail]="'jpeg, png, jpg.'"
        (onSelect)="selectFilesToBeUploaded($event, uploadFileControl)"
        (onError)="isLoading = false"
        (onRemove)="removeUploadedFile($event)"
      >
        <ng-template let-file pTemplate="content">
          <ignis-progress-bar class="upload-media-file-progress-bar" *ngIf="isLoading"></ignis-progress-bar>
        </ng-template>
      </p-fileUpload>
    </uib-modal-content>
    <uib-modal-actions>
      <button
        uibButton
        variant="primary"
        type="button"
        [disabled]="submitBtnState || isLoading"
        (click)="setMediaAssets()"
        data-test-id="modal_actions.ok_btn"
      >
        {{ 'INCIDENT_UPLOAD_FILE.OK_BTN' | translate }}
      </button>

      <button uibButton (click)="closeModal(true)" variant="secondary" data-test-id="modal_actions.cancel_btn">
        {{ 'INCIDENT_UPLOAD_FILE.CANCEL_BTN' | translate }}
      </button>
    </uib-modal-actions>
  </div>
</ng-template>
