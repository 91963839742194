<ignis-progress-bar *ngIf="isLoading"></ignis-progress-bar>

<div class="custom-odx-tab-bar odx-margin-bottom-24" *ngIf="!isLoading">
  <div class="odx-tab-bar__inner">
    <div class="odx-tab-bar__panel">
      <div class="back-btn-group ml--10">
        <button
          odxNavigationBack
          class="odx-tab-bar-item"
          (click)="confirmCloseModalOpen()"
          data-test-id="equipment_task_documentation.back"
        ></button>
        <span class="odx-fs-normal"> {{ 'CREATE_UPDATE_EQUIPMENT.STR_EQUIPMENT_BACK' | translate }}</span>
      </div>
    </div>
  </div>
</div>

<ngx-simplebar [options]="scrollbarOptions">
  <h2 class="mb-3">
    {{ 'EQUIPMENT_TEST.STR_EQUIPMENT_INFORMATION' | translate }}
  </h2>
  <form [formGroup]="equipmentTestForm" *ngIf="!isLoading" odxForm class="testForm">
    <div class="mt-2" odxLayout="grid">
      <div odxLayout="12 12@tablet 4@desktop" class="custom-form-field-pr-10">
        <odx-form-field
          [readonly]="true"
          variant="horizontal"
          label="{{ 'EQUIPMENT_TEST.STR_EQUIPMENT_IDENTIFICATION' | translate }}"
        >
          <input
            type="text"
            id="incidentIdentification"
            data-test-id="equipment_test.equipment_identification"
            formControlName="equipmentId"
            odxFormFieldControl
          />
        </odx-form-field>

        <odx-form-field
          [readonly]="true"
          variant="horizontal"
          label="{{ 'EQUIPMENT_GENERAL.STR_EQUIPMENT_BARCODE' | translate }}"
        >
          <input
            type="text"
            id="barcode"
            data-test-id="equipment_test.equipment_barcode"
            formControlName="barcode"
            odxFormFieldControl
          />
        </odx-form-field>

        <odx-form-field [readonly]="true" variant="horizontal" label="RFID">
          <input
            type="text"
            id="rfid"
            data-test-id="equipment_test.equipment_rfid"
            formControlName="rfid"
            odxFormFieldControl
          />
        </odx-form-field>

        <odx-form-field
          [readonly]="true"
          variant="horizontal"
          label="{{ 'EQUIPMENT_GENERAL.STR_MANUFACTURER_FORM_SERIAL_NO' | translate }}"
        >
          <input
            type="text"
            id="serialNo"
            data-test-id="equipment_test.serial_no"
            formControlName="serialNo"
            odxFormFieldControl
          />
        </odx-form-field>
      </div>
      <div odxLayout="12 12@tablet 4@desktop" class="custom-form-field-pl-10">
        <odx-form-field
          [readonly]="true"
          variant="horizontal"
          label="{{ 'EQUIPMENT_TEST.STR_EQUIPMENT_TYPE' | translate }}"
        >
          <input
            type="text"
            id="incidentType"
            data-test-id="equipment_test.equipment_type"
            formControlName="kind"
            odxFormFieldControl
          />
        </odx-form-field>

        <odx-form-field
          [readonly]="true"
          variant="horizontal"
          label="{{ 'EQUIPMENT_TEST.STR_EQUIPMENT_MODEL' | translate }}"
        >
          <input
            type="text"
            id="equipmentModel"
            data-test-id="equipment_test.equipment_model"
            formControlName="equipmentModel"
            odxFormFieldControl
          />
        </odx-form-field>
      </div>
    </div>
    <div class="mt-4">
      <hr class="divider" />
    </div>

    <h2 class="mb-3">
      {{ 'EQUIPMENT_TEST.STR_SERVICE_DETAILS' | translate }}
    </h2>

    <div odxLayout="grid" class="ngSelects">
      <div odxLayout="12 12@tablet 4@desktop" class="custom-form-field-pr-10">
        <odx-form-field variant="horizontal" label="{{ 'EQUIPMENT_TEST.STR_TEST_SERVICE' | translate }}">
          <div class="w-100 task-dropdown">
            <ng-select
              #ddTestTask
              ignisElementIsVisible
              appendTo="body"
              bindValue="serviceId"
              [compareWith]="compareTasks"
              ngDefaultControl
              formControlName="task"
              data-test-id="equipment_test.test_task"
              bindLabel="taskName"
              [title]="taskName"
              [items]="selectedEquipment?.serviceIntervals"
              [clearable]="false"
              clearAllText=""
              [placeholder]="'EQUIPMENT_TEST.STR_TEST_SERVICE_PLACEHOLDER' | translate"
              [notFoundText]="'EQUIPMENT_TEST.NO_ENTRIES_FOUND' | translate"
              (close)="focusNextDropdown()"
              (change)="changeService($event)"
              (click)="closeOnClickAgain('ddTestTask')"
              (visible)="applyFocusOnElements()"
            >
            </ng-select>
          </div>
        </odx-form-field>
      </div>

      <div odxLayout="12 12@tablet 4@desktop" class="custom-form-field-pl-10">
        <odx-form-field variant="horizontal" label="{{ 'EQUIPMENT_TEST.STR_TEST_EQUIPMENT' | translate }}">
          <div class="w-100 task-dropdown">
            <ng-select
              #ddTestEquipment
              appendTo="body"
              bindValue="id"
              ngDefaultControl
              formControlName="testDevice"
              data-test-id="equipment_test.test_device"
              bindLabel="name"
              [items]="testParameters?.devices"
              [clearable]="false"
              [placeholder]="'EQUIPMENT_TEST.STR_TEST_EQUIPMENT_PLACEHOLDER' | translate"
              [notFoundText]="'EQUIPMENT_TEST.NO_ENTRIES_FOUND' | translate"
              (close)="focusNextDropdown()"
              (click)="closeOnClickAgain('ddTestEquipment')"
            >
            </ng-select>
          </div>
        </odx-form-field>
        <odx-form-field variant="horizontal" label="{{ 'EQUIPMENT_TEST.STR_TEST_PROCEDURE' | translate }}">
          <div class="w-100 task-dropdown">
            <ng-select
              #ddTestProcedure
              appendTo="body"
              ngDefaultControl
              formControlName="testProcedure"
              data-test-id="equipment_test.test_procedure"
              bindLabel="name"
              bindValue="name"
              [items]="testParameters?.procedures"
              [clearable]="false"
              [placeholder]="'EQUIPMENT_TEST.STR_TEST_PROCEDURE_PLACEHOLDER' | translate"
              [notFoundText]="'EQUIPMENT_TEST.NO_ENTRIES_FOUND' | translate"
              (close)="focusNextDropdown()"
              (click)="closeOnClickAgain('ddTestProcedure')"
            >
            </ng-select>
          </div>
        </odx-form-field>

        <odx-form-field variant="horizontal" label="{{ 'EQUIPMENT_TEST.STR_TEST_TYPE' | translate }}">
          <div class="w-100 task-dropdown">
            <ng-select
              #ddTestType
              appendTo="body"
              bindValue="name"
              ngDefaultControl
              formControlName="testType"
              data-test-id="equipment_test.test_type"
              bindLabel="name"
              [items]="testParameters?.types"
              [clearable]="false"
              [placeholder]="'EQUIPMENT_TEST.STR_TEST_TYPE_PLACEHOLDER' | translate"
              [notFoundText]="'EQUIPMENT_TEST.NO_ENTRIES_FOUND' | translate"
              (click)="closeOnClickAgain('ddTestType')"
              (close)="focusNextDropdown()"
            >
            </ng-select>
          </div>
        </odx-form-field>

        <odx-form-field
          *ngIf="testParameters && testParameters.pressureGaugeComparisons.length > 1"
          variant="horizontal"
          label="{{ 'EQUIPMENT_TEST.STR_PRESSURE_GAUGE' | translate }}"
        >
          <div class="w-100 task-dropdown">
            <ng-select
              #ddPressureGauge
              appendTo="body"
              bindValue="id"
              ngDefaultControl
              formControlName="pressureGaugeId"
              data-test-id="equipment_test.pressure_gauge"
              bindLabel="name"
              [items]="testParameters?.pressureGaugeComparisons"
              [clearable]="false"
              [placeholder]="'EQUIPMENT_TEST.STR_PRESSURE_GAUGE_PLACEHOLDER' | translate"
              [notFoundText]="'EQUIPMENT_TEST.NO_ENTRIES_FOUND' | translate"
              (close)="focusNextDropdown()"
              (click)="closeOnClickAgain('ddPressureGauge')"
            >
            </ng-select>
          </div>
        </odx-form-field>
      </div>
    </div>

    <div class="mt-4">
      <hr class="divider" />
    </div>
    <div odxLayout="grid">
      <h2 odxLayout="12" class="mb-3">
        {{ 'EQUIPMENT_TASK_RESULTS.STR_TASKS_INCLUDED' | translate }}
      </h2>
      <p-table
        #dt2
        styleClass="p-datatable-striped p-datatable-gridlines"
        scrollHeight="flex"
        [scrollable]="true"
        [value]="selectedServiceIntervals"
        odxLayout="12 12@tablet 8@desktop"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th scope="col" [ngStyle]="{ width: '50%' }">
              {{ 'EQUIPMENT_TEST.STR_EQUIPMENT_INTERVAL_DESCRIPTION' | translate }}
            </th>
            <th scope="col" [ngStyle]="{ width: '25%' }">
              {{ 'EQUIPMENT_TEST.STR_EQUIPMENT_INTERVAL_LAST' | translate }}
            </th>
            <th scope="col" [ngStyle]="{ width: '25%' }">
              {{ 'EQUIPMENT_TEST.STR_EQUIPMENT_INTERVAL_NEXT' | translate }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-service>
          <tr>
            <td data-column="taskName" [ngStyle]="{ width: '50%' }" data-test-id="services_included_table.taskName">
              {{ service.taskName }}
            </td>
            <td
              data-column="lastInterval"
              [ngStyle]="{ width: '25%' }"
              data-test-id="services_included_table.last_test_date"
            >
              {{ service.lastTestDate | date: formatDate }}
            </td>
            <td
              data-column="nextInterval"
              [ngStyle]="{ width: '25%' }"
              data-test-id="services_included_table.next_test_date"
            >
              <span
                [ngClass]="{
                  'c-test--is-due': testIsExpired(service.nextTestDate),
                }"
              >
                {{ service.nextTestDate | date: formatDate }}
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="3">{{ 'EQUIPMENT_TEST.STR_NO_SERVICE_INTERVALS' | translate }}</td>
          </tr>
        </ng-template>
      </p-table>

      <h2 class="mb-3" odxLayout="12">
        {{ 'EQUIPMENT_TASK_RESULTS.STR_OTHER_TASKS' | translate }}
      </h2>

      <p-table
        #dt2
        styleClass="p-datatable-striped p-datatable-gridlines"
        scrollHeight="flex"
        [scrollable]="true"
        [value]="otherServiceIntervals"
        odxLayout="12 12@tablet 8@desktop"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th scope="col" [ngStyle]="{ width: '50%' }">
              {{ 'EQUIPMENT_TEST.STR_EQUIPMENT_INTERVAL_DESCRIPTION' | translate }}
            </th>
            <th scope="col" [ngStyle]="{ width: '25%' }">
              {{ 'EQUIPMENT_TEST.STR_EQUIPMENT_INTERVAL_LAST' | translate }}
            </th>
            <th scope="col" [ngStyle]="{ width: '25%' }">
              {{ 'EQUIPMENT_TEST.STR_EQUIPMENT_INTERVAL_NEXT' | translate }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-service>
          <tr>
            <td data-column="taskName" data-test-id="other_services_table.taskName" [ngStyle]="{ width: '50%' }">
              {{ service.taskName }}
            </td>
            <td
              data-column="lastInterval"
              data-test-id="other_services_table.last_test_date"
              [ngStyle]="{ width: '25%' }"
            >
              {{ service.lastTestDate | date: formatDate }}
            </td>
            <td
              data-column="nextInterval"
              data-test-id="other_services_table.next_test_date"
              [ngStyle]="{ width: '25%' }"
            >
              <span
                [ngClass]="{
                  'c-test--is-due': testIsExpired(service.nextTestDate),
                }"
              >
                {{ service.nextTestDate | date: formatDate }}
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="3">{{ 'EQUIPMENT_TEST.STR_NO_SERVICE_INTERVALS' | translate }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <div class="mt-4">
      <hr class="divider" />
    </div>

    <h2 class="mb-3">
      {{ 'EQUIPMENT_TEST.STR_TESTER' | translate }}
    </h2>

    <div odxLayout="grid">
      <odx-form-field
        odxLayout="12 12@tablet 4@desktop"
        variant="horizontal"
        label="{{ 'EQUIPMENT_TEST.STR_TESTER_NAME' | translate }}"
        [readonly]="true"
      >
        <input
          type="text"
          odxLayout="12"
          ignisDisableAutocomplete
          formControlName="testerName"
          odxFormFieldControl
          id="testerName"
          data-test-id="equipment_test.tester_name"
        />
      </odx-form-field>
    </div>
  </form>
</ngx-simplebar>
<ignis-confirmation-modal
  [isOpen]="isConfirmCloseModalOpen"
  (handleConfirmationModal)="navigateBack($event)"
  class="is-closed-modal"
>
  <div slot="content">
    {{ 'CONFIRM_CLOSE_MODAL.STR_DESCRIPTION_EQUIPMENT_TEST' | translate }}
  </div>
</ignis-confirmation-modal>
<footer class="blue-bar">
  <ignis-test-equipment-view-footer
    [form]="equipmentTestForm"
    [isModalOpened]="isConfirmCloseModalOpen"
    [disableStartTestBtn]="disableStartTestBtn"
    [isSubmitDisabled]="isSubmitting | async"
    (handleTestingEquipmentBtn)="getTestingBtnRef($event)"
    (handleStartTesting)="checkTestIsPossible()"
  ></ignis-test-equipment-view-footer>
</footer>

<ignis-ram-connector-auto-update-modal
  [ramConnectorStatus]="ramConnectorStatus"
  [startAutoUpdate]="startAutoUpdate"
  [type]="notificationType.WARNING"
  (handleConfirmationModal)="startRAMConnectorAutoUpdateFromEQTestPage($event)"
  (handleAutoUpdateStartsWithSuccess)="goToParentView()"
>
  <div slot="content">
    {{ 'PROTECTOR_SOFTWARE_NOTIFICATION.STR_OUTDATED_VERSION_OF_RAM_CONNECTOR' | translate }}
  </div>
</ignis-ram-connector-auto-update-modal>
