import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { select } from '@ngrx/store';
import { combineLatest, filter, map, take, takeUntil } from 'rxjs';
import { CommonConstants, DatePickerService, IStoreApiItem, IStoreApiList, PropertyBag } from 'src/app/common';
import { extractOnlyDate } from 'src/app/common/utils/date-utils/date.utils';
import { processLocationPath } from 'src/app/common/utils/execute-test-utils/start-test';
import { ChecklistParameterTypes } from 'src/app/configuration/constants/configuration.constants';
import { Task } from 'src/app/configuration/models';
import { IAvailableChecklist, IChecklist, IChecklists } from 'src/app/configuration/models/checklist.model';
import { ITask } from 'src/app/configuration/models/equipment-configuration.models';
import { AppModulesTypes } from 'src/app/root/models/app-types';
import { IFeatureToggle } from 'src/app/root/state/features-toggle/models/features-toggle.model';
import { selectFeaturesToggleList } from 'src/app/root/state/features-toggle/selectors/features-toggle.selector';
import { EquipmentNotificationConstants } from 'src/app/workshop/constants';
import { WorkshopModuleRoutes } from 'src/app/workshop/constants/workshop-module-routes.constants';
import { WorkshopConstants } from 'src/app/workshop/constants/workshop.constants';
import { EquipmentUpdateIdsActions } from 'src/app/workshop/state/actions/equipment-update-ids';
import { IEquipment, IEquipmentLocationAssignment, IEquipmentServiceInterval } from '../../../models';
import { OutOfServiceFieldInteractionService } from '../../../services/out-of-service-field-interaction.service';
import { CompleteBatchChecklistComponent } from '../complete-batch-checklist/complete-batch-checklist.component';

import {
  selectChecklistsData,
  selectedSavedServicesChecklist,
  selectEquipmentItem,
  selectUpdatedEquipmentIds,
} from '../../../state/selectors';

@Component({
  selector: 'ignis-complete-checklist',
  templateUrl: './complete-checklist.component.html',
  styleUrls: ['./complete-checklist.component.scss'],
})
export class CompleteChecklistComponent extends CompleteBatchChecklistComponent implements OnInit {
  completeChecklistForm: FormGroup = new FormGroup({});
  selectedTaskIntervals: ITask[] = [];
  otherTaskIntervals: IEquipmentServiceInterval[] = [];
  checklistParameterTypes: typeof ChecklistParameterTypes = ChecklistParameterTypes;
  operationalStatusesList: {
    value: string;
    label: string;
  }[] = [];
  operationalStatuses: PropertyBag = WorkshopConstants.operationalStatuses;

  openConfirmChangeTasks: boolean = false;
  isConfirmCloseModalOpen: boolean = false;
  areFilesUploaded: boolean = false;

  extractOnlyDate: (date: string | Date) => string = extractOnlyDate;

  jwtHelper: JwtHelperService = new JwtHelperService();
  datepickerService: DatePickerService = inject(DatePickerService);
  outOfServiceService: OutOfServiceFieldInteractionService = inject(OutOfServiceFieldInteractionService);
  equipmentUpdateIdsActions: EquipmentUpdateIdsActions = inject(EquipmentUpdateIdsActions);

  barcodeUpdateInTaskFeatureToggle: boolean;

  isLoading: boolean = true;

  ngOnInit(): void {
    this.getAppThemeAndOperationalStatusList();
    this.getAppInfo();

    if (localStorage.getItem(WorkshopConstants.selectedTask)) {
      this.selectedTask = JSON.parse(localStorage.getItem(WorkshopConstants.selectedTask));
    }

    this.createCompleteChecklistForm();

    this.isSubmitting = combineLatest([
      this.store.select(selectUpdatedEquipmentIds),
      this.store.select(selectedSavedServicesChecklist),
    ]).pipe(
      takeUntil(this.destroy),
      map(
        ([updatedEquipment, checklistCompleted]: [IStoreApiItem<any>, IStoreApiList<ITask>]) =>
          updatedEquipment.isLoading || checklistCompleted.isLoading,
      ),
    );

    this.store
      .pipe(
        select(selectFeaturesToggleList),
        map((state: IStoreApiList<IFeatureToggle[]>) =>
          state.data?.find((ft: IFeatureToggle) => Object.values(ft).includes(AppModulesTypes.barcodeUpdateInTask)),
        ),
        takeUntil(this.destroy),
      )
      .subscribe((response: IFeatureToggle) => {
        this.barcodeUpdateInTaskFeatureToggle = response?.isEnabled;
        this.cdr.detectChanges();
      });
  }

  createCompleteChecklistForm(): void {
    this.completeChecklistForm = this.formBuilder.group({
      taskData: new FormControl(null),
      checklistName: new FormControl({ value: null, disabled: true }),
      checklistDescription: new FormControl({ value: null, disabled: true }),
      barcode: new FormControl(null),
      rfid: new FormControl(null),
      outOfOrderDate: new FormControl(null),
      outOfServiceReason: new FormControl({
        value: null,
        disabled: true,
      }),
      identifierClone: new FormControl(null),
      operationalStatus: new FormControl(null, [Validators.required]),
      otherTasks: new FormControl(null),
    });
  }

  getEquipmentVersionFromStore(): void {
    this.store
      .pipe(select(selectEquipmentItem), takeUntil(this.destroy))
      .subscribe((equipmentState: IStoreApiItem<IEquipment>) => {
        if (equipmentState.data && equipmentState.isSuccess) {
          this.loadedEquipment = { ...this.loadedEquipment, version: equipmentState.data.version };
        }
      });
  }

  populateTaskDropdown(): void {
    this.store
      .pipe(
        select(selectChecklistsData),
        filter((checklists: IStoreApiItem<IChecklists>) => !checklists.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((checklists: IStoreApiItem<IChecklists>) => {
        if (checklists.data) {
          this.processingTaskData(checklists);

          this.loadedEquipment = checklists.data.equipmentData[0];
          this.selectedChecklist = checklists.data.availableChecklists[0].checklist;

          this.getEquipmentVersionFromStore();

          if (this.selectedTask) {
            this.serviceIntervals.forEach((sd: ITask) => {
              if (sd.serviceId === this.selectedTask.serviceId) {
                this.defaultServiceInterval = structuredClone(sd);
              }
            });
          } else {
            const selectedLocation: IEquipmentLocationAssignment = JSON.parse(
              localStorage.getItem(WorkshopConstants.savedLocation),
            );

            this.serviceIntervals = this.sortServicesData(this.serviceIntervals);
            this.defaultServiceInterval = structuredClone(this.serviceIntervals[0]);
            this.changeLocation(selectedLocation);
          }

          this.selectedServices = this.defaultServiceInterval;
          this.processingTasksTablesData(this.selectedServices?.serviceId);
          this.completeChecklistForm.patchValue({
            taskData: this.defaultServiceInterval,
            checklistName: this.selectedChecklist.name,
            checklistDescription: this.selectedChecklist.description,
            operationalStatus: this.loadedEquipment?.generalData?.operationalStatus,
            outOfOrderDate: this.loadedEquipment.generalData?.outOfOrderDate,
            outOfServiceReason: this.loadedEquipment.generalData?.outOfServiceReason,
          });

          if (this.loadedEquipment.generalData?.outOfOrderDate) {
            this.outOfServiceService.changeOutOfServiceDate(
              this.loadedEquipment.generalData?.outOfOrderDate,
              this.completeChecklistForm,
              'operationalStatus',
              'outOfServiceReason',
            );
          }

          this.isLoading = false;
        }

        this.setChecklistDependingBySelectedTask();

        this.completeChecklistForm.get('operationalStatus').markAsPristine();
        this.cdr.detectChanges();
      });
  }

  changeLocation(location: IEquipmentLocationAssignment): void {
    if (
      location &&
      (this.loadedEquipment as IEquipment)?.aggregateId &&
      this.router.url.includes(WorkshopModuleRoutes.workflow)
    ) {
      const locationChange: unknown = {
        locationAssignment: { aggregateId: location.aggregateId },
        equipmentAggregateIds: [(this.loadedEquipment as IEquipment).aggregateId],
      };

      this.loadedEquipment = { ...this.loadedEquipment, version: +(this.loadedEquipment as IEquipment).version + 1 };

      this.equipmentServiceTaskActions.requestUpdateEquipmentLocation(locationChange);
    }
  }

  closeModal(onSubmit?: boolean): void {
    this.markFormElements('markAsPristine');

    if (this.hasUnsavedData()) {
      this.confirmCloseModalOpen();
    } else {
      this.completeChecklistForm = null;
      this.createCompleteChecklistForm();

      this.modalRef.close('');

      if (!onSubmit) {
        this.removeFormControls(this.selectedChecklist.items);
      }

      this.location.back();
      Object.defineProperty(this.modalRef, 'data', { value: 'isClosed', writable: false });
    }
  }

  onSubmit(): void {
    this.processingChecklistItems();

    this.selectedChecklist = { ...this.selectedChecklist, items: this.checklistItems };
    this.defaultServiceInterval.lastTestDate = extractOnlyDate(this.defaultServiceInterval.lastTestDate);
    this.defaultServiceInterval.nextTestDate = extractOnlyDate(this.defaultServiceInterval.nextTestDate);

    this.checkBarcodeFeatureToggle(this.prepareDateForSubmit());
  }

  prepareDateForSubmit(): any {
    const checklist: IChecklist = this.selectedChecklist;

    this.restoreServiceTaskReasonService.restoreReasonName(this.selectedTaskIntervals, this.otherTaskIntervals);

    return {
      taskData: {
        others: this.otherTaskIntervals.map((interval: ITask) => {
          delete interval.children;
          delete interval.isInherited;

          return {
            ...interval,
            lastTestDate: extractOnlyDate(interval.lastTestDate),
            nextTestDate: extractOnlyDate(interval.nextTestDate),
            taskId: interval.serviceId,
          };
        }),
        includedTasks: this.selectedTaskIntervals.map((interval: ITask) => {
          return {
            ...interval,
            lastTestDate: extractOnlyDate(interval.lastTestDate),
            nextTestDate: extractOnlyDate(interval.nextTestDate),
            taskId: interval.serviceId,
          };
        }),
      },
      identification: (this.loadedEquipment as IEquipment).generalData.identifier,
      equipmentCategory: (this.loadedEquipment as IEquipment).generalData.model.category.name,
      equipmentType: (this.loadedEquipment as IEquipment).generalData.model.type.name,
      equipmentModel: (this.loadedEquipment as IEquipment).generalData.model.name,
      testerName: `${this.user.lastname}, ${this.user.firstname}`,
      barcode: (this.loadedEquipment as IEquipment).generalData.barcode,
      rfid: (this.loadedEquipment as IEquipment).generalData.rfid,
      serialNo: (this.loadedEquipment as IEquipment).generalData.serialNo,
      newBarcode: (this.completeChecklistForm.get('barcode').value as string)?.trim()
        ? (this.completeChecklistForm.get('barcode').value as string)?.trim()
        : null,
      newRfid: (this.completeChecklistForm.get('rfid').value as string)?.trim()
        ? (this.completeChecklistForm.get('rfid').value as string)?.trim()
        : null,
      operationalStatus: this.completeChecklistForm.get('operationalStatus').getRawValue() as string,
      locationPath: processLocationPath((this.loadedEquipment as IEquipment).locationAssignment?.path),
      equipmentAggregateId: (this.loadedEquipment as IEquipment).aggregateId,
      outOfServiceDate: this.completeChecklistForm.get('outOfOrderDate').value as string,
      outOfServiceReason: this.completeChecklistForm.get('outOfServiceReason').value as string,
      checklist,
    };
  }

  checkBarcodeFeatureToggle(objToSend: unknown): void {
    if (this.barcodeUpdateInTaskFeatureToggle) {
      const barcode: string = this.completeChecklistForm.get('barcode').dirty
        ? (this.completeChecklistForm.get('barcode').value as string)?.trim() || undefined
        : undefined;
      const rfid: string = this.completeChecklistForm.get('rfid').dirty
        ? (this.completeChecklistForm.get('rfid').value as string)?.trim() || undefined
        : undefined;

      if (barcode || rfid) {
        this.equipmentUpdateIdsActions.requestUpdateEquipmentIds(
          (this.loadedEquipment as IEquipment).aggregateId,
          {
            barcode,
            rfid,
          },
          (this.loadedEquipment as IEquipment).version,
        );

        this.handleUpdateEquipmentFields(objToSend);

        return;
      }
    }

    this.equipmentServiceTaskActions.requestSaveServicesChecklist(objToSend);
    this.handleCompleteChecklist();
  }

  handleUpdateEquipmentFields(objToSend: any): void {
    this.store
      .pipe(
        select(selectUpdatedEquipmentIds),
        filter((updatedEquipmentFields: IStoreApiItem<string[]>) => !updatedEquipmentFields.isLoading),
        take(1),
      )
      .subscribe((response: IStoreApiItem<string[]>) => {
        if (response.isSuccess) {
          this.equipmentUpdateIdsActions.resetUpdateEquipmentIds();

          this.equipmentServiceTaskActions.requestSaveServicesChecklist(objToSend);
          this.handleCompleteChecklist();

          return;
        }

        if (response.errors) {
          const [errorCode, barcodeError, rfidError]: [string, string, string] = [
            response.errors?.error.code.toString() as string,
            EquipmentNotificationConstants.notificationCodes.EQUIPMENT_BARCODE_ALREADY_EXISTS.value,
            EquipmentNotificationConstants.notificationCodes.EQUIPMENT_RFID_ALREADY_EXISTS.value,
          ];

          let notificationType: string = CommonConstants.notificationType.ERROR;

          if ([barcodeError, rfidError].includes(errorCode)) {
            this.completeChecklistForm.get(errorCode === rfidError ? 'rfid' : 'barcode').setErrors({ invalid: true });

            notificationType = CommonConstants.notificationType.HIDDEN;
          }

          this.notificationsService.requestShowNotification(
            notificationType,
            errorCode,
            EquipmentNotificationConstants.notificationCodes,
          );
        }

        this.cdr.detectChanges();
      });
  }

  handleCompleteChecklist(): void {
    this.store
      .pipe(
        select(selectedSavedServicesChecklist),
        filter((checklistCompleted: IStoreApiList<ITask>) => !checklistCompleted.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((response: IStoreApiItem<ITask>) => {
        if (response.isSuccess) {
          this.areFilesUploaded = false;
          this.completeChecklistForm.markAsPristine();
          this.closeModal(true);

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            EquipmentNotificationConstants.notificationCodes.COMPLETE_CHECKLIST_SUCCESS,
            EquipmentNotificationConstants.notificationCodes,
          );

          if (this.router.url.includes(WorkshopModuleRoutes.workflow)) {
            const idsToBeRemoved: string[] = [(this.loadedEquipment as IEquipment)?.aggregateId];

            this.storage.set(CommonConstants.markEquipmentToBeRemovedAfterChecklist, idsToBeRemoved).subscribe(() => {
              return;
            });

            const savedEquipmentsIds: string[] =
              this.userStorageService
                .getStoredValue('workflowTableStorage')
                ?.filter((id: string) => id !== idsToBeRemoved[0]) || [];

            if (savedEquipmentsIds.length) {
              this.equipmentCRUDActions.requestSavedEquipments(savedEquipmentsIds);
              this.storage.set(CommonConstants.updateWorkflowTableDataAfterChecklist, true).subscribe(() => {
                return;
              });
            }

            this.storage.set(CommonConstants.updateWorkflowFooterItemsAfterChecklist, true).subscribe(() => {
              return;
            });
          }
        }

        this.cdr.detectChanges();
      });
  }

  processingTasksTablesData(serviceId: string): void {
    this.otherTaskIntervals = [];
    this.selectedTaskIntervals = [];

    (this.loadedEquipment as IEquipment).serviceIntervals.forEach((elem: IEquipmentServiceInterval) => {
      if (elem.serviceId && elem.serviceId === serviceId) {
        this.selectedTaskIntervals.push(new Task({ ...elem, description: null, checklist: null }));

        elem?.children?.forEach((child: any) => {
          this.selectedTaskIntervals.push(child);
        });
      }
    });

    const selectedIntervals: string[] = this.selectedTaskIntervals.map((interval: ITask) => interval.serviceId);

    this.otherTaskIntervals = (this.loadedEquipment as IEquipment).serviceIntervals.filter(
      (interval: IEquipmentServiceInterval) => !selectedIntervals.includes(interval.serviceId) && interval.serviceId,
    );

    sessionStorage.setItem(WorkshopConstants.tasksIncluded, JSON.stringify(this.selectedTaskIntervals));
    sessionStorage.setItem(WorkshopConstants.otherTasks, JSON.stringify(this.otherTaskIntervals));

    this.otherTaskIntervals = structuredClone(this.otherTaskIntervals);
    this.selectedTaskIntervals = structuredClone(this.selectedTaskIntervals);

    this.selectedTaskIntervals.forEach((service: ITask) => {
      service.nextNew = this.calculateNewTaskIntervalDate(service) as string;
    });

    this.otherTaskIntervals.forEach((otherService: ITask) => {
      otherService.included = false;
      otherService.nextNew = this.calculateNewTaskIntervalDate(otherService) as string;
    });

    this.selectedTaskIntervals.forEach((service: ITask) => {
      service.taskName = service.inheritedFrom
        ? `${service.taskName} (${this.translateService.instant('EQUIPMENT_TASK_RESULTS.STR_INHERITED')} ${service.inheritedFrom})`
        : service.taskName;
    });

    this.otherTaskIntervals.forEach((service: ITask) => {
      service.taskName = service.inheritedFrom
        ? `${service.taskName} (${this.translateService.instant('EQUIPMENT_TASK_RESULTS.STR_INHERITED')} ${service.inheritedFrom})`
        : service.taskName;
    });
  }

  checkIfTaskIsChangedWithFormDirty(): void {
    let formControl: string[] = [];
    const isIncluded: IEquipmentServiceInterval[] = this.otherTaskIntervals.filter(
      (service: Partial<IEquipmentServiceInterval>) => service.included,
    );
    let selectedChecklist: IChecklist;

    this.completeChecklistForm.get('taskData')?.markAsPristine();

    Object.keys(this.completeChecklistForm.controls)?.forEach((controlName: string) => {
      if (this.completeChecklistForm.get(controlName)?.dirty) {
        formControl.push(controlName);
      }
    });

    if (formControl.length > 0 || isIncluded.length > 0 || this.areFilesUploaded) {
      this.openConfirmChangeTasks = true;
      formControl = [];

      return;
    }

    this.isLoading = true;

    this.createCompleteChecklistForm();

    this.availableChecklists.forEach((elem: IAvailableChecklist) => {
      if (elem.serviceId === this.selectedServices.serviceId) {
        selectedChecklist = elem.checklist;
      }
    });

    this.selectedChecklist = selectedChecklist;

    this.defaultServiceInterval = structuredClone(this.selectedServices);
    this.completeChecklistForm.patchValue({
      taskData: this.defaultServiceInterval,
      checklistName: selectedChecklist?.name,
      checklistDescription: selectedChecklist?.description,
      operationalStatus: (this.loadedEquipment as IEquipment).generalData?.operationalStatus,
      outOfOrderDate: (this.loadedEquipment as IEquipment).generalData?.outOfOrderDate,
      outOfServiceReason: (this.loadedEquipment as IEquipment).generalData?.outOfServiceReason,
    });
    this.processChecklistItemsData(selectedChecklist.items);

    this.isLoading = false;
  }

  getOtherTaskIntervals(event: ITask[] | any[]): void {
    this.otherTaskIntervals = event;
  }
}
