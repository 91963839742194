import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalRef, ModalService, ModalSize, ModalVariant } from '@odx/angular/components/modal';
import { IInvalidEmail } from 'src/app/user-management/models/user.model';
import { IErrorCodesForCSV, ITableColumn } from '../../models';

@Component({
  selector: 'ignis-import-csv-error-modal',
  templateUrl: './import-csv-error-modal.component.html',
  styleUrl: './import-csv-error-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportCsvErrorModalComponent implements OnChanges, OnDestroy {
  @ViewChild('importCSVErrorModal', { read: TemplateRef })
  public importCSVErrorModal: TemplateRef<any>;

  modalRef: ModalRef | any;

  @Input() openModal: boolean;
  @Input() invalidDataList: IInvalidEmail[];
  @Input() tableColumns: ITableColumn[];
  @Input() errorsConstant: IErrorCodesForCSV;
  @Input() pageUrl: string;

  @Output() handleCloseModal: EventEmitter<boolean> = new EventEmitter();

  modalService: ModalService = inject(ModalService);
  router: Router = inject(Router);
  translateService: TranslateService = inject(TranslateService);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  ngOnChanges(): void {
    if (Array.isArray(this.invalidDataList)) {
      const errorList: IInvalidEmail[] = structuredClone(this.invalidDataList);

      errorList.forEach((error: IInvalidEmail, index: number) => {
        error.errorMessage = this.translateErrorMessage(error.code);
        error.aggregateId = index;
      });

      this.invalidDataList = errorList;
    }

    if (this.openModal) {
      this.openCSVErrorModal();
    }

    this.cdr.detectChanges();
  }

  translateErrorMessage(errorCode: number): string {
    return this.translateService.instant(this.errorsConstant[errorCode].text);
  }

  openCSVErrorModal(): void {
    this.modalRef = this.modalService.open(this.importCSVErrorModal, {
      size: ModalSize.MEDIUM,
      variant: ModalVariant.DEFAULT,
      dismissable: false,
      data: 'isOpen',
    });
  }

  closeModal(): void {
    this.openModal = false;
    this.handleCloseModal.emit(false);
    Object.defineProperty(this.modalRef, 'data', { value: 'isClosed', writable: false });
  }

  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (this.router.url !== this.pageUrl && this.modalRef?.data === 'isOpen') {
      this.modalRef.close('');
    }
  }
}
