<div class="odx-app-page__content">
  <div
    class="custom-odx-tab-bar odx-margin-bottom-24"
    *ngIf="
      (router.url?.split('/').length < 4 ||
        router.url?.includes(WorkshopModuleRoutes.cylinder) ||
        router.url?.includes(WorkshopModuleRoutes.completeChecklist) ||
        router.url?.includes(WorkshopModuleRoutes.completeBatchChecklist)) &&
      !router.url?.includes(WorkshopModuleRoutes.createEquipment)
    "
  >
    <div class="odx-tab-bar__inner">
      <div class="odx-tab-bar__panel">
        <div
          [ngClass]="{
            'tab-is-active': router.url?.includes(WorkshopModuleRoutes.workflow),
          }"
        >
          <button
            odxButton
            class="odx-tab-bar-item"
            [ngClass]="{
              disabled: !accessControlService.checkPermission(appUserPermissionList.equipmentTest),
            }"
            [disabled]="!accessControlService.checkPermission(appUserPermissionList.equipmentTest)"
            id="scanTab"
            data-test-id="inventory_tab.workflow_btn"
            (click)="navigateToWorkflowTab()"
            *ngIf="equipmentWorkflowTab"
            [pTooltip]="
              accessControlService.checkPermission(appUserPermissionList.equipmentTest) ? null : noPermissionTooltip
            "
            tooltipPosition="bottom"
            tooltipStyleClass="tab-bottom-btn-tooltip"
          >
            {{ 'WORKSHOP_TAB.STR_WORKFLOW' | translate }}
          </button>
          <ng-template
            #noPermissionTooltip
            id="no_permission__tooltip"
            data-test-id="equipment_footer_items.no_permission_tooltip"
          >
            {{ 'EQUIPMENT_FOOTER_ITEMS.STR_NO_PERMISSION' | translate }}
          </ng-template>
        </div>
        <div
          [ngClass]="{
            'tab-is-active':
              !router.url?.includes(WorkshopModuleRoutes.workflow) &&
              !router.url?.includes(WorkshopModuleRoutes.tasks) &&
              !router.url?.includes(WorkshopModuleRoutes.taskDocumentation),
          }"
        >
          <button
            odxButton
            class="odx-tab-bar-item"
            *ngIf="inventoryTab"
            id="inventoryTab"
            data-test-id="inventory_tab.inventory_btn"
            (click)="navigateToInventoryTab()"
          >
            {{ 'WORKSHOP_TAB.STR_INVENTORY' | translate }}
          </button>
        </div>
        <div
          [ngClass]="{
            'tab-is-active': router.url?.includes(WorkshopModuleRoutes.tasks),
          }"
        >
          <button
            odxButton
            class="odx-tab-bar-item"
            [ngClass]="{
              disabled: !accessControlService.checkPermission(appUserPermissionList.equipmentTest),
            }"
            [disabled]="!accessControlService.checkPermission(appUserPermissionList.equipmentTest)"
            id="tasksTab"
            data-test-id="inventory_tab.tasks_btn"
            (click)="navigateToTasksTab()"
            *ngIf="tasksTab"
            [pTooltip]="
              accessControlService.checkPermission(appUserPermissionList.equipmentTest) ? null : taskTabTooltipContent
            "
            tooltipPosition="bottom"
            tooltipStyleClass="tab-bottom-btn-tooltip"
          >
            {{ 'WORKSHOP_TAB.STR_SERVICE_TASKS' | translate }}
          </button>
          <ng-template #taskTabTooltipContent>
            <div id="no_permission__tooltip_service" data-test-id="inventory_tab.tasks_btn_NO_PERMISSION_TOOLTIP">
              {{ 'EQUIPMENT_FOOTER_ITEMS.STR_NO_PERMISSION' | translate }}
            </div>
          </ng-template>
        </div>
        <div
          [ngClass]="{
            'tab-is-active': router.url?.includes(WorkshopModuleRoutes.taskDocumentation),
          }"
        >
          <button
            odxButton
            class="odx-tab-bar-item"
            [ngClass]="{
              disabled: !accessControlService.checkPermission(appUserPermissionList.equipmentTaskDocumentation),
            }"
            [disabled]="!accessControlService.checkPermission(appUserPermissionList.equipmentTaskDocumentation)"
            id="testResultsTab"
            data-test-id="inventory_tab.task_results_btn"
            (click)="navigateToTaskDocumentationResultsTab()"
            [pTooltip]="
              accessControlService.checkPermission(appUserPermissionList.equipmentTaskDocumentation)
                ? null
                : taskDocumentationTooltipContent
            "
            tooltipPosition="bottom"
            tooltipStyleClass="tab-bottom-btn-tooltip"
          >
            {{ 'WORKSHOP_TAB.STR_TASK_DOCUMENTATION' | translate }}
          </button>
          <ng-template #taskDocumentationTooltipContent>
            <div
              id="no_permission__tooltip_delete"
              data-test-id="inventory_tab.task_results_wrap_NO_PERMISSION_TOOLTIP"
            >
              {{ 'EQUIPMENT_FOOTER_ITEMS.STR_NO_PERMISSION' | translate }}
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <div class="workshop-tables">
    <router-outlet></router-outlet>
  </div>
</div>
