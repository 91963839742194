<p-multiSelect
  #multiSelection
  ngDefaultControl
  data-test-id="report_center_table.incident_type_filter"
  appendTo="body"
  [dropdownIcon]="dropdownIconCSSClass"
  [ngModel]="selectedTypes"
  class="table-multiSelect-filter"
  [options]="incidentTypes"
  [placeholder]="'REPORT_CENTER_TABLE.SELECT_TYPE' | translate"
  [filter]="true"
  [resetFilterOnHide]="true"
  (onChange)="filterService($event.value); saveFilters('type', $event.value)"
>
  <ng-template pTemplate="header">
    <button
      type="button"
      class="p-multiselect-close p-link p-multiselect-custom-close-btn"
      data-test-id="report_center_table.reset_incident_type_filter_btn"
      (click)="multiSelection.hide(); removeSelectedTypes(); saveFilters('type', []); filterService($event.value)"
    >
      <span class="p-multiselect-close-icon pi pi-times"></span>
    </button>
  </ng-template>
  <ng-template let-value pTemplate="selectedItems">
    <div *ngIf="value !== null && value?.length === 1">
      <div class="ui-multiselected-item-token ui-corner-all">
        <span>{{ incidentTypesMap[value[0]] }}</span>
      </div>
    </div>
    <ignis-multiple-items-selected [value]="value"></ignis-multiple-items-selected>
    <span *ngIf="!value || value?.length === 0" class="ui-multiselected-empty-token ui-corner-all">
      {{ 'REPORT_CENTER_TABLE.SELECT_TYPE' | translate }}
    </span>
  </ng-template>
  <ng-template let-option pTemplate="item">
    <span
      class="dropdown-option-value"
      [pTooltip]="option.label?.length > 30 && option?.label ? option.label : null"
      tooltipPosition="top"
      positionTop="-10"
      >{{ option.label.substring(0, 30) }}</span
    >
  </ng-template>
</p-multiSelect>
