import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IApplicationInsights } from '@microsoft/applicationinsights-web';
import { select, Store } from '@ngrx/store';
import { filter, Subscription, take } from 'rxjs';
import { CommonConstants, IApplicationState, IStoreApiItem, NotificationsService } from 'src/app/common';
import { ITestStatus } from 'src/app/workshop/models';
import { EquipmentTestActions } from 'src/app/workshop/state/actions/equipment-test';
import { selectTestEquipmentInProgress } from 'src/app/workshop/state/selectors';
import {
  EquipmentNotificationConstants,
  ProtectorSoftwareNotificationConstants,
  WorkshopConstants,
} from 'src/app/workshop/constants';

@Component({
  selector: 'ignis-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.scss'],
})
export class TestingComponent implements OnInit, OnDestroy {
  testRun: string;
  previousRoute: string;
  statusInterval: ReturnType<typeof setTimeout>;
  interval: number = 2000;
  isTestInProgress: boolean = false;

  sub: Subscription;

  public router: Router = inject(Router);
  public notificationsService: NotificationsService = inject(NotificationsService);
  route: ActivatedRoute = inject(ActivatedRoute);
  store: Store<IApplicationState> = inject<Store<IApplicationState>>(Store<IApplicationInsights>);
  equipmentTestActions: EquipmentTestActions = inject(EquipmentTestActions);

  constructor() {
    this.getTestStatusOrNavigateToPreviousPage();

    this.route.params.pipe(take(1)).subscribe((params: { id: string }) => {
      this.testRun = params.id;
    });
  }

  ngOnInit(): void {
    this.isTestInProgress = true;

    this.previousRoute = localStorage.getItem('previousRoute');
  }

  getTestStatusOrNavigateToPreviousPage(): void {
    if (WorkshopConstants.testIsCanceledStorageKey in localStorage) {
      this.router.navigate([localStorage.getItem(WorkshopConstants.testStartFrom)]);
      localStorage.removeItem(WorkshopConstants.testIsCanceledStorageKey);
    } else {
      setTimeout(() => {
        this.equipmentTestActions.requestEquipmentTestIsRunning();

        this.statusInterval = setInterval(() => {
          this.equipmentTestActions.requestEquipmentTestIsRunning();
        }, 1000);

        const checkDelay: ReturnType<typeof setTimeout> = setTimeout(() => {
          this.equipmentTestStatus();
          clearTimeout(checkDelay);
        }, 1000);
      }, 1000);
    }
  }

  equipmentTestStatus(): void {
    this.sub = this.store
      .pipe(
        select(selectTestEquipmentInProgress),
        filter((status: IStoreApiItem<ITestStatus>) => !status.isLoading),
      )
      .subscribe((status: IStoreApiItem<ITestStatus>) => {
        if (status.errors || (status.data?.testResult && status.data.testResult.testRun !== this.testRun)) {
          if (
            (status.errors.error.code as number) ===
            Number(ProtectorSoftwareNotificationConstants.notificationCodes.PROTECTOR_SOFTWARE_BACKEND_IS_DOWN.value)
          ) {
            this.notificationsService.requestShowNotification(
              CommonConstants.notificationType.ERROR,
              ProtectorSoftwareNotificationConstants.notificationCodes.PROTECTOR_SOFTWARE_BACKEND_IS_DOWN,
              ProtectorSoftwareNotificationConstants.notificationCodes,
            );
          } else {
            this.notificationsService.requestShowNotification(
              CommonConstants.notificationType.ERROR,
              EquipmentNotificationConstants.notificationCodes.DURING_TESTING_ERROR,
              EquipmentNotificationConstants.notificationCodes,
            );
          }

          this.stopLoading();
          this.router.navigate([...this.previousRoute.split('/')]);

          return;
        }

        if (status.data.status === 'idle' && status.data?.testResult === null) {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.ERROR,
            EquipmentNotificationConstants.notificationCodes.TEST_EQUIPMENT_CANCELED,
            EquipmentNotificationConstants.notificationCodes,
          );
          this.stopLoading();

          this.router.navigate([...this.previousRoute.split('/')]);
        } else if (
          status.data.status === 'idle' &&
          status.data.testResult?.testRun === this.testRun &&
          !status.data.previousTestWasCancelled
        ) {
          this.stopLoading();

          this.router.navigate(['workshop', 'testing-equipment', status.data.testResult.testResult, 'results']);
        } else if (status.data?.previousTestWasCancelled) {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.WARNING,
            EquipmentNotificationConstants.notificationCodes.TEST_EQUIPMENT_CANCELED,
            EquipmentNotificationConstants.notificationCodes,
          );

          localStorage.setItem(WorkshopConstants.testIsCanceledStorageKey, WorkshopConstants.testIsCanceledStorageKey);

          this.router.navigate([...this.previousRoute.split('/')]);
        }
      });
  }

  stopLoading(): void {
    this.isTestInProgress = false;
    clearInterval(this.statusInterval);
  }

  ngOnDestroy(): void {
    this.stopLoading();

    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
