import { ITableColumn } from 'src/app/common';
import { TableFilterType } from 'src/app/common/constants';

export const tableColumns: ITableColumn[] = [
  {
    field: 'identifier',
    header: 'INVENTORY.STR_UNIQUE_IDENTIFIER',
    visible: true,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'INVENTORY.STR_UNIQUE_IDENTIFIER_PLACEHOLDER',
  },
  {
    field: 'barcode',
    header: 'INVENTORY.STR_BARCODE',
    visible: true,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'INVENTORY.STR_BARCODE_PLACEHOLDER',
  },
  {
    field: 'rfid',
    header: 'RFID',
    visible: false,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'INVENTORY.STR_RFID_PLACEHOLDER',
  },
  {
    field: 'serialNo',
    header: 'INVENTORY.STR_SERIAL_NO',
    visible: false,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'INVENTORY.STR_SERIAL_NO_PLACEHOLDER',
  },
  {
    field: 'partNo',
    header: 'INVENTORY.STR_PART_NO',
    visible: false,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'INVENTORY.STR_PART_NO_PLACEHOLDER',
  },
  {
    field: 'partName',
    header: 'INVENTORY.STR_PART_NAME',
    visible: false,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'INVENTORY.STR_PART_NAME_PLACEHOLDER',
  },
  {
    field: 'typeId',
    header: 'INVENTORY.STR_EQUIPMENT_TYPE',
    visible: true,
    filterType: TableFilterType.MULTI_SELECT,
    filterKey: ['types'],
    filterPlaceholder: 'INVENTORY.STR_EQUIPMENT_TYPE_PLACEHOLDER',
  },
  {
    field: 'manufacturer',
    header: 'INVENTORY.STR_MANUFACTURER',
    visible: true,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'INVENTORY.STR_MANUFACTURER_PLACEHOLDER',
  },
  {
    field: 'model',
    header: 'INVENTORY.STR_EQUIPMENT_MODEL',
    visible: true,
    filterType: TableFilterType.MULTI_SELECT,
    filterKey: ['models'],
    filterPlaceholder: 'INVENTORY.STR_EQUIPMENT_MODEL_PLACEHOLDER',
  },
  {
    field: 'description',
    header: 'INVENTORY.STR_DESCRIPTION',
    visible: true,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'INVENTORY.STR_DESCRIPTION_PLACEHOLDER',
  },
  {
    field: 'operationalStatus',
    header: 'INVENTORY.STR_OPERATIONAL_STATUS',
    visible: true,
    filterType: TableFilterType.DROPDOWN,
    dropdownOptions: [],
    filterPlaceholder: 'INVENTORY.STR_OPERATIONAL_STATUS_PLACEHOLDER',
    dropdownWithIcon: true,
  },
  {
    field: 'serviceStatus',
    header: 'INVENTORY.STR_SERVICE_STATUS',
    visible: true,
    filterType: TableFilterType.DROPDOWN,
    dropdownOptions: [],
    filterPlaceholder: 'INVENTORY.STR_SERVICE_STATUS_PLACEHOLDER',
    dropdownWithIcon: true,
  },
  {
    field: 'location',
    header: 'INVENTORY.STR_LOCATION',
    visible: true,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'INVENTORY.STR_LOCATION_PLACEHOLDER',
  },
  {
    field: 'owner',
    header: 'INVENTORY.STR_OWNER',
    visible: false,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'INVENTORY.STR_OWNER_PLACEHOLDER',
  },
  {
    field: 'outOfService',
    header: 'INVENTORY.STR_OF_OF_SERVICE',
    visible: false,
    filterKey: ['outOfServiceStartDate', 'outOfServiceEndDate'],
    filterType: TableFilterType.DATE,
  },
  {
    field: 'firstRegisteredDate',
    header: 'INVENTORY.STR_FIRST_REGISTRATION_DATE',
    visible: false,
    filterType: TableFilterType.DATE,
    filterKey: ['firstRegisteredStartDate', 'firstRegisteredEndDate'],
  },
];

export const eqImportCSVColumns: ITableColumn[] = [
  { field: 'lineNumber', header: 'INVENTORY_CSV_IMPORT_ERROR_LIST.STR_LINE_NUMBER', visible: true },
  { field: 'equipmentIdentifier', header: 'INVENTORY_CSV_IMPORT_ERROR_LIST.STR_EQUIPMENT_ID', visible: true },
  { field: 'errorMessage', header: 'INVENTORY_CSV_IMPORT_ERROR_LIST.STR_ERROR_MSG', visible: true },
];
