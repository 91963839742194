<ignis-progress-bar *ngIf="isLoading"></ignis-progress-bar>

<ignis-table-settings
  class="report-center-list-table-settings"
  [tableColumns]="tableColumns"
  (changeColumnVisibility)="changeColumnVisibility($event)"
></ignis-table-settings>

<p-table
  #dt2
  styleClass="p-datatable-striped p-datatable-gridlines"
  scrollHeight="flex"
  class="fireground-table large-height-table"
  [value]="incidentList"
  [scrollable]="true"
  [lazy]="true"
  [paginator]="true"
  [loading]="isLoading"
  [rows]="rows"
  [columns]="tableHelperVisibleColumns"
  [first]="first"
  [filterDelay]="600"
  [rowsPerPageOptions]="rowsPerPageOptions"
  [totalRecords]="totalRecords"
  [resizableColumns]="true"
  [columnResizeMode]="'fit'"
  [reorderableColumns]="true"
  [(selectionMode)]="selectionMode"
  [selection]="selectionMode === 'multiple' ? selectedIncidents : activeIncident"
  (clickOutside)="onSelectIncident(null)"
  (onSort)="onRowSort($event)"
  (onLazyLoad)="loadData($event)"
  (onPage)="onPageChangeLocal($event)"
  (firstChange)="firstItemChange()"
  (onColReorder)="onColReorder($event)"
  (onColResize)="onColResize($event)"
>
  <ng-template pTemplate="header" let-columns>
    <tr class="table-header-column-title">
      <th
        *ngFor="let col of columns"
        class="column-header"
        pResizableColumn
        pReorderableColumn
        scope="col"
        [attr.data-test-id]="col.field"
        [ngClass]="{
          'selected-sort-column': sortColumn?.field === col.field,
          'deselected-sort-column': sortColumn?.field !== col.field,
          'multiSelect-overflow': col.field === 'hubIds' || col.field === 'duration' || col.field === 'occurredEvents',
        }"
      >
        <span class="sort-area" [pSortableColumn]="col.field"
          >{{ col.header | translate }}
          <ignis-sort-icon [columnName]="col.field" [columnSorted]="sortColumn"></ignis-sort-icon
        ></span>
      </th>
    </tr>

    <tr class="table-header-column-filter">
      <th
        *ngFor="let col of columns"
        scope="col"
        [ngClass]="{
          'selected-sort-column': sortColumn?.field === col.field,
          'deselected-sort-column': sortColumn?.field !== col.field,
          'multiSelect-overflow': col.field === 'hubIds' || col.field === 'occurredEvents' || col.field === 'duration',
        }"
      >
        <p-columnFilter
          class="w-100"
          *ngIf="col.field === 'startTime'"
          field="startTime"
          matchMode="startTime"
          [showMenu]="false"
          [showClearButton]="false"
        >
          <ng-template pTemplate="filter">
            <odx-form-field variant="simple" odxLayout="6@phone 12">
              <ignis-datepicker
                class="table-datepicker-filter"
                appendTo="body"
                [selectionMode]="'range'"
                [formatDate]="formatDate"
                [defaultRange]="defaultRange"
                data-test-id="report_center_list.date_filter"
                (dateSelect)="onDateSelect($event)"
                (dateChange)="onDateChange($event)"
              >
              </ignis-datepicker>
            </odx-form-field>
          </ng-template>
        </p-columnFilter>

        <p-columnFilter
          class="w-100"
          *ngIf="col.field === 'incidentNumber'"
          field="incidentNumber"
          matchMode="incidentNumber"
          [showMenu]="false"
          [showClearButton]="false"
        >
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <odx-form-field
              [variant]="'simple'"
              odxLayout="6@phone 12"
              [ngClass]="{ 'has-error': !isIncidentNolInputValueValid }"
            >
              <input
                type="text"
                ignisDisableAutocomplete
                odxFormFieldControl
                ignisNoAllowHtmlTags
                (emitIsInputValueValid)="isIncidentNolInputValueValid = $event"
                data-test-id="report_center_list.incident_number_filter"
                [ngClass]="{ 'text-filter-padding-right': tableHelperSavedFilters?.incidentNumber }"
                [ngModel]="tableHelperSavedFilters?.incidentNumber"
                [placeholder]="'REPORT_CENTER_TABLE.SEARCH_INCIDENT_NUMBER' | translate"
                [title]="
                  tableHelperSavedFilters?.incidentNumber
                    ? tableHelperSavedFilters?.incidentNumber
                    : ('REPORT_CENTER_TABLE.SEARCH_INCIDENT_NUMBER' | translate)
                "
                (input)="onFilter(col.field, $event.target.value)"
                (drop)="tableService.preventToDropColumn()"
              />

              <odx-action-group odxFormFieldSuffix>
                <button
                  odxButton
                  size="medium"
                  *ngIf="tableHelperSavedFilters?.incidentNumber"
                  data-test-id="report_center_list.clear_date_filter"
                  (click)="clearFilter('incidentNumber'); isIncidentNolInputValueValid = true"
                >
                  <odx-icon name="close" size="medium"></odx-icon>
                </button>
              </odx-action-group>

              <odx-icon
                *ngIf="!isIncidentNolInputValueValid"
                name="info"
                class="custom-validation-icon r-35"
                iconSet="core"
                [odxTooltip]="'TABLE.STR_NO_VALID_VALUE' | translate"
                [odxTooltipVisible]="!isBASerialInputValueValid"
              >
              </odx-icon>
            </odx-form-field>
          </ng-template>
        </p-columnFilter>

        <p-columnFilter
          class="w-100"
          *ngIf="col.field === 'type.value'"
          field="type.value"
          matchMode="incidentType"
          [showMenu]="false"
          [showClearButton]="false"
        >
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <ignis-incident-type-filter
              *ngIf="entries?.types"
              [filterService]="filter"
              [typesList]="entries?.types"
              (handleSaveFilters)="onFilter($event.name, $event.data)"
              [selectedTypes]="selectedTypes"
            ></ignis-incident-type-filter>
          </ng-template>
        </p-columnFilter>

        <p-columnFilter
          class="w-100"
          *ngIf="col.field === 'duration'"
          field="formattedDuration"
          matchMode="duration"
          [showMenu]="false"
          [showClearButton]="false"
        >
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <ignis-duration-filter
              [filterService]="filter"
              [selectedDurations]="selectedDurations"
              (handleSaveFilters)="onFilter($event.name, $event.data)"
            ></ignis-duration-filter>
          </ng-template>
        </p-columnFilter>

        <p-columnFilter
          class="w-100"
          *ngIf="col.field === 'ecpAddresses'"
          field="ecpAddresses"
          matchMode="ecpAddresses"
          [showMenu]="false"
          [showClearButton]="false"
        >
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <odx-form-field
              [variant]="'simple'"
              odxLayout="6@phone 12"
              [ngClass]="{ 'has-error': !isIncidentLocationInputValueValid }"
            >
              <input
                type="text"
                ignisDisableAutocomplete
                odxFormFieldControl
                ignisNoAllowHtmlTags
                (emitIsInputValueValid)="isIncidentLocationInputValueValid = $event"
                data-test-id="report_center_list.ecp_address_filter"
                [ngClass]="{ 'text-filter-padding-right': tableHelperSavedFilters?.ecpAddresses }"
                [ngModel]="tableHelperSavedFilters?.ecpAddresses"
                [placeholder]="'REPORT_CENTER_TABLE.SEARCH_LOCATION' | translate"
                [title]="
                  tableHelperSavedFilters?.ecpAddresses
                    ? tableHelperSavedFilters?.ecpAddresses
                    : ('REPORT_CENTER_TABLE.SEARCH_LOCATION' | translate)
                "
                (input)="onFilter(col.field, $event.target.value)"
                (drop)="tableService.preventToDropColumn()"
              />
              <odx-action-group odxFormFieldSuffix>
                <button
                  odxButton
                  size="medium"
                  *ngIf="tableHelperSavedFilters?.ecpAddresses"
                  data-test-id="report_center_list.clear_ecp_address_filter"
                  (click)="clearFilter('ecpAddresses'); isIncidentLocationInputValueValid = true"
                >
                  <odx-icon name="close" size="medium"></odx-icon>
                </button>
              </odx-action-group>

              <odx-icon
                *ngIf="!isIncidentLocationInputValueValid"
                name="info"
                class="custom-validation-icon r-35"
                iconSet="core"
                [odxTooltip]="'TABLE.STR_NO_VALID_VALUE' | translate"
                [odxTooltipVisible]="!isIncidentLocationInputValueValid"
              >
              </odx-icon>
            </odx-form-field>
          </ng-template>
        </p-columnFilter>

        <p-columnFilter
          class="w-100"
          *ngIf="col.field === 'ecpNames'"
          field="ecpNames"
          matchMode="ecpNames"
          [showMenu]="false"
          [showClearButton]="false"
        >
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <odx-form-field
              [variant]="'simple'"
              odxLayout="6@phone 12"
              [ngClass]="{ 'has-error': !isECPNamesInputValueValid }"
            >
              <input
                type="text"
                ignisDisableAutocomplete
                odxFormFieldControl
                ignisNoAllowHtmlTags
                (emitIsInputValueValid)="isECPNamesInputValueValid = $event"
                data-test-id="report_center_list.ecp_name_filter"
                [ngClass]="{ 'text-filter-padding-right': tableHelperSavedFilters?.ecpNames }"
                [ngModel]="tableHelperSavedFilters?.ecpNames"
                [placeholder]="'REPORT_CENTER_TABLE.SEARCH_ECP_NAME' | translate"
                [title]="
                  tableHelperSavedFilters?.ecpNames
                    ? tableHelperSavedFilters?.ecpNames
                    : ('REPORT_CENTER_TABLE.SEARCH_ECP_NAME' | translate)
                "
                (input)="onFilter(col.field, $event.target.value)"
                (drop)="tableService.preventToDropColumn()"
              />
              <odx-action-group odxFormFieldSuffix>
                <button
                  odxButton
                  size="medium"
                  *ngIf="tableHelperSavedFilters?.ecpNames"
                  data-test-id="report_center_list.clear_ecp_name_filter"
                  (click)="clearFilter('ecpNames'); isECPNamesInputValueValid = true"
                >
                  <odx-icon name="close" size="medium"></odx-icon>
                </button>
              </odx-action-group>

              <odx-icon
                *ngIf="!isECPNamesInputValueValid"
                name="info"
                class="custom-validation-icon r-35"
                iconSet="core"
                [odxTooltip]="'TABLE.STR_NO_VALID_VALUE' | translate"
                [odxTooltipVisible]="!isECPNamesInputValueValid"
              >
              </odx-icon>
            </odx-form-field>
          </ng-template>
        </p-columnFilter>

        <p-columnFilter
          class="w-100"
          *ngIf="col.field === 'hubIds'"
          field="hubIds"
          matchMode="hubIds"
          [showMenu]="false"
          [showClearButton]="false"
        >
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <ignis-hub-ids-filter
              [filterService]="filter"
              [selectedHubIds]="selectedHubIds"
              [hubIdsList]="hubIdsList"
              (handleSaveFilters)="onFilter($event.name, $event.data)"
            ></ignis-hub-ids-filter>
          </ng-template>
        </p-columnFilter>

        <p-columnFilter
          class="w-100"
          *ngIf="col.field === 'firefighterNo'"
          field="firefighterNo"
          matchMode="firefighterNo"
          [showMatchModes]="false"
          [showOperator]="false"
          [showAddButton]="false"
          [showMenu]="false"
          [showClearButton]="false"
        >
          <ng-template pTemplate="filter" let-filter="filterCallback">
            <div class="row m-b-15">
              <div class="col-6 pe-0">
                <input
                  pInputText
                  ngDefaultControl
                  type="number"
                  ignisDisableAutocomplete
                  data-test-id="report_center_list.slider_min_filter"
                  placeholder="{{ 'TABLE.RANGE_SLIDER_MIN' | translate }}"
                  [min]="0"
                  [max]="sliderMaxValue"
                  [ngModel]="sliderValues[0]"
                  (ngModelChange)="sliderValues[0] = $event"
                  (change)="sliderInputChange($event, 'min')"
                  (drop)="tableService.preventToDropColumn()"
                />
              </div>
              <div class="col-6 ps-0">
                <input
                  pInputText
                  ngDefaultControl
                  type="number"
                  data-test-id="report_center_list.slider_max_filter"
                  ignisDisableAutocomplete
                  placeholder="{{ 'TABLE.RANGE_SLIDER_MAX' | translate }}"
                  [min]="0"
                  [max]="sliderMaxValue"
                  [ngModel]="sliderValues[1]"
                  (ngModelChange)="sliderValues[1] = $event"
                  (change)="sliderInputChange($event, 'max')"
                  (drop)="tableService.preventToDropColumn()"
                />
              </div>
            </div>
            <div class="m-b-15">
              <p-slider
                ngDefaultControl
                styleClass="p-m-3 z-index-1"
                data-test-id="report_center_list.slider_filter"
                [ngModel]="sliderValues"
                (ngModelChange)="sliderValues = $event"
                [range]="true"
                [step]="1"
                [min]="0"
                [max]="sliderMaxValue"
                [disabled]="sliderMaxValue === sliderMinValue && sliderMaxValue === 0"
                (change)="onFilter('firefighterNo', $event.values)"
                (onSlideEnd)="onFilter('firefighterNo', $event.values)"
              ></p-slider>
            </div>
          </ng-template>
        </p-columnFilter>

        <p-columnFilter
          class="w-100"
          *ngIf="col.field === 'occurredEvents'"
          field="occurredEvents"
          matchMode="occurredEvents"
          [showMenu]="false"
          [showClearButton]="false"
        >
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <ignis-noteworthy-events-filter
              [filterService]="filter"
              [selectedOccurredEvents]="selectedOccurredEvents"
              (handleSaveFilters)="onFilter($event.name, $event.data)"
            ></ignis-noteworthy-events-filter>
          </ng-template>
        </p-columnFilter>
      </th>
    </tr>
    <div
      class="table-header-right-wrapper"
      [ngClass]="{
        'selected-sort-column': sortColumn?.field === lastTableElement,
        'deselected-sort-column': sortColumn?.field !== lastTableElement,
      }"
    ></div>
  </ng-template>
  <ng-template pTemplate="body" let-incident let-rowIndex="rowIndex" let-columns="columns">
    <tr
      class="dynamic-column-width"
      data-test-id="report_center_list.selected_incident"
      (click)="onSelectIncident(incident)"
      (dblclick)="navigateToUpdateIncident()"
      [ngClass]="{
        'selected-row': activeIncident?.aggregateId === incident.aggregateId,
      }"
      [pSelectableRow]="incident"
      [attr.data-column]="incident.aggregateId"
    >
      <td
        *ngFor="let col of columns"
        [ngClass]="{
          'multiSelect-overflow': col.field === 'hubIds' || col.field === 'occurredEvents' || col.field === 'duration',
        }"
        [attr.data-column]="col.field"
      >
        <span *ngIf="col.field === 'startTime'">{{ incident.startTime | date: formatDate }}</span>
        <span *ngIf="col.field === 'incidentNumber'">{{ incident.incidentNumber }}</span>
        <span *ngIf="col.field === 'type.value'">
          <ignis-incident-type-translation [entry]="incident.type"></ignis-incident-type-translation>
        </span>
        <span *ngIf="col.field === 'startHour'">{{ incident.startHour }}</span>
        <span *ngIf="col.field === 'duration'">{{ incident.formattedDuration }}</span>
        <span *ngIf="col.field === 'ecpAddresses'">{{ formatLocation(incident.primaryEcp?.location) }}</span>
        <span *ngIf="col.field === 'ecpNames'">
          <span class="new-line-text" *ngFor="let ecpName of incident.ecpNames?.slice(0, 3); index as i">
            <span
              [pTooltip]="ecpName?.length > ecpNameCharactersToShow ? ecpName : ''"
              tooltipPosition="top"
              positionTop="-10"
              >{{ truncateData(ecpName, ecpNameCharactersToShow) }}</span
            >
          </span>
          <span
            *ngIf="incident.ecpNames?.length > 3"
            [pTooltip]="composeListTooltip(incident.ecpNames)"
            tooltipPosition="top"
            positionTop="-10"
            >[...]</span
          ></span
        >
        <span *ngIf="col.field === 'hubIds'">
          <span class="new-line-text" *ngFor="let hubId of incident.hubIds?.slice(0, 3); index as i">
            <span
              [pTooltip]="hubId?.length > hubIdsCharactersToShow ? hubId : ''"
              tooltipPosition="top"
              positionTop="-10"
            >
              {{ truncateData(hubId ? hubId : '-', hubIdsCharactersToShow) }}</span
            >
          </span>

          <span
            *ngIf="incident.hubIds?.length > 3"
            [pTooltip]="composeListTooltip(incident.hubIds)"
            tooltipPosition="top"
            positionTop="-10"
            >[...]</span
          ></span
        >

        <span *ngIf="col.field === 'firefighterNo'">{{ incident.firefighterNo }}</span>
        <span *ngIf="col.field === 'occurredEvents'"
          ><ignis-noteworthy-events-column [incident]="incident"></ignis-noteworthy-events-column
        ></span>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage" data-test-id="table.no_entries">
    <tr>
      <td colspan="10">{{ 'REPORT_CENTER_TABLE.NO_REPORT_FOUND' | translate }}</td>
    </tr>
  </ng-template>

  <ng-template pTemplate="paginatorleft" let-state>
    <span *ngIf="totalRecords">
      {{ totalRecords }}
      <span *ngIf="totalRecords > 1; else onlyOneItem">{{ 'TABLE.STR_ITEMS' | translate }}</span>
      <ng-template #onlyOneItem>
        {{ 'TABLE.STR_ITEM' | translate }}
      </ng-template>
      <span *ngIf="selectionMode === 'multiple' && openMergeIncidents">
        (2 {{ 'TABLE.STR_ITEMS_SELECTED' | translate }})
      </span>
    </span>
    <span
      class="withheld-info"
      [pTooltip]="'REPORT_CENTER_TABLE.STR_WITHHELD_TOOLTIP' | translate"
      tooltipPosition="top"
      positionTop="-10"
      *ngIf="withheldReportsNo > 0"
      data-test-id="report_center_list.withheld_info"
    >
      <odx-icon size="inline" name="feedback-warning"></odx-icon>
      {{ withheldReportsNo }} {{ 'REPORT_CENTER_TABLE.STR_WITHHELD_LABEL' | translate }}
    </span>
  </ng-template>
  <ng-template pTemplate="paginatorright" let-state>
    @if (state.pageCount > 0) {
      <span> {{ 'TABLE.STR_TABLE_OF' | translate: { totalPageNumber: state.pageCount } }}</span>
    } @else {
      <span>1 {{ 'TABLE.STR_TABLE_OF' | translate: { totalPageNumber: 1 } }}</span>
    }
  </ng-template>
</p-table>

<ignis-export-table-excel
  [excelArray]="excelArray"
  [formatDate]="formatDate"
  [sheetName]="'EXCEL_TABLE_EXPORT.STR_REPORT_CENTER' | translate"
  [fileName]="'EXCEL_TABLE_EXPORT.STR_REPORT_CENTER' | translate"
  [tableColumns]="tableColumns"
  [excelTranslationKeys]="excelTranslationKeys"
  (handleExcelExportFinished)="excelExportDone()"
></ignis-export-table-excel>

<footer class="blue-bar">
  <ignis-report-center-list-view-footer
    [selectedItem]="activeIncident"
    [selectedMerge]="activateEditBtn"
    [mergeMode]="selectionMode === 'multiple'"
    [numberOfRecordsToBeExported]="totalRecords"
    [isModalOpened]="openMergeIncidents"
    (handleMergeIncidents)="initMerge()"
    (handleEdit)="navigateToUpdateIncident()"
    (handleExcelExport)="exportExcel(activeIncident)"
    (handTableToExcelFile)="exportExcelFile()"
  >
  </ignis-report-center-list-view-footer>

  <ignis-merge-incidents
    [openModal]="openMergeIncidents"
    [selectedIncidents]="selectedIncidents"
    [formatDate]="formatDate"
    (handleCloseMergeIncidentsModal)="cancelMergeIncidents($event)"
  >
  </ignis-merge-incidents>
</footer>
