import { INotificationConstant } from 'src/app/common';

export class EquipmentNotificationConstants {
  public static readonly notificationCodes: INotificationConstant = {
    MANDATORY_NAME_FIELD: {
      value: '2004',
      text: 'EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_ERRORS_CODES_MANDATORY_NAME_FIELD',
    },
    NAME_SHOULD_BE_UNIQUE: {
      value: '2005',
      text: 'EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_ERROR_CODES_NAME_SHOULD_BE_UNIQUE',
    },
    INVALID_EMAIL: {
      value: '2006',
      text: 'EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_ERROR_CODES_INVALID_EMAIL',
    },
    MANDATORY_EQUIPMENT_STATUS_FIELD: {
      value: '2007',
      text: 'EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_OPERATION_STATUS_MANDATORY',
    },
    NOT_CHRONOLOGICAL_IN_USE_OUT_OF_ORDER: {
      value: '2008',
      text: 'EQUIPMENT_NOTIFICATION.STR_MANUFACTURER_NOT_CHRONOLOGICAL_IN_USE_OUT_OF_ORDER',
    },
    INVALID_DELIVERY_DATE: {
      value: '2010',
      text: 'EQUIPMENT_NOTIFICATION.STR_MANUFACTURER_INVALID_DELIVERY_DATE',
    },
    INVALID_MANUFACTURER_DATE: {
      value: '2011',
      text: 'EQUIPMENT_NOTIFICATION.STR_MANUFACTURER_INVALID_DATE',
    },
    INVALID_TEST_INTERVALS: {
      value: '2012',
      text: 'EQUIPMENT_NOTIFICATION.STR_INVALID_TEST_INTERVALS',
    },
    UNEXPECTED_APPLICATION_ERROR: {
      value: '2013',
      text: 'EQUIPMENT_NOTIFICATION.STR_MANUFACTURER_UNEXPECTED_APPLICATION_ERROR',
    },
    ENTITY_DOES_NOT_EXIST: {
      value: '2014',
      text: 'EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_DELETED_OR_DONT_EXIST',
    },
    UPDATE_OLDER_VERSION: {
      value: '2015',
      text: 'EQUIPMENT_NOTIFICATION.STR_OLD_VERSION_EDIT_EQUIPMENT',
    },
    EQUIPMENT_BARCODE_ALREADY_EXISTS: {
      value: '2017',
      text: 'EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_BARCODE_ALREADY_EXISTS',
    },
    BARCODE_NO_EXIST: {
      value: '2018',
      text: 'EQUIPMENT_NOTIFICATION.STR_BARCODE_NO_EXIST',
    },
    BARCODE_NO_EXIST_TESTER: {
      value: 'BARCODE_NO_EXIST_TESTER',
      text: 'EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_SEARCHED_EQUIPMENT_NOT_EXIST_TESTER',
    },
    INVALID_SERVICE_INTERVAL: {
      value: '2042',
      text: 'EQUIPMENT_NOTIFICATION.STR_INVALID_SERVICE_INTERVAL',
    },
    BARCODE_CANNOT_HAVE_ONLY_SPACES: {
      value: '2043',
      text: 'INVENTORY_CSV_IMPORT_ERROR_LIST.STR_BARCODE_CANNOT_HAVE_ONLY_SPACES',
    },
    RFID_CANNOT_HAVE_ONLY_SPACES: {
      value: '2044',
      text: 'EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_RFID_CANNOT_HAVE_ONLY_SPACES',
    },
    EQUIPMENT_RFID_ALREADY_EXISTS: {
      value: '2045',
      text: 'EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_RFID_ALREADY_EXISTS',
    },
    EQUIPMENT_SERIAL_NO_ALREADY_EXISTS: {
      value: '2051',
      text: 'EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_SERIAL_NO_ALREADY_EXISTS',
    },
    SERIAL_NO_CANNOT_HAVE_ONLY_SPACES: {
      value: '2052',
      text: 'EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_SERIAL_NO_CANNOT_HAVE_ONLY_SPACES',
    },
    EQUIPMENT_HIERARCHY_TEST_TYPE_CANNOT_BE_CHANGE_TO_BA_CYLINDER: {
      value: '2046',
      text: 'EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_HIERARCHY_TEST_TYPE_CANNOT_BE_CHANGE_TO_BA_CYLINDER',
    },
    ADDRESS_BOOK_ENTRY_ALREADY_EXISTS: {
      value: '2047',
      text: 'EQUIPMENT_NOTIFICATION.STR_ADDRESS_BOOK_ENTRY_ALREADY_EXISTS',
    },
    MANDATORY_DATA_MISSING: {
      value: '12002',
      text: 'EQUIPMENT_NOTIFICATION.STR_MANDATORY_DATA_MISSING',
    },
    TASK_RESULT_NOT_FOUND: {
      value: '12003',
      text: 'EQUIPMENT_NOTIFICATION.STR_TASK_RESULT_NOT_FOUND',
    },
    ADD_EQUIPMENT_SUCCESS: {
      value: 'ADD_EQUIPMENT_SUCCESS',
      text: 'EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_SAVED_SUCCESS',
    },
    UPDATE_EQUIPMENT_SUCCESS: {
      value: 'UPDATE_EQUIPMENT_SUCCESS',
      text: 'EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_UPDATED_SUCCESS',
    },
    DELETE_EQUIPMENT_SUCCESS: {
      value: 'DELETE_EQUIPMENT_SUCCESS',
      text: 'EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_DELETED_SUCCESS',
    },
    TEST_EQUIPMENT_SUCCESS: {
      value: 'TEST_EQUIPMENT_SUCCESS',
      text: 'EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_TEST_SUCCESS',
    },
    SAVE_TEST_RESULTS_SUCCESS: {
      value: 'SAVE_TEST_RESULTS_SUCCESS',
      text: 'EQUIPMENT_NOTIFICATION.STR_SAVE_TEST_RESULTS_SUCCESS',
    },
    SAVE_TEST_RESULTS_FAILED: {
      value: 'SAVE_TEST_RESULTS_FAILED',
      text: 'EQUIPMENT_NOTIFICATION.STR_SAVE_TEST_RESULTS_FAILED',
    },
    TASK_UPDATE_NOT_POSSIBLE: {
      value: '12015',
      text: 'EQUIPMENT_NOTIFICATION.STR_TASK_UPDATE_NOT_POSSIBLE',
    },
    DURING_TESTING_ERROR: {
      value: 'DURING_TESTING_ERROR',
      text: 'EQUIPMENT_NOTIFICATION.STR_DURING_TESTING_ERROR',
    },
    TEST_EQUIPMENT_CANCELED: {
      value: 'TEST_EQUIPMENT_CANCELED',
      text: 'EQUIPMENT_NOTIFICATION.STR_TEST_EQUIPMENT_CANCELED',
    },
    EDIT_DELETED_EQUIPMENT: {
      value: 'EDIT_DELETED_EQUIPMENT',
      text: 'EQUIPMENT_NOTIFICATION.STR_EDIT_DELETED_EQUIPMENT',
    },
    ERROR_ADDING_SUPPLIER_MANUFACTURER: {
      value: 'ERROR_ADDING_SUPPLIER_MANUFACTURER',
      text: 'EQUIPMENT_NOTIFICATION.STR_MANUFACTURER_ERROR_SUPPLIER',
    },
    ERROR_ADDING_SUPPLIER_MANUFACTURER_CONTACT: {
      value: 'ERROR_ADDING_SUPPLIER_MANUFACTURER_CONTACT',
      text: 'EQUIPMENT_NOTIFICATION.STR_MANUFACTURER_ERROR_SUPPLIER_CONTACT',
    },
    SEARCHED_BARCODE_ALREADY_EXIST: {
      value: 'SEARCHED_BARCODE_ALREADY_EXIST',
      text: 'EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_SCAN_INFO_MSG',
    },
    SCANNED_LOCATION_NOT_HAVE_EQUIPMENT: {
      value: 'SCANNED_LOCATION_NOT_HAVE_EQUIPMENT',
      text: 'EQUIPMENT_NOTIFICATION.STR_SEARCHED_LOCATION_NOT_HAVE_EQUIPMENT',
    },
    SEARCHED_BARCODE_NOT_EXIST: {
      value: 'SEARCHED_BARCODE_NOT_EXIST',
      text: 'EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_SEARCHED_EQUIPMENT_NOT_EXIST',
    },
    SEARCHED_EQUIPMENT_NO_CHECKLIST: {
      value: 'SEARCHED_EQUIPMENT_NO_CHECKLIST',
      text: 'EQUIPMENT_NOTIFICATION.STR_SCANNED_EQUIPMENT_NO_CHECKLIST',
    },
    LOCATION_CHANGED_SUCCESSFULLY: {
      value: 'LOCATION_CHANGED_SUCCESSFULLY',
      text: 'EQUIPMENT_NOTIFICATION.STR_LOCATION_CHANGED_SUCCESSFULLY',
    },
    LOCATION_CHANGED_FAILED: {
      value: 'LOCATION_CHANGED_FAILED',
      text: 'EQUIPMENT_NOTIFICATION.STR_LOCATION_CHANGED_FAILED',
    },
    CYLINDER_CHARGING_SUCCESS: {
      value: 'CYLINDER_CHARGING_SUCCESS',
      text: 'EQUIPMENT_NOTIFICATION.STR_CYLINDER_CHARGING_SUCCESS',
    },
    EQUIPMENT_WITHOUT_PROTECTOR_TEST_TYPE: {
      value: 'EQUIPMENT_WITHOUT_PROTECTOR_TEST_TYPE',
      text: 'EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_WITHOUT_PROTECTOR_TEST_TYPE',
    },
    UPLOAD_EQUIPMENT_CSV_SUCCESS: {
      value: 'UPLOAD_EQUIPMENT_CSV_SUCCESS',
      text: 'EQUIPMENT_NOTIFICATION.STR_INVENTORY_UPLOAD_EQUIPMENT_CSV_SUCCESS',
    },
    LARGE_CSV_FILE: {
      value: '2026',
      text: 'EQUIPMENT_NOTIFICATION.STR_INVENTORY_LARGE_CSV_FILE',
    },
    CSV_FILE_FORMAT: {
      value: '2027',
      text: 'EQUIPMENT_NOTIFICATION.STR_INVENTORY_CSV_FILE_FORMAT',
    },
    EQUIPMENT_IMPORT_FAILED: {
      value: '2028',
      text: 'EQUIPMENT_NOTIFICATION.STR_INVENTORY_EQUIPMENT_IMPORT_FAILED',
    },
    MAXIMUM_NUMBER_OF_ASSETS_HAS_BEEN_REACHED: {
      value: '2041',
      text: 'EQUIPMENT_NOTIFICATION.STR_MAXIMUM_NUMBER_OF_ASSETS_HAS_BEEN_REACHED',
    },
    EQUIPMENT_TEST_IN_PROGRESS: {
      value: 'EQUIPMENT_TEST_IN_PROGRESS',
      text: 'EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_TEST_IN_PROGRESS',
    },
    MIGRATION_IN_PROGRESS: {
      value: 'MIGRATION_IN_PROGRESS',
      text: 'EQUIPMENT_NOTIFICATION.STR_MIGRATION_IN_PROGRESS',
    },
    EQUIPMENT_NOT_CYLINDER_TYPE: {
      value: 'EQUIPMENT_NOT_CYLINDER_TYPE',
      text: 'EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_NOT_CYLINDER_TYPE',
    },
    WRONG_RAM_CONNECTOR_TOKEN: {
      value: 'WRONG_RAM_CONNECTOR_TOKEN',
      text: 'EQUIPMENT_NOTIFICATION.STR_WRONG_RAM_CONNECTOR_TOKEN',
    },
    COMPLETE_CHECKLIST_SUCCESS: {
      value: 'COMPLETE_CHECKLIST_SUCCESS',
      text: 'EQUIPMENT_NOTIFICATION.STR_COMPLETE_CHECKLIST_SUCCESS',
    },
    SCANNED_LOCATION_IS_SAME_WITH_DIRECTLY_ASSIGNED_LOCATION: {
      value: 'SCANNED_LOCATION_IS_SAME_WITH_DIRECTLY_ASSIGNED_LOCATION',
      text: 'EQUIPMENT_NOTIFICATION.STR_SCANNED_LOCATION_IS_SAME_WITH_DIRECTLY_ASSIGNED_LOCATION',
    },
    EQUIPMENT_ADDITIONAL_DETAILS_DUPLICATE_ERROR: {
      value: '2058',
      text: '',
    },
  };
}
