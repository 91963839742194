import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { QRCodeModule } from 'angularx-qrcode';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { Table, TableModule, TableService } from 'primeng/table';
import { SimplebarAngularModule } from 'simplebar-angular';
import { ExportTableExcelComponent } from 'src/app/common';
import { LoggerService } from 'src/app/common/services/logger-monitor/logger.service';
import { CommonModule } from '../../common/modules/common.module';
import { ConfirmChangeTokenComponent } from '../components/create-update-device-connection/confirm-change-token/confirm-change-token.component';
import { CreateUpdateDeviceConnectionComponent } from '../components/create-update-device-connection/create-update-device-connection.component';
import { DeviceConnectionFormAssignmentComponent } from '../components/create-update-device-connection/device-connection-form/device-connection-form-assignment/device-connection-form-assignment.component';
import { DeviceConnectionFormComponent } from '../components/create-update-device-connection/device-connection-form/device-connection-form.component';
import { ProtectorSoftwareMigrationProgressBarComponent } from '../components/create-update-device-connection/protector-software-migration-progress-bar/protector-software-migration-progress-bar.component';
import { ProtectorMigrateErrorTableColumnComponent } from '../components/create-update-device-connection/protector-software-migration-tab/protector-migration-error-table/protector-migrate-error-table-column/protector-migrate-error-table-column.component';
import { ProtectorMigrationErrorTableComponent } from '../components/create-update-device-connection/protector-software-migration-tab/protector-migration-error-table/protector-migration-error-table.component';
import { ProtectorSoftwareMigrationTabComponent } from '../components/create-update-device-connection/protector-software-migration-tab/protector-software-migration-tab.component';
import { QrCodeModalComponent } from '../components/create-update-device-connection/qr-code-modal/qr-code-modal.component';
import { DeviceConnectionContainerComponent } from '../components/device-connection-container/device-connection-container.component';
import { DeviceConnectionListComponent } from '../components/device-connection-list/device-connection-list.component';
import { DeviceConnectionManagementComponent } from '../components/device-connection-management/device-connection-management.component';
import { DeviceConnectionModuleFooterComponent } from '../components/device-connection-module-footer/device-connection-module-footer.component';
import { DeviceConnectionService } from '../services/device-connection.service';
import { DeviceConnectionEffects } from '../state/effects/device-connection.effects';
import { deviceConnectionReducer } from '../state/reducers/device-connection.reducer';
import { DeviceConnectionManagementRoutingModule } from './device-connection-management-routing.module';

@NgModule({
  declarations: [
    DeviceConnectionManagementComponent,
    DeviceConnectionListComponent,
    CreateUpdateDeviceConnectionComponent,
    DeviceConnectionFormComponent,
    DeviceConnectionContainerComponent,
    ConfirmChangeTokenComponent,
    QrCodeModalComponent,
    DeviceConnectionModuleFooterComponent,
    DeviceConnectionFormAssignmentComponent,
    ProtectorSoftwareMigrationTabComponent,
    ProtectorMigrationErrorTableComponent,
    ProtectorSoftwareMigrationProgressBarComponent,
    ProtectorMigrateErrorTableColumnComponent,
  ],
  imports: [
    DeviceConnectionManagementRoutingModule,
    StoreModule.forFeature('deviceConnections', deviceConnectionReducer),
    EffectsModule.forFeature([DeviceConnectionEffects]),
    CommonModule,
    TableModule,
    MultiSelectModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    FormsModule,
    DropdownModule,
    InputTextModule,
    SimplebarAngularModule,
    QRCodeModule,
    ExportTableExcelComponent,
    NgSelectModule,
  ],
  providers: [DeviceConnectionService, LoggerService, Table, TableService, provideHttpClient(withInterceptorsFromDi())],
})
export class DeviceConnectionManagementModule {}
