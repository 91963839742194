import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CommonConstants } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { IIncident, IMediaAssets } from 'src/app/report-center/models/incident.model';
import { IncidentActions } from 'src/app/report-center/state/actions/incident.actions';
import { selectIncidentMediaAssets } from 'src/app/report-center/state/selectors/incident.selector';
import { environment } from 'src/environments/environment';
import { ReportCenterConstants } from '../../../constants/report-center.constants';

@Component({
  selector: 'ignis-media-assets',
  templateUrl: './media-assets.component.html',
  styleUrls: ['./media-assets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaAssetsComponent extends OnDestroyMixin() implements OnChanges {
  @Input() formatDate: string;
  @Input() selectedIncident: IIncident;
  @Input() incidentForm: FormGroup;

  notes: IMediaAssets[];
  media: IMediaAssets[];
  mediaAssets: IMediaAssets[];
  isLoading: boolean = false;

  mediaAssetsAreGot: boolean = false;
  version: number;

  constructor(
    private incidentActions: IncidentActions,
    private store: Store<IApplicationState>,
    private cdr: ChangeDetectorRef,
  ) {
    super();

    this.mediaAssets = [];
  }

  ngOnChanges(): void {
    /* istanbul ignore else */
    if (this.selectedIncident && !this.mediaAssetsAreGot) {
      this.getMediaAssets(this.selectedIncident.aggregateId);

      this.store
        .pipe(
          select(selectIncidentMediaAssets),
          map((state: IStoreApiItem<IMediaAssets[]>) => state.data),
          takeUntil(this.destroy),
        )
        .subscribe((mediaAssets: IMediaAssets[]) => {
          this.notes = [];
          this.media = [];
          this.mediaAssets = [];

          this.checkIfMediaTypeIsDone(mediaAssets);
          this.cdr.detectChanges();
        });
    }
  }

  checkIfMediaTypeIsDone(mediaAssets: IMediaAssets[]): void {
    this.composeMediaAssetUrlAndCheckType(mediaAssets).subscribe((assets: IMediaAssets[]) => {
      if (assets.length > 0) {
        this.checkMediaType();
      } else {
        this.isLoading = false;
      }

      this.cdr.detectChanges();
    });
  }

  checkMediaType(): void {
    const allowedAudioTypes: string[] = CommonConstants.allowedAudioTypes;

    this.mediaAssets.some((media: IMediaAssets) => {
      if (media?.mediaLink === null) {
        this.notes = [...this.notes, media];
        this.isLoading = false;

        return;
      }

      const mediaUrl: string[] = media.mediaLink.split('.');
      const fileExtension: string = mediaUrl.pop();

      if (allowedAudioTypes.includes(fileExtension)) {
        this.notes = [...this.notes, media];

        return;
      }

      this.media = [...this.media, media];

      if (media.text?.length) {
        this.notes = [...this.notes, { ...media, mediaLink: null }];
      }

      this.isLoading = false;
    });

    this.version = this.mediaAssets[0]?.version;
    localStorage.setItem(ReportCenterConstants.mediaAssetsVersion, this.version?.toString());
  }

  composeMediaAssetUrlAndCheckType(mediaAssets: IMediaAssets[]): Observable<IMediaAssets[]> {
    this.isLoading = true;
    const mutableMediaAssets: IMediaAssets[] = structuredClone(mediaAssets);

    return new Observable((observer) => {
      if (mutableMediaAssets) {
        mutableMediaAssets.forEach((asset: IMediaAssets) => {
          this.processMediaLink(asset);
        });

        observer.next(this.mediaAssets);
        observer.complete();
      }
    });
  }

  processMediaLink(asset: IMediaAssets): void {
    const deviceConnectionBaseUrl: string = environment.API_URLS.DEVICE_CONNECTION;

    if (asset.mediaLink) {
      if (!asset.mediaLink.includes('https://')) {
        asset.mediaLink = `${deviceConnectionBaseUrl}/files/${asset.mediaLink}`;
        this.mediaAssets.push(asset);
      } else {
        const regex: RegExp = CommonConstants.regexToExtractFileWithExtension;
        const match: unknown = asset.mediaLink.match(regex);
        const fileName: string = match[1];

        asset.mediaLink = `${deviceConnectionBaseUrl}/files/${fileName}`;
        this.mediaAssets.push(asset);
      }
    } else {
      this.mediaAssets.push(asset);
    }
  }

  getMediaAssets(incidentId: string): void {
    this.incidentActions.requestIncidentMediaAssets(incidentId);
    this.mediaAssetsAreGot = true;
  }
}
