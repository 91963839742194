import { INotificationConstant } from '../models/notifications.model';

export class NotificationConstants {
  public static readonly commonCodes: INotificationConstant = {
    UNEXPECTED_ERROR: {
      value: '10001',
      text: 'COMMON_NOTIFICATION.STR_UNEXPECTED_ERROR',
    },
    IDENTIFIER_SHOULD_BE_UNIQUE: {
      value: '10002',
      text: 'COMMON_NOTIFICATION.STR_IDENTIFIER_SHOULD_BE_UNIQUE',
    },
    MANDATORY_IDENTIFIER: {
      value: '10003',
      text: 'COMMON_NOTIFICATION.STR_IDENTIFIER_MANDATORY',
    },
    GDPR_NOT_ACCEPTED: {
      value: '8009',
      text: 'COMMON_NOTIFICATION.STR_GDPR_NOT_ACCEPTED',
    },
    TABLE_COLUMN_SETTINGS_ERROR: {
      value: '8010',
      text: 'COMMON_NOTIFICATION.STR_TABLE_COLUMN_SETTINGS_ERROR',
    },
    TAC_NOT_ACCEPTED: {
      value: '8012',
      text: 'COMMON_NOTIFICATION.STR_TAC_NOT_ACCEPTED',
    },
    DOCUMENT_VERSION_OUTDATED: {
      value: '8018',
      text: 'COMMON_NOTIFICATION.STR_DOCUMENT_VERSION_OUTDATED',
    },
    DPA_NOT_ACCEPTED: {
      value: '8034',
      text: 'COMMON_NOTIFICATION.STR_DPA_NOT_ACCEPTED',
    },
    ADD_NEW_NOTE: {
      value: 'ADD_NEW_NOTE',
      text: 'COMMON_NOTIFICATION.STR_ADD_NEW_NOTE',
    },
    UPLOAD_FILE_SUCCESS: {
      value: 'UPLOAD_FILE_SUCCESS',
      text: 'COMMON_NOTIFICATION.STR_UPLOAD_FILE_SUCCESS',
    },
    UPLOAD_FILE_FAILED: {
      value: 'UPLOAD_FILE_FAILED',
      text: 'COMMON_NOTIFICATION.STR_UPLOAD_FILE_FAILED',
    },
    UPLOAD_LARGE_FILE_FAILED: {
      value: 'UPLOAD_LARGE_FILE_FAILED',
      text: 'COMMON_NOTIFICATION.STR_UPLOAD_LARGE_FILE_FAILED',
    },
    WRONG_FILE_TYPE_UPLOAD_FAILED: {
      value: 'WRONG_FILE_TYPE_UPLOAD_FAILED',
      text: 'COMMON_NOTIFICATION.STR_WRONG_FILE_TYPE_UPLOAD_FAILED',
    },
    DELETE_FILE_SUCCESS: {
      value: 'DELETE_FILE_SUCCESS',
      text: 'COMMON_NOTIFICATION.STR_DELETE_FILE_SUCCESS',
    },
    DELETE_FILE_FAILED: {
      value: 'DELETE_FILE_FAILED',
      text: 'COMMON_NOTIFICATION.STR_DELETE_FILE_FAILED',
    },
    ADD_MEDIA_ASSET_SUCCESS: {
      value: 'ADD_MEDIA_ASSET_SUCCESS',
      text: 'COMMON_NOTIFICATION.STR_ADD_MEDIA_ASSET_SUCCESS',
    },
    ADD_MEDIA_ASSET_FAILED: {
      value: 'ADD_MEDIA_ASSET_FAILED',
      text: 'COMMON_NOTIFICATION.STR_ADD_MEDIA_ASSET_FAILED',
    },
    MAP_ERROR: {
      value: 'MAP_ERROR',
      text: 'COMMON_NOTIFICATION.STR_MAP_ERROR',
    },
    MAINTENANCE_MODE: {
      value: 'MAINTENANCE_MODE',
      text: 'COMMON_NOTIFICATION.STR_MAINTENANCE_DATE',
    },
    EXCEL_EXPORT_ERROR: {
      value: 'EXCEL_EXPORT_ERROR',
      text: 'COMMON_NOTIFICATION.STR_EXCEL_EXPORT_ERROR',
    },
  };

  public static readonly globalCodes: INotificationConstant = {
    URL_TOO_LONG: {
      value: 'URL_TOO_LONG',
      text: 'GLOBAL_NOTIFICATION.STR_URL_TOO_LONG',
    },
    ERROR_ON_THE_UI: {
      value: 'ERROR_ON_THE_UI',
      text: 'GLOBAL_NOTIFICATION.STR_ERROR_ON_THE_UI',
    },
    BACKEND_NOT_AVAILABLE: {
      value: 'BACKEND_NOT_AVAILABLE',
      text: 'GLOBAL_NOTIFICATION.STR_BACKEND_NOT_AVAILABLE',
    },
    GENERIC_SERVER_ERROR: {
      value: 'GENERIC_SERVER_ERROR',
      text: 'GLOBAL_NOTIFICATION.STR_GENERIC_SERVER_ERROR',
    },
    INTERNET_CONNECTION_IS_DOWN: {
      value: 'INTERNET_CONNECTION_IS_DOWN',
      text: 'GLOBAL_NOTIFICATION.STR_INTERNET_CONNECTION_IS_DOWN',
    },
    INTERNET_CONNECTION_IS_UP: {
      value: 'INTERNET_CONNECTION_IS_UP',
      text: 'GLOBAL_NOTIFICATION.STR_INTERNET_CONNECTION_IS_UP',
    },
  };
}
