<p
  #columnText
  class="table-ellipsis-data"
  [pTooltip]="!disableTooltip ? null : columnData.data"
  tooltipPosition="top"
  positionTop="-10"
  (mouseover)="calculateToDisableTooltip()"
  data-test-id="table.ellipsis"
>
  {{ columnData.data }}
</p>
