import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GenericFooterItemsComponent } from '../../../../common';

@Component({
  selector: 'ignis-equipment-hierarchy-view-footer',
  templateUrl: './equipment-hierarchy-view-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentHierarchyViewFooterComponent<T> extends GenericFooterItemsComponent<T> {
  @Input() disableAddBtn: boolean;
  @Input() isModalOpened: boolean;
}
