<ignis-progress-bar *ngIf="isLoading"></ignis-progress-bar>
<div class="row media-section">
  <div class="col-6 media-gallery-section">
    <h1 class="tab-menu-header">
      {{ 'INCIDENT_MEDIA_ASSETS.STR_MEDIA_GALLERY' | translate }}
    </h1>
    <ignis-media-gallery [media]="media" [formatDate]="formatDate"></ignis-media-gallery>
  </div>
  <div class="vertical-line"></div>
  <div class="col-6">
    <h1 class="tab-menu-header">
      {{ 'INCIDENT_MEDIA_ASSETS.STR_NOTES' | translate }}
    </h1>
    <ignis-media-notes
      [notes]="notes"
      [version]="version"
      [formatDate]="formatDate"
      [selectedIncident]="selectedIncident"
      [incidentForm]="incidentForm"
      (getMediaAssets)="getMediaAssets($event)"
    ></ignis-media-notes>
  </div>
</div>
