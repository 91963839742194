import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, filter, map, Observable, takeUntil } from 'rxjs';
import { CommonConstants, IApplicationState, IStoreApiItem, IStoreApiList } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { PropertyBag } from 'src/app/common/models/common.model';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { ConfigurationNotificationConstants } from '../../constants';
import { ConfigurationConstants } from '../../constants/configuration.constants';
import { IEquipmentHierarchy } from '../../models/equipment-configuration.models';
import { EquipmentHierarchyActions, TasksActions } from '../../state/actions';

import {
  selectDeletedEquipmentHierarchy,
  selectEquipmentHierarchy,
  selectImportEquipmentHierarchy,
} from './../../state/selectors/configuration.selectors';

@Component({
  selector: 'ignis-equipment-hierarchy',
  templateUrl: './equipment-hierarchy.component.html',
  styleUrls: ['./equipment-hierarchy.component.scss'],
})
export class EquipmentHierarchyComponent extends OnDestroyMixin() implements OnInit {
  hierarchyData: IEquipmentHierarchy[] | any;
  isLoading: Observable<boolean>;
  selectedTreeNode: any;
  disableAddBtn: boolean = false;
  disableEditBtnAndDelete: boolean = true;
  openConfirmationDeleteDialog: boolean = false;
  openImportEQHierarchyModal: boolean = false;
  activateClickOutside: boolean = true;
  deletedType: string;
  equipmentConfigType: string;
  configType: PropertyBag = ConfigurationConstants.EquipmentConfigType;
  deleteUrl: string;
  errors: any;

  store: Store<IApplicationState> = inject(Store<IApplicationState>);
  tasksActions: TasksActions = inject(TasksActions);
  equipmentHierarchyActions: EquipmentHierarchyActions = inject(EquipmentHierarchyActions);
  router: Router = inject(Router);
  translateService: TranslateService = inject(TranslateService);
  notificationsService: NotificationsService = inject(NotificationsService);

  constructor() {
    super();
    this.hierarchyData = [
      {
        label: this.translateService.instant('CONFIGURATION.STR_EQUIPMENT_HIERARCHY'),
        data: null,
        children: [],
      },
    ];
  }

  ngOnInit(): void {
    this.equipmentHierarchyActions.requestEquipmentHierarchy();

    this.isLoading = combineLatest([
      this.store.select(selectEquipmentHierarchy),
      this.store.select(selectImportEquipmentHierarchy),
    ]).pipe(
      takeUntil(this.destroy),
      map(
        ([hierarchyState, importHierarchyState]: [IStoreApiList<IEquipmentHierarchy[]>, IStoreApiItem<unknown>]) =>
          hierarchyState.isLoading || importHierarchyState.isLoading,
      ),
    );

    this.store
      .pipe(
        select(selectEquipmentHierarchy),
        filter((state: IStoreApiList<IEquipmentHierarchy[]>) => !state.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((response: IStoreApiList<IEquipmentHierarchy[]>) => {
        if (response.data) {
          this.hierarchyData[0].children = structuredClone(response.data);

          this.hierarchyData.filter((parentNode: any) => {
            parentNode.children.some((childNode: any) => {
              if (
                ConfigurationConstants.categoryId in localStorage &&
                childNode.data.aggregateId === localStorage.getItem(ConfigurationConstants.categoryId)
              ) {
                childNode.expanded = true;
              }

              childNode.children.some((nephewNode: any) => {
                if (
                  ConfigurationConstants.typeId in localStorage &&
                  nephewNode.data.typeId === localStorage.getItem(ConfigurationConstants.typeId)
                ) {
                  nephewNode.expanded = true;
                }
              });
            });
          });

          localStorage.removeItem(ConfigurationConstants.categoryId);
          localStorage.removeItem(ConfigurationConstants.typeId);
          localStorage.removeItem(ConfigurationConstants.modelId);
          localStorage.removeItem(ConfigurationConstants.configurationVersion);

          if (this.hierarchyData[0].children.length > 0) {
            this.hierarchyData[0].expanded = true;
            this.disableAddBtn = true;
          } else {
            this.selectedTreeNode = this.hierarchyData[0];
            this.disableAddBtn = false;
          }
        }
      });
  }

  nodeSelect(event: any): void {
    this.selectedTreeNode = event.node;
    this.disableAddBtn = false;
    this.disableEditBtnAndDelete = false;

    localStorage.removeItem('isCylinderType');

    if (!this.selectedTreeNode.data) {
      this.disableEditBtnAndDelete = true;
    }

    if (this.selectedTreeNode?.parent?.parent?.parent?.data === null && this.selectedTreeNode.children.length < 1) {
      this.disableAddBtn = true;
    }

    if (!this.selectedTreeNode.parent?.data) {
      this.deletedType = this.translateService.instant('CONFIGURATION.STR_CATEGORY');
      this.equipmentConfigType = ConfigurationConstants.EquipmentConfigType.CATEGORY;
      this.deleteUrl = `categories/${this.selectedTreeNode.data?.aggregateId}`;
    } else if (!this.selectedTreeNode.parent?.parent.data) {
      localStorage.setItem('isCylinderType', this.selectedTreeNode.data.isCylinderType);
      this.deletedType = this.translateService.instant('CONFIGURATION.STR_TYPE');
      this.equipmentConfigType = ConfigurationConstants.EquipmentConfigType.TYPE;
      this.deleteUrl = `categories/${this.selectedTreeNode.parent.data.aggregateId}/types/${this.selectedTreeNode.data.typeId}`;
    } else if (!this.selectedTreeNode.parent?.parent.parent.data) {
      this.deletedType = this.translateService.instant('CONFIGURATION.STR_MODEL');
      this.equipmentConfigType = ConfigurationConstants.EquipmentConfigType.MODEL;
      this.deleteUrl = `categories/${this.selectedTreeNode.parent.parent.data.aggregateId}/types/${this.selectedTreeNode.parent.data.typeId}/models/${this.selectedTreeNode.data.modelId}`;
    }
  }

  removeSelectedNode(): void {
    if (this.activateClickOutside) {
      this.selectedTreeNode = null;
      this.disableAddBtn = true;
    }
  }

  createNewConfiguration(): void {
    if (!this.selectedTreeNode?.data) {
      this.router.navigate(['configuration', 'create']);
    } else if (!this.selectedTreeNode.parent?.data) {
      this.router.navigate(['configuration', 'create', this.selectedTreeNode.data.aggregateId, 'types']);
    } else if (!this.selectedTreeNode.parent?.parent?.data) {
      this.router.navigate([
        'configuration',
        'create',
        this.selectedTreeNode.parent.data.aggregateId,
        'types',
        this.selectedTreeNode.data.typeId,
        'models',
      ]);
    }
  }

  editConfiguration(): void {
    if (!this.selectedTreeNode.parent?.data) {
      this.router.navigate(['configuration', 'update', 'categories', this.selectedTreeNode.data.aggregateId]);
    } else if (!this.selectedTreeNode.parent?.parent?.data) {
      this.router.navigate([
        'configuration',
        'update',
        'categories',
        this.selectedTreeNode.parent.data.aggregateId,
        'types',
        this.selectedTreeNode.data.typeId,
      ]);
    } else if (!this.selectedTreeNode.parent?.parent?.parent?.data) {
      this.router.navigate([
        'configuration',
        'update',
        'categories',
        this.selectedTreeNode.parent.parent.data.aggregateId,
        'types',
        this.selectedTreeNode.parent.data.typeId,
        'models',
        this.selectedTreeNode.data.modelId,
      ]);
    }
  }

  openDeleteEquipmentHierarchyDialog(): void {
    this.openConfirmationDeleteDialog = true;
    this.activateClickOutside = false;
  }

  closeDeleteEquipmentHierarchyDialog(confirmation: boolean): void {
    if (confirmation) {
      this.deleteConfiguration();
    } else {
      this.openConfirmationDeleteDialog = false;
      this.activateClickOutside = true;
    }
  }

  deleteConfiguration(): void {
    this.equipmentHierarchyActions.requestDeleteEquipmentHierarchy({
      urlPath: this.deleteUrl,
      version: this.selectedTreeNode.data.version,
    });

    this.store
      .pipe(
        select(selectDeletedEquipmentHierarchy),
        filter((state: IStoreApiItem<any>) => !state.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((response: IStoreApiItem<any>) => {
        if (response.isSuccess) {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            ConfigurationNotificationConstants.notificationCodes.DELETE_HIERARCHY_SUCCESS,
            ConfigurationNotificationConstants.notificationCodes,
            {
              type: this.deletedType,
            },
          );

          this.ngOnInit();
        } else {
          this.errors = response.errors.error?.code.toString();

          if (this.errors) {
            this.notificationsService.requestShowNotification(
              CommonConstants.notificationType.ERROR,
              this.errors,
              ConfigurationNotificationConstants.notificationCodes,
            );
          }
        }
      });
  }

  openImportEquipmentHierarchyModal(): void {
    this.openImportEQHierarchyModal = true;
  }

  closeImportEquipmentHierarchyModal(event: boolean): void {
    this.openImportEQHierarchyModal = event;
  }
}
