import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnChanges, Output } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CheckingSettingsModalsService, GenericFooterItemsComponent, PropertyBag } from 'src/app/common';
import { AppUserPermissionList } from 'src/app/root/models/app-types';
import { AccessControlService } from 'src/app/root/services/access-control/access-control.service';

@Component({
  selector: 'ignis-inventory-view-footer',
  templateUrl: './inventory-view-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryViewFooterComponent<T> extends GenericFooterItemsComponent<T> implements OnChanges {
  @Input() disableStartTestBtn: boolean;
  @Input() numberOfRecordsToBeExported: number;
  @Input() isModalOpened: boolean;
  @Input() isSubmitDisabled: boolean = false;

  @Output() handleNavigateToEquipment: EventEmitter<void> = new EventEmitter<void>();
  @Output() handleHistory: EventEmitter<void> = new EventEmitter<void>();
  @Output() handleDownloadTemplate: EventEmitter<void> = new EventEmitter<void>();
  @Output() handleUploadCSVFile: EventEmitter<void> = new EventEmitter<void>();
  @Output() handleTest: EventEmitter<void> = new EventEmitter<void>();
  @Output() handleExcelExport: EventEmitter<void> = new EventEmitter<void>();

  appUserPermissionList: PropertyBag = AppUserPermissionList;
  permissions: string[] = [];

  jwtHelper: JwtHelperService = new JwtHelperService();

  public accessControlService: AccessControlService = inject(AccessControlService);
  private checkSettingsModal: CheckingSettingsModalsService = inject(CheckingSettingsModalsService);

  constructor() {
    super();

    this.checkSettingsModal.checkIfSettingsModalIsOpen().subscribe((isModalOpened: boolean) => {
      this.isModalOpened = isModalOpened;
      this.cdr.detectChanges();
    });
  }

  ngOnChanges(): void {
    this.permissions = this.accessControlService.permissions;
  }

  onNavigateToEquipment(e: Event): void {
    e.stopPropagation();
    this.handleNavigateToEquipment.emit();
  }

  onTestClick(e: Event): void {
    e.stopPropagation();
    this.handleTest.emit();
  }

  onHistoryClick(e: Event): void {
    e.stopPropagation();
    this.handleHistory.emit();
  }

  onDownloadTemplateClick(e: Event): void {
    e.stopPropagation();
    this.handleDownloadTemplate.emit();
  }

  onUploadCSVFileClick(e: Event): void {
    e.stopPropagation();
    this.handleUploadCSVFile.emit();
  }

  onExportExcelClick(e: Event): void {
    e.stopPropagation();
    this.handleExcelExport.emit();
  }
}
