<ignis-progress-bar *ngIf="(isSubmitting | async) || isLoading"></ignis-progress-bar>
<ngx-simplebar [options]="scrollbarOptions" class="custom-org-settings-scrollbar">
  <form odxForm [formGroup]="organizationSettingsForm" *ngIf="!isLoading">
    <p-accordion class="w-full" [multiple]="true">
      <p-accordionTab [selected]="true" data-test-id="organization_settings.organization_settings">
        <ng-template pTemplate="header">
          <div class="flex align-items-center">
            <span class="vertical-align-middle">
              {{ 'ORGANIZATION_SETTINGS.STR_ORGANIZATION_SETTINGS' | translate }}
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <ignis-organization-settings-section
            [organizationSettingsForm]="organizationSettingsForm"
            [languagesOptions]="languagesOptions"
            [dateTimeFormatOptions]="dateTimeFormatOptions"
          ></ignis-organization-settings-section>
        </ng-template>
      </p-accordionTab>
      <p-accordionTab [hidden]="!firegroundAccess" data-test-id="organization_settings.fireground_settings">
        <ng-template pTemplate="header">
          <div class="flex align-items-center">
            <span class="vertical-align-middle">
              {{ 'ORGANIZATION_SETTINGS.STR_FIREGROUND_SETTINGS' | translate }}
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <p class="m-0">
            <ignis-fireground-settings-section
              [organizationSettingsForm]="organizationSettingsForm"
              [profilesOptions]="profilesOptions"
              [defaultTimeOptions]="defaultTimeOptions"
              [pressureOptions]="pressureOptions"
              [cylinderPressureOptions]="cylinderPressureOptions"
              (handleProfileChanging)="profileChange($event)"
            ></ignis-fireground-settings-section></p
        ></ng-template>
      </p-accordionTab>
      <p-accordionTab [hidden]="!equipmentModuleActive" data-test-id="organization_settings.ram_settings">
        <ng-template pTemplate="header">
          <div class="flex align-items-center">
            <span class="vertical-align-middle">
              {{ 'ORGANIZATION_SETTINGS.STR_RAM_SETTINGS' | translate }}
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <ignis-ram-settings-section
            [organizationSettingsForm]="organizationSettingsForm"
            [hasAutomaticTestsAccess]="checkIfUserHaveEquipmentAutomaticTestActive()"
          ></ignis-ram-settings-section>
        </ng-template>
      </p-accordionTab>
    </p-accordion>
  </form>
</ngx-simplebar>

<footer class="blue-bar">
  <ignis-organization-settings-footer-view
    [isSubmitDisabled]="isSubmitting | async"
    [isModalOpened]="isConfirmCloseModalOpen"
    [form]="organizationSettingsForm"
    (handleSubmit)="onSubmit()"
  ></ignis-organization-settings-footer-view>
</footer>
<ignis-confirmation-modal
  [isOpen]="isConfirmCloseModalOpen"
  (handleConfirmationModal)="navigateBack($event)"
  class="is-closed-modal"
>
  <div slot="content">
    {{ 'CONFIRM_CLOSE_MODAL.STR_DESCRIPTION_ORGANIZATION_SETTINGS' | translate }}
  </div>
</ignis-confirmation-modal>
