import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  inject,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CommonConstants, NotificationsService } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { convertMillisecondsToMinutes, dateFormat } from 'src/app/common/utils/date-utils/date.utils';
import { getPressureUnit } from 'src/app/common/utils/settings-utils/settings-utils';
import { setHazmatValue } from 'src/app/report-center/helpers/incident-helper';
import {
  Alarm,
  AlarmChartData,
  AlarmChartDurations,
  AlarmChartEntry,
  IAlarm,
  IBaSet,
  IEcp,
  IEcpChart,
  IEventMarker,
  IEventMarkerClusters,
  IIncidentAnalysis,
  IMediaAsset,
} from 'src/app/report-center/models/incident.model';
import { IncidentActions } from 'src/app/report-center/state/actions/incident.actions';
import { selectIncidentAnalysis } from 'src/app/report-center/state/selectors/incident.selector';
import { ReportCenterConstants } from '../../../constants/report-center.constants';

@Component({
  selector: 'ignis-incident-analysis',
  templateUrl: './incident-analysis.component.html',
  styleUrls: ['./incident-analysis.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IncidentAnalysisComponent extends OnDestroyMixin() implements OnInit, AfterViewInit {
  @Input() formatDate: string;
  @Input() incidentForm: FormGroup;
  @Input() hazmatIncidentType: string;
  @Input() activateClickOutside: boolean;

  @Output() getManualEventId: EventEmitter<string> = new EventEmitter();
  @Output() resetFormErrors: EventEmitter<void> = new EventEmitter();
  isLoading: boolean = false;
  incidentAnalysis: IIncidentAnalysis;
  ecpTimelineIsOpen: boolean[] = [];
  baSetIsOpen: any = { isOpen: [] };

  @ViewChild('timelineList') timelineList: ElementRef;
  incidentTimelineTotalAlarms: number = 0;
  incidentTimelineVisibleWidth: number;
  incidentTimelineLeftContentWidth: number = 230;
  incidentTimelineMinuteToPixel: number;
  incidentTimelineWidth: number;
  incidentTimelineLastEcpAlarmCount: number;

  @ViewChild('ecpsList') ecpsList: ElementRef;
  ecpTimelineTotalAlarms: number = 0;
  ecpTimelineVisibleWidth: number;
  ecpTimelineLeftContentWidth: number = 230;
  ecpTimelineMinuteToPixel: number;
  ecpTimelineWidth: number;
  showEcpShadows: boolean = true;

  @ViewChild('baSetItem') baSetItem: ElementRef;
  baSetTimelineTotalAlarms: number = 0;
  baSetTimelineVisibleWidth: number;
  baSetTimelineLeftContentWidth: number = 230;
  accordionHeader: number = 58;
  accordionPadding: number = 30;
  alarmRowHeight: number = 230;
  baSetTimelineMinuteToPixel: number;
  baSetTimelineWidth: number;
  showBaSetShadows: boolean = true;
  showTimelineScrollBar: boolean = false;
  displayHorizontalGap: boolean = false;
  incidentAggregateId: string;

  dateFormat: any = dateFormat;

  alarmIds: string[];

  pressureDisplayUnit: Observable<string>;

  ecpTimelineOffset: number = 240;
  timelinesDashLineOffset: number = 50;
  ecpTimelinesDashLineOffset: number = 50;

  scrollbarOptions: any = CommonConstants.scrollbarOptions;
  currentAppTheme: string;

  isIncidentTypeHazmat: boolean;

  @ViewChildren('timelineWrapper') timelineWrapper: QueryList<ElementRef>;

  notificationsService: NotificationsService = inject(NotificationsService);

  constructor(
    private incidentActions: IncidentActions,
    private store: Store<IApplicationState>,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
  ) {
    super();

    this.alarmIds = [];

    /* istanbul ignore next */
    window.addEventListener('resize', () => {
      this.ngOnInit();
      this.ngAfterViewInit();
    });

    this.currentAppTheme = this.document.body.className.split(' ')[1];
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.displayHorizontalGap = false;
    this.getIncidentAnalysisData();
    this.resetFormErrors.emit();
    this.pressureDisplayUnit = getPressureUnit(this.store, this.destroy);
    this.readIncidentAnalysisDataFromStore();

    this.isIncidentTypeHazmat =
      this.incidentForm?.get('incidentData.incidentType').value === this.hazmatIncidentType ||
      this.incidentForm?.get('incidentData.incidentSpecialExposure').value === this.hazmatIncidentType;
  }

  /* istanbul ignore next */
  ngAfterViewInit(): void {
    this.incidentTimelineVisibleWidth = this.timelineList?.nativeElement.offsetWidth;
    this.ecpTimelineVisibleWidth = this.incidentTimelineVisibleWidth;
    this.baSetTimelineVisibleWidth = this.incidentTimelineVisibleWidth;

    setTimeout(() => {
      this.displayHorizontalGap = true;
    }, 250);
  }

  get firefighterHazmatFlags(): FormArray {
    return this.incidentForm?.get('firefighterHazmatFlags') as FormArray;
  }

  /* istanbul ignore next */
  getIncidentAnalysisData(): void {
    this.ecpTimelineIsOpen = [];
    this.route.params.pipe(takeUntil(this.destroy)).subscribe((params: Params) => {
      this.incidentActions.requestIncidentAnalysis(params.id);
      this.incidentAggregateId = params.id;
      this.cdr.detectChanges();
    });
  }

  readIncidentAnalysisDataFromStore(): void {
    this.store
      .pipe(
        select(selectIncidentAnalysis),
        filter((state: IStoreApiItem<IIncidentAnalysis>) => !state.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((incidentAnalysis: IStoreApiItem<IIncidentAnalysis>) => {
        if (incidentAnalysis.data && this.incidentAggregateId) {
          this.incidentAnalysis = incidentAnalysis.data;
          this.incidentAnalysis?.ecpTimelines.ecps.forEach(() => this.ecpTimelineIsOpen.push(true));
          localStorage.setItem(
            ReportCenterConstants.currentIncidentAnalysis,
            JSON.stringify({
              aggregateId: this.incidentAggregateId,
              ecp: {
                name: this.incidentAnalysis.incidentTimeline.ecps[0]?.name,
                telemetryAddress: this.incidentAnalysis.incidentTimeline.ecps[0]?.appId,
              },
              ecps: this.incidentAnalysis.incidentTimeline.ecps,
              startTime: this.incidentAnalysis.startTime,
              endTime: this.incidentAnalysis.endTime,
            }),
          );

          this.firefighterHazmatFlags?.clear();

          this.incidentAnalysis.ecpTimelines.ecps.forEach((ecps) => {
            ecps.baSets.forEach((baSets) => {
              const hazmat = {
                isHazmat: setHazmatValue(baSets.hazmat, this.incidentForm, this.hazmatIncidentType),
                appId: ecps.appId,
                equipmentSerialNumber: baSets.equipmentSerialNumber,
                firefighterId: baSets.firefighterId,
              };

              this.firefighterHazmatFlags?.push(this.fb.group(hazmat));
            });
          });

          this.calculateIncidentTimelineWidth(this.incidentAnalysis.endTime, this.incidentAnalysis.startTime);

          this.calculateIncidentTimelineAlarmCount(this.incidentAnalysis);
          this.calculateEcpTimelineAlarmCount(this.incidentAnalysis);
          this.isLoading = false;
        }

        this.cdr.detectChanges();
      });
  }

  calculateIncidentTimelineAlarmCount(incidentAnalysis: IIncidentAnalysis): void {
    const ecpList: IEcp[] = incidentAnalysis.incidentTimeline.ecps;
    const lastEcp: IEcp = ecpList[ecpList.length - 1];

    incidentAnalysis.incidentTimeline.ecps.forEach((ecp: IEcp) => {
      this.incidentTimelineTotalAlarms += ecp.alarms.length;
      this.incidentTimelineTotalAlarms += ecp.eventMarkerClusters.length;
    });

    this.incidentTimelineLastEcpAlarmCount = lastEcp.alarms.length;
  }

  calculateEcpTimelineAlarmCount(incidentAnalysis: IIncidentAnalysis): void {
    incidentAnalysis?.ecpTimelines?.ecps?.forEach((ecp: IEcpChart) => {
      ecp.baSets.forEach((baSet: IBaSet) => {
        this.ecpTimelineTotalAlarms += baSet.ecpAlarmRow?.length;
      });
    });
  }

  calculateIncidentTimelineWidth(endTime: string, startTime: string): void {
    this.incidentTimelineMinuteToPixel =
      (this.incidentTimelineVisibleWidth - this.incidentTimelineLeftContentWidth) / 60;
    this.incidentTimelineWidth =
      ((new Date(endTime).getTime() - new Date(startTime).getTime()) / 1000 / 60) * this.incidentTimelineMinuteToPixel;
  }

  calculateEcpTimelineWidth(endTime: string, startTime: string): void {
    this.ecpTimelineMinuteToPixel = (this.ecpTimelineVisibleWidth - this.ecpTimelineLeftContentWidth) / 60;
    this.ecpTimelineWidth =
      ((new Date(endTime).getTime() - new Date(startTime).getTime()) / 1000 / 60) * this.ecpTimelineMinuteToPixel;
  }

  calculateBaSetTimelineWidth(endTime: string, startTime: string): void {
    this.baSetTimelineMinuteToPixel = (this.baSetTimelineVisibleWidth - this.baSetTimelineLeftContentWidth) / 60;
    this.baSetTimelineWidth =
      ((new Date(endTime).getTime() - new Date(startTime).getTime()) / 1000 / 60) * this.baSetTimelineMinuteToPixel;
  }

  /* istanbul ignore next */
  getIncidentListWidthDependingOnEventLength(): number {
    const moreThanHourValue: number = this.incidentTimelineWidth + this.incidentTimelineLeftContentWidth + 20;
    const lessThanOneHourValue: number = this.incidentTimelineVisibleWidth;

    if (Math.max(moreThanHourValue, lessThanOneHourValue) === lessThanOneHourValue) {
      this.timelinesDashLineOffset = 30;
      this.document.getElementById('incident-timeline-right-shadow').style.visibility = 'hidden';
    }

    return Math.max(moreThanHourValue, lessThanOneHourValue);
  }

  /* istanbul ignore next */
  getIncidentTimelineWidthDependingOnEventLength(): number {
    const moreThanHourValue: number = this.incidentTimelineWidth;
    const lessThanOneHourValue: number = this.incidentTimelineVisibleWidth - this.incidentTimelineLeftContentWidth;

    return Math.max(moreThanHourValue, lessThanOneHourValue);
  }

  /* istanbul ignore next */
  getEcpListWidthDependingOnEventLength(endTime: string, startTime: string): number {
    const ecpTimelinePadding: number = 20;

    if (new Date(endTime).getTime() - new Date(startTime).getTime() < 60 * 60 * 1000) {
      this.ecpTimelinesDashLineOffset = 30;
    }

    this.calculateEcpTimelineWidth(endTime, startTime);

    const moreThanHourValue: number = this.ecpTimelineWidth + this.ecpTimelineLeftContentWidth + ecpTimelinePadding;
    const lessThanOneHourValue: number = this.ecpTimelineVisibleWidth;

    if (
      Math.max(moreThanHourValue, lessThanOneHourValue) === lessThanOneHourValue &&
      this.document.getElementById('ecp-timeline-left-shadow')
    ) {
      this.document.getElementById('ecp-timeline-left-shadow').style.visibility = 'hidden';
    }

    return Math.max(moreThanHourValue, lessThanOneHourValue);
  }

  getEcpTimelineWidthDependingOnEventLength(endTime: string, startTime: string): number {
    this.calculateEcpTimelineWidth(endTime, startTime);
    const moreThanHourValue: number = this.ecpTimelineWidth;
    const lessThanOneHourValue: number = this.ecpTimelineVisibleWidth - this.ecpTimelineLeftContentWidth;

    return Math.max(moreThanHourValue, lessThanOneHourValue);
  }

  /* istanbul ignore next */
  getBaSetListWidthDependingOnEventLength(endTime: string, startTime: string, i: number, j: number): number {
    const ecpChartPaddings: number = 40;

    this.calculateBaSetTimelineWidth(endTime, startTime);

    const moreThanHourValue: number = this.baSetTimelineWidth + this.baSetTimelineLeftContentWidth - ecpChartPaddings;
    const lessThanOneHourValue: number = this.baSetTimelineVisibleWidth - ecpChartPaddings;

    if (
      Math.max(moreThanHourValue, lessThanOneHourValue) === lessThanOneHourValue &&
      this.document.getElementById(`ba-set-timeline-left-shadow-${j}${i}`)
    ) {
      this.document.getElementById(`ba-set-timeline-left-shadow-${j}${i}`).style.visibility = 'hidden';
      this.document.getElementById(`ba-set-timeline-right-shadow-${j}${i}`).style.visibility = 'hidden';
    }

    return Math.max(moreThanHourValue, lessThanOneHourValue);
  }

  getBaSetTimelineWidthDependingOnEventLength(endTime: string, startTime: string): number {
    this.calculateBaSetTimelineWidth(endTime, startTime);
    const moreThanHourValue: number = this.baSetTimelineWidth;
    const lessThanOneHourValue: number = this.baSetTimelineVisibleWidth - this.baSetTimelineLeftContentWidth;

    return Math.max(moreThanHourValue, lessThanOneHourValue);
  }

  /* istanbul ignore next */
  prepareIncidentTimelineChartData(
    alarms: IAlarm[],
    eventMarkers: [IEventMarker[]],
    mediaAssets: [IMediaAsset[]],
    eventMarkerClusters: [IEventMarkerClusters[]],
  ): AlarmChartData[] {
    const result: AlarmChartData[] = this.prepareEcpTimelineChartData(alarms);
    let resultLength: number = alarms.length;

    eventMarkers?.forEach((event: IEventMarker[], index: number) => {
      if (event.length === 1) {
        result.push(this.prepareEventMarkers(event[0], resultLength + index));
      } else {
        event.forEach((eventMarker: IEventMarker) => {
          result.push(this.prepareEventMarkers(eventMarker, resultLength + index));
        });
      }
    });

    resultLength = alarms.length + eventMarkers.length;

    mediaAssets?.forEach((assets: IMediaAsset[], index: number) => {
      if (assets.length === 1) {
        result.push(this.prepareMediaAssets(assets[0], resultLength + index));
      } else {
        assets.forEach((asset: IMediaAsset) => {
          result.push(this.prepareMediaAssets(asset, resultLength + index));
        });
      }
    });

    if (eventMarkerClusters) {
      resultLength = alarms.length + eventMarkerClusters.length;
    }

    eventMarkerClusters?.forEach((clusters: IEventMarkerClusters[], index: number) => {
      if (clusters.length === 1) {
        result.push(this.prepareIncidentEventMarkerClusterer(clusters[0], resultLength + index));
      } else {
        clusters.forEach((cluster: IEventMarkerClusters) => {
          result.push(this.prepareIncidentEventMarkerClusterer(cluster, alarms.length + eventMarkers.length + index));
        });
      }
    });

    return result;
  }

  /* istanbul ignore next */
  prepareEventMarkers(event: IEventMarker, lineIndex?: number): AlarmChartData {
    const acd: AlarmChartData = new AlarmChartData();

    acd.id = event.id;
    acd.type = event.type;
    acd.name = event.name;
    acd.lineIndex = lineIndex;
    acd.isManualEvent = true;
    acd.color = ReportCenterConstants.alarmColors.MANUAL_EVENT_COLOR;
    acd.borderColor = ReportCenterConstants.alarmColors.MANUAL_EVENT_BORDER;
    acd.entries = [{ start: new Date(event.startTime), end: new Date(event.endTime) }];

    return new AlarmChartData(acd);
  }

  /* istanbul ignore next */
  prepareMediaAssets(asset: IMediaAsset, lineIndex?: number): AlarmChartData {
    const acd: AlarmChartData = new AlarmChartData();

    acd.lineIndex = lineIndex;
    acd.name = asset.uploadedBy;
    acd.uploaderName = asset.uploaderName;
    acd.isMediaAsset = true;
    acd.color = ReportCenterConstants.alarmColors.MEDIA_ASSET_COLOR;
    acd.borderColor = ReportCenterConstants.alarmColors.MEDIA_ASSET_BORDER;
    acd.entries = [{ start: new Date(asset.startTime), end: new Date(asset.endTime) }];

    return new AlarmChartData(acd);
  }

  /* istanbul ignore next */
  prepareIncidentEventMarkerClusterer(cluster: IEventMarkerClusters, lineIndex?: number): AlarmChartData {
    const acd: AlarmChartData = new AlarmChartData();

    acd.lineIndex = lineIndex;
    acd.id = cluster.id;
    acd.name = cluster.teamName;
    acd.status = cluster.teamStatus;
    acd.type = cluster.type;
    acd.isEventMarkerCluster = true;
    acd.color = ReportCenterConstants.alarmColors.MEDIA_ASSET_COLOR;
    acd.borderColor = ReportCenterConstants.alarmColors.EVENT_MARKER;
    acd.entries = [{ start: new Date(cluster.timestamp), end: new Date(cluster.timestamp) }];

    return new AlarmChartData(acd);
  }

  /* istanbul ignore next */
  calculateNumberOfAlarmLines(
    alarms: IAlarm[],
    eventMarkers: [IEventMarker[]],
    mediaAssets: [IMediaAsset[]],
    eventMarkerClusters: [IEventMarkerClusters[]],
  ): number {
    return alarms?.length + eventMarkers?.length + mediaAssets?.length + eventMarkerClusters?.length;
  }

  calculateEcpTimelineHeight(alarms: IAlarm[]): number {
    const chartHeightWithoutAlarms: number = 30;

    return alarms.length === 0 ? chartHeightWithoutAlarms : null;
  }

  isLessThanOneHour(startDate: Date, endDate: Date): boolean {
    return convertMillisecondsToMinutes(new Date(endDate).getTime() - new Date(startDate).getTime()) < 60;
  }

  prepareEcpTimelineChartData(alarms: IAlarm[]): AlarmChartData[] {
    const result: AlarmChartData[] = [];

    alarms?.forEach((alarm: Alarm) => {
      const acd: AlarmChartData = new AlarmChartData();

      acd.type = alarm.type;
      acd.equipmentId = alarm.equipmentId;
      acd.firefighter = alarm.firefighterName;

      const spanIcon = (icon: string) => {
        return `<span class="odx-icon" data-icon-set="uib-legacy" data-icon-name="${icon}"><span></span></span>`;
      };

      switch (alarm.type) {
        case 'motion_alarm':
          acd.color = ReportCenterConstants.alarmColors.MOTION_ALARM_COLOR;
          acd.borderColor = ReportCenterConstants.alarmColors.MOTION_ALARM_BORDER;
          acd.icon = spanIcon('motion-sensing');
          acd.order = 1;
          break;
        case 'distress_button':
          acd.color = ReportCenterConstants.alarmColors.DISTRESS_BUTTON_COLOR;
          acd.borderColor = ReportCenterConstants.alarmColors.DISTRESS_BUTTON_BORDER;
          acd.icon = spanIcon('sos');
          acd.order = 2;
          break;
        case 'ecb_evacuation':
          acd.color = ReportCenterConstants.alarmColors.ECB_EVACUATION_COLOR;
          acd.borderColor = ReportCenterConstants.alarmColors.ECB_EVACUATION_BORDER;
          acd.icon = spanIcon('evacuation-warning');
          acd.order = 3;
          break;
        case 'ecb_out_withdraw':
          acd.color = ReportCenterConstants.alarmColors.WITHDRAW_SIGNAL_COLOR;
          acd.borderColor = ReportCenterConstants.alarmColors.WITHDRAW_SIGNAL_BORDER;
          acd.icon = '<div class="withdraw-signal-icon"></div>';
          acd.order = 4;
          break;
        case 'thermal_exposure_alarm':
          acd.color = ReportCenterConstants.alarmColors.THERMAL_ALARM_COLOR;
          acd.borderColor = ReportCenterConstants.alarmColors.THERMAL_ALARM_BORDER;
          acd.icon = spanIcon('thermal-alarm');
          acd.order = 5;
          break;
        case 'low_battery':
          acd.color = ReportCenterConstants.alarmColors.LOW_BATTERY_ALARM_COLOR;
          acd.borderColor = ReportCenterConstants.alarmColors.LOW_BATTERY_ALARM_BORDER;
          acd.icon = spanIcon('battery-alarm');
          acd.order = 6;
          break;
        case 'ecb_in_range':
          acd.color = ReportCenterConstants.alarmColors.LOSS_CONNECTION_COLOR;
          acd.borderColor = ReportCenterConstants.alarmColors.LOSS_CONNECTION_BORDER;
          acd.icon = spanIcon('telemetry-error');
          acd.order = 7;
          break;
      }

      const entries: AlarmChartEntry[] = [];

      alarm.durations?.forEach((interval: AlarmChartDurations) =>
        entries.push({ start: new Date(interval.startTime), end: new Date(interval.endTime) }),
      );
      acd.entries = this.getSortedEntries(entries);

      result.push(new AlarmChartData(acd));
    });

    result.sort((firstAlarm: AlarmChartData, secondAlarm: AlarmChartData) => firstAlarm.order - secondAlarm.order);

    return result;
  }

  getSortedEntries(entries: AlarmChartEntry[]): AlarmChartEntry[] {
    return entries.sort(
      (firstInterval: AlarmChartEntry, secondInterval: AlarmChartEntry) =>
        firstInterval.start.getTime() - secondInterval.start.getTime(),
    );
  }

  ecpTimelineOpen(event: any, index: number): void {
    if (!event.target.checked) {
      this.showEcpShadows = false;
      this.showBaSetShadows = false;
      this.ecpTimelineIsOpen[index] = false;
    } else {
      this.showEcpShadows = true;
      this.showBaSetShadows = true;
      this.ecpTimelineIsOpen[index] = true;
    }
  }

  /* istanbul ignore next */
  toggleBaSetAccordion(event: any, index: string, accordionOrder: number, ecpIndex: number): void {
    if (!event.target.checked) {
      const contentScroll: HTMLElement = this.document.getElementById(`ecp-content-scroll${accordionOrder}`);
      const incidentECP: HTMLElement = this.document.getElementById('ecp-content-scroll' + ecpIndex);

      setTimeout(() => {
        if (contentScroll) {
          this.baSetIsOpen.isOpen[index] = false;
          incidentECP.style.height = ``;
        }
      }, 300);
    } else {
      this.baSetIsOpen.isOpen[index] = true;
      this.calculateECPHeight(ecpIndex);
    }
  }

  /* istanbul ignore next */
  getManualEvent(event: any): void {
    if (event.id) {
      this.alarmIds.push(event.id);
    }

    if (
      this.alarmIds.length === 2 &&
      this.document.getElementById(this.alarmIds[0]) &&
      this.alarmIds[0] !== this.alarmIds[1]
    ) {
      this.document.getElementById(this.alarmIds[0]).style.stroke = 'white';
      this.alarmIds.shift();
    } else if (this.alarmIds.length === 2 && this.alarmIds[0] === this.alarmIds[1]) {
      this.alarmIds.shift();
    }

    this.getManualEventId.emit(event);
  }

  public convertToDateAndStripSeconds(date: string): Date {
    return new Date(new Date(date).setSeconds(0));
  }

  public addMinutes(date: Date, minutes: number): Date {
    return new Date(Math.round(date.getTime() + minutes * 60000));
  }

  public prepareAccordionLabel(alarm: Alarm): string {
    return `${alarm.equipmentId} - ${alarm.firefighterName}`;
  }

  ecpTimelineLoaded(event: boolean): void {
    this.showTimelineScrollBar = event;
  }

  /* istanbul ignore next */
  horizontalTimelineScroll(ecpsList: HTMLElement, timelineScroll: HTMLElement, baSetsList: HTMLElement): void {
    ecpsList.scrollLeft = timelineScroll.scrollLeft;
    baSetsList.querySelectorAll('.ba-set-item').forEach((element: HTMLElement) => {
      element.scrollLeft = timelineScroll.scrollLeft;
    });
  }

  /* istanbul ignore next */
  calculateECPHeight(index: number): void {
    const headersAndFooterHeight: number = 200;
    const marginsBetweenTimelines: number = 49;
    const offset: number = 25;
    const expandLimit: number = 400;

    const incidentAnalysisWrapperHeight: number = window.innerHeight - headersAndFooterHeight;
    const eventTimelineHeight: number =
      (this.timelineWrapper.first.nativeElement as HTMLElement).offsetHeight + marginsBetweenTimelines;
    const ecpTimeline: HTMLElement = this.document.getElementById('ecp-timeline-scroll');

    const incident: HTMLElement = this.document.getElementById(`ecp-${index}`);
    const incidentECP: HTMLElement = this.document.getElementById('ecp-content-scroll' + index);

    if (incidentAnalysisWrapperHeight > eventTimelineHeight + ecpTimeline.offsetHeight) {
      const availableSpace: number = incidentAnalysisWrapperHeight - (eventTimelineHeight + ecpTimeline.offsetHeight);
      const maxHeight: number = incident.offsetHeight + availableSpace - offset;

      if (maxHeight < expandLimit) {
        incidentECP.style.maxHeight = `${expandLimit}px`;
      } else {
        incidentECP.style.maxHeight = `${maxHeight}px`;
      }
    } else if (incidentECP.offsetHeight > expandLimit) {
      incidentECP.style.maxHeight = `${incidentECP.offsetHeight}px`;
    }
  }
}
