import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import uniqBy from 'lodash-es/uniqBy';
import { CommonConstants, DropdownService } from 'src/app/common';
import { EntryModel, IEntryModel, IScrollOptions } from 'src/app/common/models/common.model';
import { formatLocaleDate } from 'src/app/common/utils/date-utils/date.utils';
import { RemoteMonitoringConstants } from 'src/app/remote-monitoring/constants/remote-monitoring.constants';
import { IncidentNotificationConstants } from 'src/app/report-center';
import { ReportCenterConstants } from 'src/app/report-center/constants/report-center.constants';
import { IArrayElement, IEcp, IEntriesModel, IIncident } from 'src/app/report-center/models/incident.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ignis-incident-information',
  templateUrl: './incident-information.component.html',
  styleUrls: ['./incident-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IncidentInformationComponent implements OnChanges, AfterViewInit {
  @ViewChild('incidentTypeDD') incidentTypeDD: Partial<NgSelectComponent>;
  @ViewChild('specialExposureDD') specialExposureDD: Partial<NgSelectComponent>;

  @Input() incidentForm: FormGroup;
  @Input() selectedIncident: IIncident;
  @Input() formatDate: string;
  @Input() language: string;
  @Input() overlappingIncidentNumber: string;
  @Input() isSubmitted: boolean;
  @Input() errors: string;
  @Input() entries: IEntriesModel;

  incidentTypes: IEntryModel[];
  incidentTypesToShow: IEntryModel[];
  specialExposures: IEntryModel[];
  specialExposuresToShow: IEntryModel[];
  incidentErrors: any = IncidentNotificationConstants.notificationCodes;
  startTimeError: string;
  endTimeError: string;

  showEcpNameToolTip: number = -1;
  showStartTimeTooltip: boolean = false;
  showEndTimeTooltip: boolean = false;

  formatLocaleDate: any = formatLocaleDate;

  noteworthyAlarms: IArrayElement[];
  relevantAlarmsNo: number = 0;

  apiKey: string = environment.GOOGLE_MAP.GOOGLE_API_KEY;
  darkTheme: any = RemoteMonitoringConstants.staticDarkTheme;
  dropdownIconCSSClass: string = CommonConstants.defaultDropdownIconCSSClass;
  incidentImage: string;
  imageSize: string = this.calculateImageSize();
  isModalOpened: boolean = false;
  ddAreOpened: boolean[];

  @ViewChild('deployedVehicleElem') deployedVehicleElem: ElementRef;
  isTextOverflow: boolean = false;

  scrollbarOptions: IScrollOptions = CommonConstants.scrollbarOptions;

  constructor(
    private translateService: TranslateService,
    public dropdownService: DropdownService,
  ) {
    window.addEventListener('resize', () => {
      this.imageSize = this.calculateImageSize();
      this.processStaticMap();
    });

    this.ddAreOpened = [];
  }

  ngOnChanges(): void {
    if (this.entries) {
      this.incidentTypes = this.entries.types.map((entry: IEntryModel) => ({
        ...entry,
        label: this.translateService.instant(
          ReportCenterConstants.incidentEntries.types.find((t: EntryModel) => t.value === entry.value)?.localizedName ||
            entry.value,
        ),
      }));

      this.incidentTypesToShow = this.incidentTypes;

      this.specialExposures = this.entries.specialExposures.map((entry: IEntryModel) => ({
        ...entry,
        label: this.translateService.instant(
          ReportCenterConstants.incidentEntries.specialExposures.find((t: EntryModel) => t.value === entry.value)
            ?.localizedName || entry.value,
        ),
      }));

      this.specialExposuresToShow = this.specialExposures;
    }

    this.displayPageErrors();

    if (this.selectedIncident?.incidentData?.summary) {
      this.noteworthyAlarms = structuredClone(this.selectedIncident?.incidentData.summary?.noteworthyAlarms).sort(
        (a: IArrayElement, b: IArrayElement) => b.number - a.number,
      );

      this.relevantAlarmsNo = 0;

      this.noteworthyAlarms.forEach((elem: IArrayElement) => {
        this.relevantAlarmsNo += elem.number;
        elem.name = this.translateService.instant(
          ReportCenterConstants.incidentEntries.alarmTypes.find((t: EntryModel) => t.value === elem.name)
            ?.localizedName || elem.name,
        );
      });
    }

    this.processStaticMap();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isTextOverflow = this.isEllipsisActive(this.deployedVehicleElem.nativeElement);
    }, 0);
  }

  displayPageErrors(): void {
    if (this.errors) {
      if (
        this.errors === IncidentNotificationConstants.notificationCodes.START_TIME_ERROR.value ||
        this.errors === IncidentNotificationConstants.notificationCodes.OVERLAPPING_START_TIME.value ||
        this.errors === IncidentNotificationConstants.notificationCodes.OVERLAPPING_TIME_RANGE.value
      ) {
        this.startTimeError = this.errors;
      }

      if (
        this.errors === IncidentNotificationConstants.notificationCodes.END_TIME_ERROR.value ||
        this.errors === IncidentNotificationConstants.notificationCodes.OVERLAPPING_END_TIME.value ||
        this.errors === IncidentNotificationConstants.notificationCodes.OVERLAPPING_TIME_RANGE.value
      ) {
        this.endTimeError = this.errors;
      }
    }
  }

  isEllipsisActive(e: HTMLElement): boolean {
    return e.offsetWidth < e.scrollWidth;
  }

  composeListTooltip(array: string[] | string): string {
    if (array === null || array === undefined) {
      return '';
    }

    let stringToReturn: string = '';

    if (!Array.isArray(array) && typeof array === 'string') {
      array = array.split(',');
    }

    array.forEach((item: string) => (stringToReturn = stringToReturn + item + '\n'));

    return stringToReturn;
  }

  get ecps(): FormArray {
    return this.incidentForm.get('ecps') as FormArray;
  }

  onStartTimeSelect(date: Date): void {
    this.incidentForm.get('incidentData.startTime').setValue(new Date(date).toISOString());
    this.incidentForm.get('incidentData.startTime').markAsDirty();
    this.startTimeError = null;

    if (
      this.errors === IncidentNotificationConstants.notificationCodes.OVERLAPPING_START_TIME.value ||
      this.errors === IncidentNotificationConstants.notificationCodes.OVERLAPPING_TIME_RANGE.value
    ) {
      this.endTimeError = null;
    }
  }

  onEndTimeSelect(date: Date): void {
    this.incidentForm.get('incidentData.endTime').setValue(new Date(date).toISOString());
    this.incidentForm.get('incidentData.endTime').markAsDirty();
    this.endTimeError = null;

    if (
      this.errors === IncidentNotificationConstants.notificationCodes.OVERLAPPING_END_TIME.value ||
      this.errors === IncidentNotificationConstants.notificationCodes.OVERLAPPING_TIME_RANGE.value
    ) {
      this.startTimeError = null;
    }
  }

  selectPrimaryEcp(selectedEcpControl: any, i: number): void {
    const ecpsArray: FormArray = this.incidentForm.get('ecps') as FormArray;

    if (!selectedEcpControl.value.primary) {
      selectedEcpControl.markAsPristine();
    }

    ecpsArray.getRawValue().forEach((_ecp: IEcp, index: number) => {
      if (index !== i) {
        ecpsArray.get(index.toString()).get('primary').setValue(false);
      } else {
        ecpsArray.get(index.toString()).get('primary').setValue(true);
      }
    });
  }

  processStaticMap(): void {
    if (this.selectedIncident.ecps?.length > 0) {
      const markersList: string[] = [];

      this.selectedIncident.ecps.forEach((ecp: IEcp, index: number) => {
        if (ecp.location.coordinates?.lat && ecp.location.coordinates?.lng) {
          markersList.push(
            `&markers=color:0xFFFFFF%7Clabel:${index + 1}%7C${ecp.location.coordinates?.lat},${
              ecp.location.coordinates?.lng
            }`,
          );
        }
      });

      const markers: string | any = markersList.toString();
      const markerString: any = markers.replaceAll(',&', '&');

      if (markersList.length > 0) {
        this.incidentImage = `https://maps.googleapis.com/maps/api/staticmap?size=${this.imageSize}&maptype=roadmap&style=${this.darkTheme}${markerString}&key=${this.apiKey}&language=${this.language}`;
      }
    }
  }

  calculateImageSize(): string {
    return `${Math.round((60 * window.innerWidth) / 100)}x${Math.round((75 * window.innerHeight) / 100)}`;
  }

  showMap(): void {
    this.isModalOpened = true;
  }

  closeMediaImageModal(): void {
    this.isModalOpened = false;
  }

  addNewEntryInIncidentDropdown(event: InputEvent, listNameToBeDisplayed: string, listName: string): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;

    if (target.value) {
      const mergedArray: IEntryModel[] = [{ value: target.value, label: target.value }, ...(this[listName] || [])];

      this[listNameToBeDisplayed] = uniqBy(mergedArray, 'label');
    } else {
      this[listNameToBeDisplayed] = this[listName];
    }
  }

  onEntryChange(value: string, formFieldName: string): void {
    if (!value) {
      this.incidentForm.get(formFieldName).setValue(null);
    }
  }

  toggleDropdown(ddElemName: string): void {
    this.ddAreOpened.push(this[ddElemName].isOpen);

    if (this.ddAreOpened.length === 2) {
      this[ddElemName].close();
      this[ddElemName].focus();
      this.ddAreOpened = [];
    }
  }
}
