<ngx-simplebar class="widget-container">
  <div class="note-list" *ngFor="let note of notesList">
    <div class="note">
      <div class="icon" [class]="currentAppTheme">
        <odx-icon size="inline" name="user"></odx-icon>
      </div>
      <div class="note-content">
        <span class="name" data-test-id="incident_media_assets.sender_name" *ngIf="note.senderName">
          {{ note.senderName }}
        </span>
        <span class="location" [ngClass]="{ 'no-margin-top': !note.senderName }">
          <span *ngIf="note.ecpNumber">
            {{ 'INCIDENT_MEDIA_ASSETS.STR_NOTE_ECP_NUMBER' | translate }} {{ note.ecpNumber }}
          </span>
          <span
            *ngIf="note.ecpNumber && note.teamName && note.teamId !== '0'"
            data-test-id="incident_media_assets.empty_team_name"
            >-</span
          >
          <span *ngIf="note.teamName && note.teamId !== '0'" data-test-id="incident_media_assets.team_name">
            {{ 'INCIDENT_MEDIA_ASSETS.STR_NOTE_TEAM_NUMBER' | translate }} {{ note.teamName }}
          </span>
        </span>
        <span class="message" data-test-id="incident_media_assets.message">
          {{ note.text }}
        </span>
        <div class="audio-note" *ngIf="note?.mediaSrc">
          <odx-icon size="inline" name="microphone"></odx-icon>
          <audio controls controlslist="nodownload noplaybackrate" data-test-id="incident_media_assets.audio">
            <source [src]="note.mediaSrc" />
          </audio>
        </div>
        <div class="audio-note align-loader-to-center" *ngIf="note?.mediaLink && !note?.mediaSrc">
          <div [odxLoadingSpinner]="true" [odxLoadingSpinnerMinHeight]="60" class="app-loader"></div>
        </div>

        <div class="note-footer" [class]="currentAppTheme">
          <span> {{ note.timestamp | date: formatDate }} {{ note.time }} </span>
        </div>
      </div>
      <div class="hover-effect" #displayOnHover></div>
    </div>
  </div>
</ngx-simplebar>
