<ignis-progress-bar *ngIf="isLoading$ | async"></ignis-progress-bar>
<ignis-table
  [tableName]="tableName"
  [localColumns]="localTableColumns"
  [dataTable]="equipmentList"
  [resizableColumns]="true"
  [reorderableColumns]="true"
  [displayFilters]="true"
  [activateClickOutside]="activateClickOutside"
  [customFilterLayout]="customFilterLayouts"
  [customColumnsLayout]="['rfid', 'serialNo', 'partNo', 'partName', 'operationalStatus', 'serviceStatus']"
  [customColumnsRef]="[rfid, serialNo, partNo, partName, operationalStatus, serviceStatus]"
  [selectActiveOptions]="selectedDropdowns"
  [isDefaultRangeShown]="true"
  [activeDate]="selectedDates"
  [datePickerFormat]="formatDate"
  [datePickerSelectionMode]="'range'"
  [isDatePickerShowingTime]="false"
  [lazy]="'true'"
  [savedFiltersName]="tableFiltersKey"
  [isLoading]="isLoading$ | async"
  [sortColumn]="sortColumn"
  [paginator]="'true'"
  [pageNumber]="pageNumber"
  [first]="first"
  [totalRecords]="totalRecords"
  (columns)="processingTableSettings($event)"
  (handleOnSortChange)="tableHelperOnChangeSort($event)"
  (handleTableRef)="tableHelperGetTableRef($event)"
  (handleRowSelection)="onSelectEquipment($event)"
  (handleDoubleClickRowSelection)="openSelectedEquipmentOnDblClick()"
  (handleColumnVisibility)="changeColumnVisibility($event)"
  (handleFilterValue)="getFilterTableValue($event)"
  (handleOnLazyLoading)="tableHelperOnLazyLoading($event)"
  (handleOnPageChange)="tableHelperOnPageChange($event)"
>
  <ng-template #rfid let-rfid>
    <div *ngIf="rfid.field === 'rfid' && rfid.data">
      <ignis-table-ellipsis-data-column [columnData]="rfid"></ignis-table-ellipsis-data-column>
    </div>
  </ng-template>

  <ng-template #serialNo let-serialNo>
    <div *ngIf="serialNo.field === 'serialNo' && serialNo.data">
      <ignis-table-ellipsis-data-column [columnData]="serialNo"></ignis-table-ellipsis-data-column>
    </div>
  </ng-template>

  <ng-template #partNo let-partNo>
    <div *ngIf="partNo.field === 'partNo' && partNo.data">
      <ignis-table-ellipsis-data-column [columnData]="partNo"></ignis-table-ellipsis-data-column>
    </div>
  </ng-template>

  <ng-template #partName let-partName>
    <div *ngIf="partName.field === 'partName' && partName.data">
      <ignis-table-ellipsis-data-column [columnData]="partName"></ignis-table-ellipsis-data-column>
    </div>
  </ng-template>

  <ng-template #operationalStatus let-operationalStatus>
    <div *ngIf="operationalStatus.field === 'operationalStatus'">
      <ignis-operational-status-column [operationalStatus]="operationalStatus"></ignis-operational-status-column>
    </div>
  </ng-template>

  <ng-template #serviceStatus let-serviceStatus>
    <div *ngIf="serviceStatus.field === 'serviceStatus' && serviceStatus.data">
      <ignis-service-status-column [serviceStatus]="serviceStatus"></ignis-service-status-column>
    </div>
  </ng-template>
</ignis-table>

<ignis-export-table-excel
  [excelArray]="excelArray"
  [formatDate]="formatDate"
  [sheetName]="'EXCEL_TABLE_EXPORT.EQUIPMENT' | translate"
  [fileName]="'EXCEL_TABLE_EXPORT.EQUIPMENT' | translate"
  [tableColumns]="tableColumns"
  [excelTranslationKeys]="excelTranslationKeys"
  (handleExcelExportFinished)="tableHelperExcelExportDone()"
></ignis-export-table-excel>

<input type="file" accept=".csv" #fileInput class="upload-csv-input" (change)="uploadFile($event.target.files)" />

<footer class="blue-bar">
  <ignis-inventory-view-footer
    [selectedItem]="selectedEquipment"
    [numberOfRecordsToBeExported]="totalRecords"
    [disableStartTestBtn]="!selectedEquipment?.hasProtectorTestType"
    [isModalOpened]="openConfirmationDeleteDialog || openModal"
    [isSubmitDisabled]="isSubmitting | async"
    (handleCreate)="openCreateEquipment()"
    (handleEdit)="openUpdateEquipment()"
    (handleNavigateToEquipment)="openUpdateEquipment()"
    (handleDelete)="openDeleteEquipmentDialog()"
    (handleTest)="testEquipment('workshop/inventory')"
    (handleHistory)="redirectToHistory()"
    (handleDownloadTemplate)="downloadTemplate()"
    (handleUploadCSVFile)="fileInput.click()"
    (handleExcelExport)="exportExcelFile()"
  >
  </ignis-inventory-view-footer>
</footer>

<ignis-import-csv-error-modal
  [openModal]="openModal"
  [invalidDataList]="httpCustomErrorCode"
  [tableColumns]="importCSVColumns"
  [errorsConstant]="importCSVErrorCodes"
  [pageUrl]=""
  (handleCloseModal)="closeModal($event)"
>
  <div slot="header">
    <odx-icon size="xlarge" name="error"></odx-icon>
    {{ 'INVENTORY_CSV_IMPORT_ERROR_LIST.STR_UPLOAD_CSV_ERROR_MSG' | translate }}
  </div>
</ignis-import-csv-error-modal>

<ignis-delete-confirmation-modal
  [isOpen]="openConfirmationDeleteDialog"
  [isLoading]="isLoading$ | async"
  (handleDeleteModal)="closeDeleteEquipmentDialog($event)"
>
  <div slot="header">
    {{ 'DELETE_EQUIPMENT.STR_MODAL_HEADER' | translate }}
  </div>
  <div slot="content">
    {{ 'DELETE_EQUIPMENT.STR_MODAL_CONTENT' | translate }}
  </div>
</ignis-delete-confirmation-modal>

<ignis-ram-connector-auto-update-modal
  [ramConnectorStatus]="ramConnectorStatus"
  [startAutoUpdate]="startAutoUpdate"
  [type]="notificationType.ERROR"
  (handleConfirmationModal)="startRAMConnectorAutoUpdateFromEQList($event)"
  (handleAutoUpdateStartsWithSuccess)="startAutoUpdate = false"
>
  <div slot="content">
    {{ 'PROTECTOR_SOFTWARE_NOTIFICATION.STR_PROTECTOR_SOFTWARE_VERSION_NOT_SUPPORTED' | translate }}
  </div>
</ignis-ram-connector-auto-update-modal>
