import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, inject, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ModalRef, ModalService, ModalSize, ModalVariant } from '@odx/angular/components/modal';
import { TreeSelect } from 'primeng/treeselect';
import { combineLatest, filter, map, Observable, switchMap, take, takeUntil } from 'rxjs';
import { NotificationConstants } from 'src/app/common/constants/notification.constants';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { OKTAProfile, PropertyBag } from 'src/app/common/models/common.model';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { processLocationPath } from 'src/app/common/utils/execute-test-utils/start-test';
import { getLanguageFromOrganizationProfile } from 'src/app/common/utils/settings-utils/settings-utils';
import { ILocationHierarchy } from 'src/app/configuration/models/location-configuration.model';
import { LocationHierarchyActions } from 'src/app/configuration/state/actions';
import { EquipmentNotificationConstants, WorkshopConstants } from 'src/app/workshop';
import { IEquipment } from 'src/app/workshop/models/equipment.model';
import { ILocationChange } from 'src/app/workshop/models/service-task.model';
import { EquipmentServiceTaskActions } from 'src/app/workshop/state/actions/equipment-service-task';
import { selectLocationHierarchy } from '../../../../configuration/state/selectors/configuration.selectors';
import { ICylinderChargerRequest, IIntervalData, ILocationAssignment } from '../../../models/service-task.model';
import {
  ApplicationState,
  CommonConstants,
  DropdownService,
  IStoreApiItem,
  IStoreApiList,
  TableConstants,
} from 'src/app/common';

import {
  selectSavedEquipments,
  selectSelectedServiceEquipment,
  selectSendServiceInterval,
  selectUpdateEquipmentsLocation,
} from '../../../state/selectors';

@Component({
  selector: 'ignis-cylinder-charger',
  templateUrl: './cylinder-charger.component.html',
  styleUrls: ['./cylinder-charger.component.scss'],
})
export class CylinderChargerComponent extends OnDestroyMixin() implements OnInit, AfterViewInit {
  @ViewChild('cylinderChargerModal', { read: TemplateRef }) cylinderChargerModal: TemplateRef<ViewContainerRef>;
  @ViewChild('dd1') dd1: Partial<TreeSelect>;

  modalRef: ModalRef<string, string>;
  private readonly modalService: ModalService = inject(ModalService);
  readonly minimumPressureConstant: number = WorkshopConstants.minimumPressureConstant;
  operationalStatuses: PropertyBag = WorkshopConstants.operationalStatuses;

  serviceForm: FormGroup = new FormGroup({
    location: new FormControl(null),
    airQualityMeasured: new FormControl(false),
    comment: new FormControl(null),
    pressure: new FormControl(null, [Validators.min(this.minimumPressureConstant), Validators.required]),
    aggregateIDs: new FormControl([], [Validators.required]),
  });

  isLoading: Observable<boolean>;
  locations: ILocationHierarchy[];
  isInvalidPressureDisplayed: boolean;
  selectedEquipment: IIntervalData[];
  selectedLocation: ILocationAssignment;
  user: Partial<OKTAProfile>;
  jwtHelper: JwtHelperService = new JwtHelperService();
  dropdownIconCSSClass: string = CommonConstants.defaultDropdownIconCSSClass;

  previousRoute: string = '';
  translatedWorkflow: string;
  isSubmitting: Observable<boolean>;
  tableScrollingFallback: number = TableConstants.NO_ROWS_TABLE_SCROLLING_FALLBACK;

  equipmentServiceTaskActions: EquipmentServiceTaskActions = inject(EquipmentServiceTaskActions);
  locationHierarchyActions: LocationHierarchyActions = inject(LocationHierarchyActions);
  notificationsService: NotificationsService = inject(NotificationsService);
  translateService: TranslateService = inject(TranslateService);
  location: Location = inject(Location);

  constructor(
    public router: Router,
    private store: Store<ApplicationState>,
    private httpClient: HttpClient,
    private dropdownService: DropdownService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.user = this.jwtHelper.decodeToken(localStorage.getItem(CommonConstants.AUTH_TOKEN));

    this.locationHierarchyActions.requestLocationHierarchy();

    this.isLoading = combineLatest([
      this.store.select(selectSelectedServiceEquipment),
      this.store.select(selectLocationHierarchy),
    ]).pipe(
      map(
        ([equipmentState, locationState]: [IStoreApiList<IIntervalData[]>, IStoreApiList<ILocationHierarchy[]>]) =>
          equipmentState.isLoading || locationState.isLoading,
      ),
      takeUntil(this.destroy),
    );

    this.getLocations();

    this.previousRoute = localStorage.getItem('previousRoute');

    this.isSubmitting = this.store.select(selectSendServiceInterval).pipe(
      takeUntil(this.destroy),
      map((serviceInterval: IStoreApiItem<ICylinderChargerRequest>) => serviceInterval.isLoading),
    );
  }

  ngAfterViewInit(): void {
    this.openCylinderChargerModal();
    this.getEquipment();
    this.readOrganizationLanguageAndLoadTranslation();
  }

  readOrganizationLanguageAndLoadTranslation(): void {
    getLanguageFromOrganizationProfile(this.store, this.destroy)
      .pipe(switchMap((language: string) => this.httpClient.get(`assets/i18n/${language}.json`)))
      .subscribe((response: PropertyBag) => {
        this.translatedWorkflow = response['ORGANIZATION_SETTINGS.STR_CYLINDER_CHARGE_WORKFLOW'];
      });
  }

  getEquipment(): void {
    combineLatest([this.store.select(selectSavedEquipments), this.store.select(selectSelectedServiceEquipment)])
      .pipe(
        filter(
          ([equipment, equipmentServices]: [IStoreApiList<IEquipment[]>, IStoreApiList<IIntervalData[]>]) =>
            !equipment.isLoading && !equipmentServices.isLoading,
        ),
        take(1),
      )
      .subscribe(([equipment, equipmentServices]: [IStoreApiList<IEquipment[]>, IStoreApiList<IIntervalData[]>]) => {
        let eqArr: IEquipment[] = structuredClone(equipment.data);

        if (!equipment.data) {
          eqArr = JSON.parse(localStorage.getItem(CommonConstants.equipmentDataAfterScanLocation));
        }

        if (eqArr && equipmentServices.data) {
          const eqServiceArr: IIntervalData[] = structuredClone(equipmentServices.data);

          eqServiceArr.forEach((intData: IIntervalData) => {
            eqArr.forEach((eq: IEquipment) => {
              if (intData.equipmentAggregateId === eq.aggregateId) {
                intData.location = eq.locationAssignment?.name;
                intData.locationPath = processLocationPath(eq.locationAssignment?.path);
              }
            });
          });

          this.selectedEquipment = eqServiceArr;

          this.prepareEquipmentData();
        } else {
          this.closeModal();
        }
      });
  }

  prepareEquipmentData(): void {
    const listOfPressures: number[] = this.selectedEquipment.map((equipment: IIntervalData) => equipment.maxPressure);
    const listOfAggregateIDS: string[] = this.selectedEquipment.map(
      (equipment: IIntervalData) => equipment.equipmentAggregateId,
    );
    const minMaxPressureFromSelected: number = Math.round(Math.min(...listOfPressures));

    this.serviceForm.get('pressure').patchValue(minMaxPressureFromSelected);
    this.serviceForm.get('aggregateIDs').patchValue([...new Set(listOfAggregateIDS)]);
  }

  getLocations(): void {
    this.store
      .pipe(
        select(selectLocationHierarchy),
        map((locationList: IStoreApiList<ILocationHierarchy[]>) => locationList.data || []),
        takeUntil(this.destroy),
      )
      .subscribe((response: ILocationHierarchy[]) => {
        this.locations = structuredClone(response);
        this.dropdownService.findTreeNodeAndAddIcon(this.locations);
      });
  }

  openCylinderChargerModal(): void {
    this.modalRef = this.modalService.open(this.cylinderChargerModal, {
      size: ModalSize.MEDIUM,
      variant: ModalVariant.DEFAULT,
      dismissable: false,
      data: 'isOpen',
    });
  }

  closeModal(): void {
    this.modalRef.close('');

    Object.defineProperty(this.modalRef, 'data', { value: 'isClosed', writable: false });
    localStorage.removeItem(CommonConstants.equipmentDataAfterScanLocation);
    this.location.back();
  }

  prepareSubmitData(): ICylinderChargerRequest {
    return {
      intervalsData: this.selectedEquipment.map((eq: IIntervalData) => {
        return {
          ...eq,
          operationalStatus: eq.operationalStatus,
          locationPath: eq.locationPath ? eq.locationPath : [],
          others: eq.includedTasks ? eq.includedTasks : [],
          outOfServiceDate: eq.outOfServiceDate,
          outOfServiceReason: eq.outOfServiceReason,
        };
      }),
      cylinderChargingData: {
        airQualityMeasured: this.serviceForm.get('airQualityMeasured').value as boolean,
        comment: this.serviceForm.get('comment').value as string,
        pressure: this.serviceForm.get('pressure').value as number,
        taskName: this.translatedWorkflow,
      },
      testerName: `${this.user.lastname}, ${this.user.firstname}`,
    };
  }

  onSubmit(): void {
    const requestData: ICylinderChargerRequest = this.prepareSubmitData();

    requestData.intervalsData.forEach((interval: IIntervalData) => {
      delete interval.includedTasks;
      delete interval.isCylinderType;
    });

    this.equipmentServiceTaskActions.requestSendServiceIntervals(requestData);

    this.store
      .pipe(
        select(selectSendServiceInterval),
        filter((serviceInterval: IStoreApiItem<ICylinderChargerRequest>) => !serviceInterval.isLoading),
        take(1),
      )
      .subscribe((response: IStoreApiItem<ICylinderChargerRequest>) => {
        if (response.isSuccess) {
          const timeout: ReturnType<typeof setTimeout> = setTimeout(() => {
            if (this.selectedLocation) {
              this.updateLocations();
            } else {
              this.closeModal();

              this.notificationsService.requestShowNotification(
                CommonConstants.notificationType.SUCCESS,
                EquipmentNotificationConstants.notificationCodes.CYLINDER_CHARGING_SUCCESS,
                EquipmentNotificationConstants.notificationCodes,
              );
            }

            clearTimeout(timeout);
          }, 0);
        }

        if (response.errors) {
          const errors: string = (response.errors.error?.code as string).toString();

          if (errors === EquipmentNotificationConstants.notificationCodes.MANDATORY_DATA_MISSING.value) {
            this.notificationsService.requestShowNotification(
              CommonConstants.notificationType.ERROR,
              EquipmentNotificationConstants.notificationCodes.LOCATION_CHANGED_FAILED,
              EquipmentNotificationConstants.notificationCodes,
            );
          } else {
            this.notificationsService.requestShowNotification(
              CommonConstants.notificationType.ERROR,
              NotificationConstants.globalCodes.GENERIC_SERVER_ERROR,
              NotificationConstants.globalCodes,
            );
          }
        }
      });
  }

  updateLocations(): void {
    const preparedUpdateLocationData: ILocationChange = {
      equipmentAggregateIds: [...(this.serviceForm.get('aggregateIDs').value as string[])],
      locationAssignment: { aggregateId: this.selectedLocation.aggregateId },
    };

    this.equipmentServiceTaskActions.requestUpdateEquipmentLocation(preparedUpdateLocationData);

    this.store
      .pipe(
        select(selectUpdateEquipmentsLocation),
        filter((equipmentsLocation: IStoreApiItem<ILocationChange>) => !equipmentsLocation.isLoading),
        take(1),
      )
      .subscribe((response: IStoreApiItem<ILocationChange>) => {
        if (response.isSuccess) {
          this.closeModal();

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            EquipmentNotificationConstants.notificationCodes.LOCATION_CHANGED_SUCCESSFULLY,
            EquipmentNotificationConstants.notificationCodes,
          );

          return;
        }

        if (response.errors) {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.ERROR,
            EquipmentNotificationConstants.notificationCodes.LOCATION_CHANGED_FAILED,
            EquipmentNotificationConstants.notificationCodes,
          );
        }
      });
  }

  setSelectedLocation(selected: { node: { data: ILocationAssignment } }): void {
    this.selectedLocation = { ...selected.node.data };
    this.dd1.overlayVisible = false;
  }

  clearSelectedLocation(): void {
    this.selectedLocation = null;
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/dot-notation
    super['ngOnDestroy'] && super['ngOnDestroy']();

    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (this.router.url !== 'workshop/workflow/cylinder' && this.modalRef && this.modalRef['data'] === 'isOpen') {
      this.modalRef?.close('');
    }

    this.equipmentServiceTaskActions.saveSelectedServiceEquipment(null);
    this.equipmentServiceTaskActions.resetSentServiceIntervals();
    this.equipmentServiceTaskActions.resetUpdateEquipmentsLocation();
    localStorage.removeItem(CommonConstants.equipmentDataAfterScanLocation);
  }
}
