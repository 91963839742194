import { AfterViewInit, Component, HostListener, inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { IStoreApiList, MapService } from 'src/app/common';
import { CommonConstants } from 'src/app/common/constants/common.constants';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { PropertyBag } from 'src/app/common/models/common.model';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { ReportCenterConstants } from 'src/app/report-center/constants/report-center.constants';
import { getDateFormatFromUserProfile, getLanguage } from '../../../common/utils/settings-utils/settings-utils';
import { IncidentNotificationConstants } from '../../constants';
import { ReportCenterModuleRoutes } from '../../constants/report-center-module-routes.constants';
import { IEntriesModel, IIncident } from '../../models/incident.model';
import { IncidentService } from '../../services/incident.service';
import { IncidentActions } from '../../state/actions/incident.actions';
import { IIncidentState } from '../../state/models/incident.model';
import { selectEntries, selectIncident } from '../../state/selectors/incident.selector';
import { IEcp, IEventMarker, IIncidentExcelData } from './../../models/incident.model';
import { ExportExcelService } from './../../services/export-excel.service';
import { selectDeletedEventMarker, selectIncidentExcelData } from './../../state/selectors/incident.selector';

@Component({
  selector: 'ignis-view-update-incident',
  templateUrl: './view-update-incident.component.html',
  styleUrls: ['./view-update-incident.component.scss'],
})
export class ViewUpdateIncidentComponent extends OnDestroyMixin() implements OnInit, AfterViewInit, OnDestroy {
  isLoading: boolean;
  isLoadingAsync: Observable<boolean>;
  selectedIncident: IIncident;
  incidentForm: FormGroup;
  incidentState: IIncidentState;
  selectedEventMarker: any;
  successAddUpdate: boolean = false;
  isSubmitted: boolean = false;
  hasErrors: boolean = false;
  errors: any;
  isConfirmCloseModalOpen: boolean = false;
  activeTab: string = ReportCenterConstants.viewEditModalTabs.INCIDENT_INFORMATION;
  tabs: PropertyBag = ReportCenterConstants.viewEditModalTabs;
  tabsWithErrors: string[] = [];
  overlappingIncidentNumber: string;
  isSubmitting: Observable<boolean>;

  formatDate: string;
  language: Observable<string>;
  entries: IEntriesModel;
  openConfirmationDeleteDialog: boolean = false;
  activateClickOutside: boolean = true;

  incidentExcelData: Observable<IIncidentExcelData>;
  previousRoute: string;
  readonly ReportCenterModuleRoutes: PropertyBag = ReportCenterModuleRoutes;

  incidentActions: IncidentActions = inject(IncidentActions);
  notificationsService: NotificationsService = inject(NotificationsService);
  exportExcelService: ExportExcelService = inject(ExportExcelService);
  translateService: TranslateService = inject(TranslateService);
  incidentService: IncidentService = inject(IncidentService);
  mapService: MapService = inject(MapService);

  linkToNavigate: string;
  hazmatIncidentType: string = 'hazmat';

  constructor(
    private store: Store<IApplicationState>,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
  ) {
    super();

    this.router.events?.subscribe((event: any) => {
      this.linkToNavigate = event.url;
    });
  }

  @HostListener(CommonConstants.popStateWindowEvent, ['$event'])
  onPopState(event: any): void {
    event.returnValue = false;
  }

  @HostListener(CommonConstants.beforeUnloadWindowEvent, ['$event'])
  handleBeforeUnload($event: any): void {
    if (this.incidentForm.dirty) {
      $event.returnValue = this.incidentForm.dirty;
    }
  }

  canDeactivate(): boolean {
    if (this.incidentForm.dirty) {
      this.confirmCloseModalOpen();

      return false;
    } else {
      this.resetOnClose();
      this.isConfirmCloseModalOpen = false;

      return true;
    }
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.getEntries();
    this.buildForm();
    this.readSelectedIncidentDataFromStore();

    this.formatDate = getDateFormatFromUserProfile(this.translateService);
    this.language = getLanguage(this.store, this.destroy);

    this.store
      .pipe(
        select(selectEntries),
        map((state: IStoreApiItem<IEntriesModel>) => state.data),
        takeUntil(this.destroy),
      )
      .subscribe((entries: IEntriesModel) => {
        if (entries) {
          this.entries = entries;
          this.populateForm();
        }
      });

    this.readExcelData();

    this.previousRoute = localStorage.getItem('previousRoute');

    this.isSubmitting = this.store.pipe(
      select(selectIncident),
      takeUntil(this.destroy),
      map((selectedIncident: IStoreApiItem<IIncident>) => {
        return selectedIncident.isLoading;
      }),
    );
  }

  readExcelData(): void {
    this.incidentExcelData = this.store.select(selectIncidentExcelData).pipe(
      filter(
        (incidentExcelData: IStoreApiList<IIncidentExcelData>) =>
          !incidentExcelData.isLoading && !incidentExcelData.errors && !!incidentExcelData.data,
      ),
      takeUntil(this.destroy),
      map((incidentExcelData: IStoreApiList<IIncidentExcelData>) => {
        return incidentExcelData.data;
      }),
    );
  }

  populateForm(): void {
    this.route.params.pipe(takeUntil(this.destroy)).subscribe((params: Params) => {
      this.incidentActions.requestIncidentById(params.id);
    });
  }

  readSelectedIncidentDataFromStore(): void {
    this.store
      .pipe(
        select(selectIncident),
        filter((data: IStoreApiItem<IIncident>) => !data.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((selectedIncident: IStoreApiItem<IIncident>) => {
        if (selectedIncident.data) {
          this.selectedIncident = selectedIncident.data;

          const objectToPopulate: Partial<IIncident> = {
            aggregateId: selectedIncident.data.aggregateId,
            ecps: selectedIncident.data.ecps,
            incidentData: selectedIncident.data.incidentData,
            version: selectedIncident.data.version,
          };

          localStorage.setItem('ecpsList', JSON.stringify(selectedIncident.data.ecps));

          const ecps: IEcp[] = objectToPopulate.ecps ? structuredClone(objectToPopulate.ecps) : [];

          this.ecps.clear();

          ecps.forEach((ecp: IEcp) => {
            ecp.location = ecp.location ? ecp.location : { displayedCoordinates: '', address: '', postcode: '' };

            ecp.location = this.fb.group({
              displayedCoordinates: new FormControl({ value: null, disabled: true }),
              address: new FormControl({ value: ecp.location.address }),
              postcode: new FormControl({ value: ecp.location.postcode }),
            });

            this.ecps.push(this.fb.group(ecp));
          });

          this.incidentForm.patchValue(objectToPopulate);

          this.isLoading = false;
        }
      });
  }

  ngAfterViewInit(): void {
    this.activeTab = ReportCenterConstants.viewEditModalTabs.INCIDENT_INFORMATION;
  }

  buildForm(): void {
    this.incidentForm = new FormGroup({
      aggregateId: new FormControl(null),
      version: new FormControl(null),
      ecps: this.fb.array([]),
      incidentData: new FormGroup({
        incidentNumber: new FormControl(null),
        startTime: new FormControl(),
        endTime: new FormControl(null),
        incidentCategory: new FormControl(null),
        incidentType: new FormControl(null),
        incidentSpecialExposure: new FormControl(null),
        object: new FormControl(null),
        deployedVehicles: new FormControl({ value: null, disabled: true }),
        deployedResources: new FormControl(null),
        comment: new FormControl(null),
      }),
      firefighterHazmatFlags: this.fb.array([]),
    });
  }

  get ecps(): FormArray {
    return this.incidentForm.get('ecps') as FormArray;
  }

  confirmCloseModalOpen(): void {
    this.isConfirmCloseModalOpen = true;
  }

  navigateBack(isOpen: boolean): void {
    if (isOpen) {
      this.incidentForm.markAsPristine();

      if (window.location.pathname === this.linkToNavigate || !this.linkToNavigate) {
        this.router.navigate([this.previousRoute.split('/')[1]]);
      } else {
        this.router.navigate([this.linkToNavigate]);
      }
    } else {
      this.isConfirmCloseModalOpen = false;
    }
  }

  resetOnClose(): void {
    this.isSubmitted = false;
    this.tabsWithErrors = [];
    this.incidentForm.reset();
    this.activeTab = this.tabs.INCIDENT_INFORMATION;
    localStorage.removeItem(ReportCenterConstants.currentIncidentAnalysis);
    localStorage.removeItem('ecpsList');
  }

  setActiveTab(tab: string): void {
    this.selectedEventMarker = null;
    this.activeTab = tab;

    this.displayUnsavedIncidentInformationDataNotification();
  }

  markTabsWithErrors(): void {
    this.tabsWithErrors = [];

    if (this.isSubmitted) {
      if (this.incidentForm.invalid) {
        this.tabsWithErrors.push(this.tabs.INCIDENT_INFORMATION);
      }

      if (this.incidentForm.invalid) {
        this.tabsWithErrors.push(this.tabs.INCIDENT_ANALYSIS);
      }
    }
  }

  displayUnsavedIncidentInformationDataNotification(): void {
    if (this.incidentForm.dirty && this.activeTab !== this.tabs.INCIDENT_INFORMATION) {
      this.notificationsService.requestShowNotification(
        CommonConstants.notificationType.WARNING,
        IncidentNotificationConstants.notificationCodes.UNSAVED_INCIDENT_INFORMATION,
        IncidentNotificationConstants.notificationCodes,
      );
    }
  }

  onSubmit(): void {
    this.tabsWithErrors = [];
    this.isSubmitted = true;
    this.errors = null;
    this.markTabsWithErrors();

    if (this.incidentForm.invalid) {
      return;
    }

    this.updateIncident();
  }

  updateIncident(): void {
    const ecps: any[] = structuredClone(this.incidentForm.value.ecps);

    ecps.forEach((ecp: IEcp) => {
      ecp.location.coordinates = { lat: 0, lng: 0 };

      ecp.name = ecp.name === '-' ? null : ecp.name;
      ecp.user = ecp.user === '-' ? null : ecp.user;
      ecp.hubId = ecp.hubId === '-' ? null : ecp.hubId;
    });

    const isIncidentHazmat: boolean =
      this.incidentForm.get('incidentData.incidentType').value === this.hazmatIncidentType ||
      this.incidentForm.get('incidentData.incidentSpecialExposure').value === this.hazmatIncidentType;

    const useFormDataIfIsTouched = this.incidentForm.get('firefighterHazmatFlags').touched
      ? this.incidentForm.get('firefighterHazmatFlags').value
      : [];

    if (
      ReportCenterConstants.mediaAssetsVersion in localStorage &&
      localStorage.getItem(ReportCenterConstants.mediaAssetsVersion) > this.incidentForm.value.version
    ) {
      this.incidentForm.get('version').setValue(localStorage.getItem(ReportCenterConstants.mediaAssetsVersion));
    }

    const objToSend: any = {
      aggregateId: this.selectedIncident.aggregateId,
      ecps,
      incidentData: this.incidentForm.value.incidentData,
      firefighterHazmatFlags: isIncidentHazmat ? useFormDataIfIsTouched : [],
      version: this.incidentForm.value.version,
    };

    this.incidentForm.get('firefighterHazmatFlags')?.markAsUntouched();

    this.incidentActions.requestUpdateIncident({ ...objToSend });
    this.readUpdateIncidentState();
  }

  readUpdateIncidentState(): void {
    this.store
      .pipe(
        select(selectIncident),
        filter((selectedIncident: IStoreApiItem<IIncident>) => {
          return !selectedIncident.isLoading && (!!selectedIncident.errors || selectedIncident.isSuccess);
        }),
        take(1),
      )
      .subscribe((selectedIncident: IStoreApiItem<IIncident>) => {
        if (selectedIncident.errors) {
          this.errorsOnIncidentUpdate(selectedIncident);
        } else if (selectedIncident.isSuccess) {
          this.successAddUpdate = true;
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            IncidentNotificationConstants.notificationCodes.UPDATE_INCIDENT_SUCCESS,
            IncidentNotificationConstants.notificationCodes,
          );
          this.incidentForm.markAsPristine();
          this.populateForm();
          this.getEntries();
        }
      });
  }

  errorsOnIncidentUpdate(selectedIncident: IStoreApiItem<IIncident>): void {
    this.hasErrors = true;
    this.errors = selectedIncident.errors.error.code?.toString();

    if (
      this.errors === IncidentNotificationConstants.notificationCodes.OVERLAPPING_TIME_RANGE.value ||
      this.errors === IncidentNotificationConstants.notificationCodes.OVERLAPPING_START_TIME.value ||
      this.errors === IncidentNotificationConstants.notificationCodes.OVERLAPPING_END_TIME.value
    ) {
      const responseArray: string[] = selectedIncident.errors.error.debugMessage.split(':');

      this.overlappingIncidentNumber = responseArray[responseArray.length - 1].split(']')[0].trim();
      this.notificationsService.requestShowNotification(
        CommonConstants.notificationType.HIDDEN,
        this.errors,
        IncidentNotificationConstants.notificationCodes,
        { displayedValue: this.overlappingIncidentNumber ? this.overlappingIncidentNumber : '-' },
      );
    } else if (this.errors === IncidentNotificationConstants.notificationCodes.AGGREGATE_VERSION_CONFLICT.value) {
      this.notificationsService.requestShowNotification(
        CommonConstants.notificationType.ERROR,
        this.errors,
        IncidentNotificationConstants.notificationCodes,
      );
    } else {
      this.notificationsService.requestShowNotification(
        CommonConstants.notificationType.HIDDEN,
        this.errors,
        IncidentNotificationConstants.notificationCodes,
        { value: this.incidentForm.value.ecps[0].name },
      );
    }

    this.tabsWithErrors.push(this.tabs.INCIDENT_INFORMATION);
  }

  getManualEventId(event: any): void {
    this.selectedEventMarker = { id: event.id, name: event.alarmName };
  }

  resetFormErrors(): void {
    this.errors = null;
  }

  openCreateEventMarkerModal(): void {
    this.router.navigate(['report-center', 'update', this.selectedIncident.aggregateId, 'event-marker', 'create']);
  }

  openUpdateEventMarkerModal(): void {
    this.router.navigate([
      'report-center',
      'update',
      this.selectedIncident.aggregateId,
      'event-marker',
      'update',
      this.selectedEventMarker.id,
    ]);
  }

  openDeleteEventMarkerDialog(): void {
    this.openConfirmationDeleteDialog = true;
    this.activateClickOutside = false;
  }

  closeDeleteEventMarkerDialog(confirmation: boolean): void {
    if (confirmation) {
      this.deleteEventMarker();
    } else {
      this.openConfirmationDeleteDialog = false;
      this.activateClickOutside = true;
    }
  }

  deleteEventMarker(): void {
    const currentIncident: any = JSON.parse(localStorage.getItem(ReportCenterConstants.currentIncidentAnalysis));
    let selectedAppId: string;

    currentIncident.ecps.forEach((ecp: IEcp) => {
      ecp.eventMarkerClusters.forEach((eventMarkerCluster: IEventMarker[]) => {
        eventMarkerCluster.forEach((eventMarker: IEventMarker) => {
          if (eventMarker.id === this.selectedEventMarker.id) {
            selectedAppId = ecp.appId || '-';
          }
        });
      });
    });

    this.incidentActions.requestEventMarkerDelete(
      this.selectedIncident?.aggregateId,
      this.selectedEventMarker,
      selectedAppId,
      this.selectedIncident.version,
    );

    this.store
      .pipe(
        select(selectDeletedEventMarker),
        filter((eventMarker: IStoreApiItem<IEventMarker>) => !eventMarker.isLoading),
        take(1),
      )
      .subscribe((eventMarker: IStoreApiItem<IEventMarker>) => {
        if (eventMarker.isSuccess) {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            IncidentNotificationConstants.notificationCodes.DELETE_EVENT_MARKER_SUCCESS,
            IncidentNotificationConstants.notificationCodes,
            { manualEvent: eventMarker?.data?.name },
          );
          this.incidentActions.requestIncidentAnalysis(this.selectedIncident?.aggregateId);
          this.incidentActions.requestIncidentById(this.selectedIncident?.aggregateId);
        } else {
          this.errors = eventMarker.errors?.error.code?.toString();

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.ERROR,
            this.errors,
            IncidentNotificationConstants.notificationCodes,
          );
        }
      });
  }

  exportExcel(): void {
    const url: string[] = this.router.url.split('/');
    const incidentId: string = url[url.length - 1].toString();

    this.incidentActions.requestIncidentExcelData(incidentId);

    this.incidentExcelData.pipe(take(1)).subscribe((response: IIncidentExcelData) => {
      this.exportExcelService.translatedIncidentSheetName = this.translateService.instant(
        'INCIDENT_EXCEL.STR_INCIDENT_SHEET_NAME',
      );

      this.exportExcelService.exportIncidentExcel(response, this.formatDate);
    });
  }

  openUploadFileModal(): void {
    this.router.navigate(['report-center', 'update', this.selectedIncident.aggregateId, 'upload-media-file']);
  }

  getEntries(): void {
    this.incidentActions.requestIncidentEntries();
  }

  hasUnsavedData(): boolean {
    return this.incidentForm.dirty;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    localStorage.removeItem(ReportCenterConstants.mediaAssetsVersion);
  }
}
