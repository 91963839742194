<ignis-progress-bar *ngIf="isLoading$ | async"></ignis-progress-bar>

<ignis-table
  #ignisTableComponent
  class="large-height-table"
  [tableName]="tableName"
  [localColumns]="localTableColumns"
  [dataTable]="list"
  [resizableColumns]="true"
  [reorderableColumns]="true"
  [isDefaultRangeShown]="true"
  [activateClickOutside]="activateClickOutside"
  [datePickerFormat]="formatDate"
  [datePickerSelectionMode]="'range'"
  [isDatePickerShowingTime]="false"
  [displayFilters]="true"
  [customFilterLayout]="customFilterLayouts"
  [customColumnsLayout]="['personalId', 'firstName', 'lastName', 'fireStation']"
  [customColumnsRef]="[personalId, firstName, lastName, fireStation]"
  [lazy]="'true'"
  [activeDate]="selectedDates"
  [isLoading]="isLoading$ | async"
  [sortColumn]="sortColumn"
  [paginator]="'true'"
  [pageNumber]="pageNumber"
  [totalRecords]="totalRecords"
  [savedFiltersName]="tableFiltersKey"
  (columns)="tableHelperGetTableColumns($event)"
  (handleOnSortChange)="tableHelperOnChangeSort($event)"
  (handleTableRef)="tableHelperGetTableRef($event)"
  (handleRowSelection)="onBAWearerSelect($event)"
  (handleDoubleClickRowSelection)="editBAWearer($event)"
  (handleColumnVisibility)="changeColumnVisibility($event)"
  (handleFilterValue)="getFilterTableValue($event)"
  (handleOnLazyLoading)="tableHelperOnLazyLoading($event)"
  (handleOnPageChange)="tableHelperOnPageChange($event)"
>
  <ng-template #personalId let-personalId>
    <div *ngIf="personalId.field === 'personalId' && personalId.data">
      <ignis-table-ellipsis-data-column [columnData]="personalId"></ignis-table-ellipsis-data-column>
    </div>
  </ng-template>

  <ng-template #firstName let-firstName>
    <div *ngIf="firstName.field === 'firstName' && firstName.data">
      <ignis-table-ellipsis-data-column [columnData]="firstName"></ignis-table-ellipsis-data-column>
    </div>
  </ng-template>

  <ng-template #lastName let-lastName>
    <div *ngIf="lastName.field === 'lastName' && lastName.data">
      <ignis-table-ellipsis-data-column [columnData]="lastName"></ignis-table-ellipsis-data-column>
    </div>
  </ng-template>

  <ng-template #fireStation let-fireStation>
    <div *ngIf="fireStation.field === 'fireStation' && fireStation.data">
      <ignis-table-ellipsis-data-column [columnData]="fireStation"></ignis-table-ellipsis-data-column>
    </div>
  </ng-template>
</ignis-table>

<input type="file" accept=".csv" #fileInput class="upload-csv-input" (change)="uploadCSVFile($event.target.files)" />

<ignis-import-csv-error-modal
  [openModal]="openModal"
  [invalidDataList]="httpCustomErrorCode"
  [tableColumns]="importCSVColumns"
  [errorsConstant]="importCSVErrorCodes"
  [pageUrl]="pageUrl"
  (handleCloseModal)="closeModal($event)"
>
  <div slot="header">
    <odx-icon size="xlarge" name="error"></odx-icon>
    {{ 'BA_WEARER_ERROR.STR_ERROR_DESCRIPTION' | translate }}
  </div>
</ignis-import-csv-error-modal>

<footer class="blue-bar">
  <ignis-ba-wearer-list-view-footer
    [selectedItem]="selectedBAWearer"
    [isModalOpened]="openConfirmationDeleteDialog || disableFooterBtns"
    (handleCreate)="createBAWearer()"
    (handleEdit)="editBAWearer(selectedBAWearer)"
    (handleDelete)="openDeleteBAWearerDialog()"
    (handleDownloadTemplate)="downloadCSVTemplate()"
    (handleUploadCSVFile)="fileInput.click()"
  >
  </ignis-ba-wearer-list-view-footer>
</footer>

<ignis-delete-confirmation-modal
  [isOpen]="openConfirmationDeleteDialog"
  [isLoading]="isLoading$ | async"
  (handleDeleteModal)="closeDeleteBAWearerDialog($event)"
>
  <div slot="header">
    {{ 'BA_WEARER.STR_DELETE_BA_WEARER' | translate }}
  </div>
  <div slot="content">
    {{ 'BA_WEARER.STR_DELETE_BA_WEARER_MSG' | translate }}
  </div>
</ignis-delete-confirmation-modal>
