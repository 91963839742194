<div class="blue-bar-items">
  <button
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    id="bluebar-create"
    [disabled]="true"
    data-test-id="equipment_footer_items.create_equipment_btn"
    [pTooltip]="'EQUIPMENT_FOOTER_ITEMS.STR_ADD_EQUIPMENT_TOOLTIP' | translate"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <odx-icon size="inline" name="add"></odx-icon>
  </button>

  <button
    type="button"
    [disabled]="!selectedItem || isModalOpened"
    (click)="onEditClick($event)"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    id="bluebar-edit"
    data-test-id="equipment_footer_items.edit_equipment_btn"
    [pTooltip]="'EQUIPMENT_FOOTER_ITEMS.STR_EDIT_EQUIPMENT_TOOLTIP' | translate"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <odx-icon size="inline" name="edit"></odx-icon>
  </button>

  <button
    type="button"
    [disabled]="!selectedItem || isModalOpened"
    id="bluebar-delete"
    data-test-id="equipment_footer_items.delete_equipment_btn"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    [pTooltip]="'EQUIPMENT_FOOTER_ITEMS.STR_DELETE_EQUIPMENT_TOOLTIP' | translate"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <odx-icon name="delete"></odx-icon>
  </button>

  <button
    type="button"
    [disabled]="isModalOpened || router.url?.includes(WorkshopModuleRoutes.createEquipment)"
    (click)="onHistoryClick($event)"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    id="bluebar-history"
    data-test-id="equipment_footer_items.history_equipment_btn"
    [pTooltip]="'EQUIPMENT_FOOTER_ITEMS.STR_VIEW_HISTORY_TOOLTIP' | translate"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <odx-icon name="timeline-view"></odx-icon>
  </button>

  <button
    type="button"
    *ngIf="accessControlService.equipmentTestingGlobalFeatureToggle"
    [disabled]="
      disableStartTestBtn ||
      router.url?.includes(WorkshopModuleRoutes.updateEquipment) ||
      !accessControlService.checkPermission(appUserPermissionList.equipmentTest) ||
      isModalOpened
    "
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    (click)="onStartTestingClick($event)"
    id="bluebar-start-test"
    data-test-id="equipment_footer_items.start_test_btn"
    [pTooltip]="startTestButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #startTestButtonTooltipContent>
      @if (accessControlService.checkPermission(appUserPermissionList.equipmentTest)) {
        @if (!selectedItem && !router.url.includes(WorkshopModuleRoutes.updateEquipment)) {
          <div data-test-id="equipment_footer_items.start_test_not_selected_tooltip">
            {{ 'EQUIPMENT_FOOTER_ITEMS.STR_TOOLTIP1' | translate }}
          </div>
        } @else if (disableStartTestBtn) {
          <div data-test-id="equipment_footer_items.start_test_no_test_tooltip">
            {{ 'EQUIPMENT_FOOTER_ITEMS.STR_TOOLTIP2' | translate }}
          </div>
        } @else if (!disableStartTestBtn) {
          <div data-test-id="equipment_footer_items.start_test_tooltip">
            {{ 'EQUIPMENT_FOOTER_ITEMS.STR_START_PROTECTOR_TEST_TOOLTIP' | translate }}
          </div>
        }
      } @else {
        <div data-test-id="equipment_footer_items.start_test_no_permission_tooltip">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_NO_PERMISSION' | translate }}
        </div>
      }
    </ng-template>

    <odx-icon name="test-eq" size="medium"></odx-icon>
  </button>
  <div
    (mouseenter)="showSaveButtonTooltip = true"
    (mouseleave)="showSaveButtonTooltip = false"
    id="submit-btn-wrap"
    data-test-id="equipment_footer_items.save_equipment_data_wrap"
  >
    <button
      type="button"
      odxButton
      size="medium"
      variant="highlight"
      class="blue-bar-btn"
      [disabled]="
        !form?.valid ||
        !form?.dirty ||
        !accessControlService.checkPermission(appUserPermissionList.equipmentChangeTasks) ||
        isSubmitDisabled ||
        isModalOpened
      "
      id="bluebar-save"
      data-test-id="equipment_footer_items.save_equipment_data_btn"
      (click)="onSubmit($event)"
      [pTooltip]="saveButtonTooltipContent"
      tooltipPosition="top"
      positionLeft="5"
      positionTop="-10"
      tooltipStyleClass="footer-tooltip"
    >
      <ng-template #saveButtonTooltipContent>
        @if (!form?.valid && accessControlService.checkPermission(appUserPermissionList.equipmentWrite)) {
          <div data-test-id="equipment_footer_items.save_equipment_error_tooltip">{{ equipmentSaveTooltip }}</div>
        } @else if (!accessControlService.checkPermission(appUserPermissionList.equipmentChangeTasks)) {
          <div data-test-id="equipment_footer_items.save_equipment_no_permission_tooltip">
            {{ 'EQUIPMENT_FOOTER_ITEMS.STR_NO_PERMISSION' | translate }}
          </div>
        } @else if (
          (accessControlService.checkPermission(appUserPermissionList.equipmentWrite) ||
            accessControlService.checkPermission(appUserPermissionList.equipmentChangeTasks)) &&
          form?.valid
        ) {
          <div data-test-id="equipment_footer_items.save_equipment_tooltip">
            {{ 'EQUIPMENT_FOOTER_ITEMS.STR_SAVE_EQUIPMENT_TOOLTIP' | translate }}
          </div>
        }
      </ng-template>

      <odx-icon size="inline" name="save"></odx-icon>
    </button>
  </div>
</div>
