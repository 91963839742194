import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { select, Store } from '@ngrx/store';
import { ModalRef, ModalService, ModalSize, ModalVariant } from '@uib/angular';
import uniqBy from 'lodash-es/uniqBy';
import { FileSelectEvent, FileUpload } from 'primeng/fileupload';
import { filter, take } from 'rxjs';
import { CommonConstants, DropdownService, IApplicationState } from 'src/app/common';
import { NotificationConstants } from 'src/app/common/constants/notification.constants';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { IOKTAProfile } from 'src/app/common/models/common.model';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { UploadFilesService } from 'src/app/common/services/upload-helper/upload-files.service';
import { IncidentActions } from 'src/app/report-center/state/actions/incident.actions';
import { IStoreApiItem } from '../../../../../common';
import { ReportCenterConstants } from '../../../../constants/report-center.constants';
import { IIncident, INewNote } from '../../../../models/incident.model';
import { selectIncident, selectNewNote } from '../../../../state/selectors/incident.selector';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';

export interface MediaFile extends File {
  isUploaded: boolean;
}

@Component({
  selector: 'ignis-upload-media-file',
  templateUrl: './upload-media-file.component.html',
  styleUrls: ['./upload-media-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadMediaFileComponent extends OnDestroyMixin() implements OnInit, AfterViewInit {
  @ViewChild('uploadMediaFilesModal', { read: TemplateRef })
  public uploadMediaFilesModal: TemplateRef<any>;
  modalRef: ModalRef | any;
  ecpsList: any[];
  files: MediaFile[] | File[];
  submitBtnState: boolean = true;
  selectedTelemetryAddress: string;
  isLoading: boolean;
  submitSuccess: boolean;
  uploadFilesSuccess: boolean[];
  numberOfFilesInControl: number;
  dropdownIconCSSClass: string = CommonConstants.defaultDropdownIconCSSClass;
  maxSizeOfUploadedImages: number = CommonConstants.maxSizeOfUploadedImages;
  jwtHelper: JwtHelperService = new JwtHelperService();

  private modalService: ModalService = inject(ModalService);
  public dropdownService: DropdownService = inject(DropdownService);
  private uploadFileService: UploadFilesService = inject(UploadFilesService);
  private incidentActions: IncidentActions = inject(IncidentActions);
  private notificationsService: NotificationsService = inject(NotificationsService);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  location: Location = inject(Location);

  incidentVersion: number;

  constructor(
    private router: Router,
    private store: Store<IApplicationState>,
  ) {
    super();

    this.files = [];
    this.uploadFilesSuccess = [];
  }

  ngOnInit(): void {
    this.ecpsList = JSON.parse(localStorage.getItem('ecpsList'));
    this.ecpsList.forEach((elem: any) => {
      elem.telemetryAddress = elem.telemetryAddress ? elem.telemetryAddress : '-';
      elem.label = elem.name ? elem.name : elem.telemetryAddress;
    });

    this.readIncidentVersion();
  }

  ngAfterViewInit(): void {
    this.openAddUserModal();
  }

  openAddUserModal(): void {
    this.modalRef = this.modalService.open(this.uploadMediaFilesModal, {
      size: ModalSize.MEDIUM,
      variant: ModalVariant.DEFAULT,
      isDismissable: false,
      data: 'isOpen',
    });
  }

  closeModal(onlyClose: boolean): void {
    this.location.back();
    Object.defineProperty(this.modalRef, 'data', { value: 'isClosed', writable: false });
    this.modalRef?.close();
    this.modalRef = {
      close: () => {
        return;
      },
    };

    if (this.numberOfFilesInControl > 0 && onlyClose) {
      this.files.forEach((file: any, index: number) => {
        this.deleteUploadedFile(file, index);
      });
    }

    this.submitBtnState = true;
  }

  readIncidentVersion(): void {
    this.store
      .pipe(
        select(selectIncident),
        filter((data: IStoreApiItem<IIncident>) => !data.isLoading),
      )
      .subscribe((selectedIncident: IStoreApiItem<IIncident>) => {
        this.incidentVersion = selectedIncident.data?.version;
        this.cdr.detectChanges();
      });
  }

  selectTelemetryAddress(event: any): void {
    if (event.value && this.files.length > 0) {
      this.submitBtnState = false;
    } else {
      this.submitBtnState = true;
    }
  }

  deleteUploadedFile(file: any, index: number): void {
    this.isLoading = true;

    this.uploadFileService.deleteSelectedFile(file.name).subscribe({
      next: () => {
        this.files.splice(index, 1);
        this.uploadFilesSuccess.splice(index, 1);

        if (this.uploadFilesSuccess.length < 1) {
          this.submitBtnState = true;
        }

        this.notificationsService.requestShowNotification(
          CommonConstants.notificationType.SUCCESS,
          NotificationConstants.commonCodes.DELETE_FILE_SUCCESS,
          NotificationConstants.commonCodes,
        );

        this.isLoading = false;
        this.cdr.detectChanges();
      },
      error: () => {
        this.notificationsService.requestShowNotification(
          CommonConstants.notificationType.ERROR,
          NotificationConstants.commonCodes.DELETE_FILE_FAILED,
          NotificationConstants.commonCodes,
        );

        this.isLoading = false;
        this.cdr.detectChanges();
      },
    });
  }

  disableRemoveUploadedFileBtn(): void {
    setTimeout(() => {
      const flUploadRow: NodeList = document.querySelectorAll('.p-fileupload-row');

      flUploadRow.forEach((element: HTMLElement) => {
        const removeUploadedFileBtn: Element = element.children[element.children.length - 1].children[0];

        removeUploadedFileBtn.setAttribute('disabled', 'true');
      });
    }, 0);
  }

  enableRemoveUploadedFileBtn(): void {
    const flUploadRow: NodeList = document.querySelectorAll('.p-fileupload-row');

    flUploadRow.forEach((element: HTMLElement) => {
      const removeUploadedFileBtn: Element = element.children[element.children.length - 1].children[0];

      removeUploadedFileBtn.removeAttribute('disabled');
    });
  }

  selectFilesToBeUploaded(event: FileSelectEvent, uploadFileControl: FileUpload | any): void {
    const filesToUpload: File[] = event.currentFiles;

    if (filesToUpload.length > 0) {
      this.disableRemoveUploadedFileBtn();

      filesToUpload.forEach((file: MediaFile) => {
        this.files.push(file);
      });
    }

    this.files.forEach((fileToUpload: MediaFile, fileIndex: number) => {
      this.numberOfFilesInControl = uploadFileControl.files.length;

      if (!fileToUpload.isUploaded) {
        this.uploadFile(fileToUpload, fileIndex);
      }
    });
  }

  uploadFile(fileToUpload: MediaFile | any, fileIndex: number): void {
    this.isLoading = true;

    this.uploadFileService
      .uploadFiles(fileToUpload)
      .pipe(take(1))
      .subscribe({
        next: (response: HttpResponse<MediaFile>) => {
          const mediaUrl: string[] = response.headers?.get('Location').split('/');
          const generatedFileName: string = mediaUrl.pop();

          Object.defineProperty(fileToUpload, 'name', {
            writable: true,
            value: generatedFileName,
          });

          fileToUpload.isUploaded = true;

          this.uploadFilesSuccess.push(true);

          if (this.uploadFilesSuccess.length === this.numberOfFilesInControl) {
            this.submitBtnState = this.selectedTelemetryAddress ? false : true;

            this.isLoading = false;

            this.notificationsService.requestShowNotification(
              CommonConstants.notificationType.SUCCESS,
              NotificationConstants.commonCodes.UPLOAD_FILE_SUCCESS,
              NotificationConstants.commonCodes,
            );

            setTimeout(() => {
              this.enableRemoveUploadedFileBtn();
            }, 0);
          }

          this.cdr.detectChanges();
        },
        error: () => {
          this.files.splice(fileIndex, 1);

          this.enableRemoveUploadedFileBtn();

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.ERROR,
            NotificationConstants.commonCodes.UPLOAD_FILE_FAILED,
            NotificationConstants.commonCodes,
          );

          this.isLoading = false;
          this.cdr.detectChanges();
        },
      });
  }

  removeUploadedFile(event: any): void {
    this.files.forEach((file: any, index: number) => {
      if (file.name.includes(event.file.name)) {
        this.deleteUploadedFile(file, index);
      }
    });
  }

  setMediaAssets(): void {
    this.submitBtnState = true;
    const loggedInUser: Partial<IOKTAProfile> = this.jwtHelper.decodeToken(
      localStorage.getItem(CommonConstants.AUTH_TOKEN),
    );
    const newNoteMediaFiles: INewNote[] = [];

    this.files.forEach((file: MediaFile) => {
      newNoteMediaFiles.push({
        senderName: `${loggedInUser?.firstname} ${loggedInUser?.lastname}`,
        mediaLink: file.name,
        ecpId: this.selectedTelemetryAddress,
      });
    });

    const routeArr: string[] = this.router.url.split('/');
    const aggregateIdFromUrl: string = routeArr[routeArr.length - 2];

    if (
      ReportCenterConstants.mediaAssetsVersion in localStorage &&
      Number(localStorage.getItem(ReportCenterConstants.mediaAssetsVersion)) > Number(this.incidentVersion)
    ) {
      this.incidentVersion = Number(localStorage.getItem(ReportCenterConstants.mediaAssetsVersion));
    }

    this.incidentActions.requestAddNoteOrMediaFile(
      aggregateIdFromUrl,
      uniqBy(newNoteMediaFiles, 'mediaLink'),
      this.incidentVersion,
    );

    this.store
      .pipe(
        select(selectNewNote),
        filter((newNote: IStoreApiItem<INewNote>) => !newNote.isLoading),
        take(1),
      )
      .subscribe((response: any) => {
        this.submitBtnState = false;

        if (response.isSuccess) {
          this.closeModal(false);
          this.submitSuccess = true;

          this.incidentActions.requestIncidentMediaAssets(aggregateIdFromUrl);

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            NotificationConstants.commonCodes.ADD_MEDIA_ASSET_SUCCESS,
            NotificationConstants.commonCodes,
          );
        } else {
          this.submitSuccess = false;

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.ERROR,
            NotificationConstants.commonCodes.ADD_MEDIA_ASSET_FAILED,
            NotificationConstants.commonCodes,
          );
        }

        this.cdr.detectChanges();
      });
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/dot-notation
    super['ngOnDestroy'] && super['ngOnDestroy']();

    this.uploadFilesSuccess = [];

    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (this.router.url !== 'upload-media-file' && this.modalRef && this.modalRef['data'] === 'isOpen') {
      this.modalRef.close();
    }
  }
}
