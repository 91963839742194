import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaAuthGuard } from '@okta/okta-angular';
import { CreateUpdateEquipmentComponent } from '../components/create-update-equipment/create-update-equipment.component';
import {
  editEquipmentResolver,
  editEquipmentResolverFn,
} from '../components/create-update-equipment/resolver/edit-equipment.resolver';
import { EquipmentHistoryComponent } from '../components/equipment-history/equipment-history.component';
import { InventoryComponent } from '../components/inventory/inventory.component';
import { TaskDocumentationComponent } from '../components/task-documentation/task-documentation.component';
import { serviceResultResolver } from '../components/task-documentation/task-result/resolver/service-result.resolver';
import { TaskResultComponent } from '../components/task-documentation/task-result/task-result.component';
import { CompleteBatchChecklistComponent } from '../components/tasks/complete-batch-checklist/complete-batch-checklist.component';
import { batchChecklistResolver } from '../components/tasks/complete-batch-checklist/resolver/checklist.resolver';
import { CompleteChecklistComponent } from '../components/tasks/complete-checklist/complete-checklist.component';
import { TasksComponent } from '../components/tasks/tasks.component';
import { TestEquipmentComponent } from '../components/test-equipment/test-equipment.component';
import { RedirectAfterTestingComponent } from '../components/test-equipment/testing/redirect-after-testing/redirect-after-testing.component';
import { TestingComponent } from '../components/test-equipment/testing/testing.component';
import { CylinderChargerComponent } from '../components/workflow/cylinder-charger/cylinder-charger.component';
import { WorkflowComponent } from '../components/workflow/workflow.component';
import { WorkshopComponent } from '../components/workshop/workshop.component';
import { WorkshopModuleRoutes } from '../constants/workshop-module-routes.constants';
import {
  CompleteBatchChecklistDeactivateGuard,
  CompleteChecklistDeactivateGuard,
  DeactivateGuard,
} from './guards/can-deactivate/can-deactivate.guard';
import { CanActivateBachChecklistGuard } from './guards/workshop-batch-checklist/can-activate-batch-checklist.guard';
import { WorkshopFeatureToggleGuard } from './guards/workshop-feature-toggle/workshop-feature-toggle.guard';
import { WorkshopTestResultsModuleGuard } from './guards/workshop-test-results/equipment-test-results.module.guard';
import { WorkshopTestingModuleGuard } from './guards/workshop-testing/workshop-testing.module.guard';
import { WorkshopWorkflowModuleGuard } from './guards/workshop-workflow/workshop-workflow.module.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [OktaAuthGuard],
    canActivateChild: [OktaAuthGuard],
    component: WorkshopComponent,
    children: [
      {
        path: WorkshopModuleRoutes.inventory,
        component: InventoryComponent,
        pathMatch: 'full',
      },
      {
        path: `${WorkshopModuleRoutes.taskResult}/:id`,
        component: TaskResultComponent,
        canActivate: [WorkshopTestResultsModuleGuard],
        canDeactivate: [DeactivateGuard],
        resolve: { serviceResultResolver },
      },
      {
        path: WorkshopModuleRoutes.taskDocumentation,
        component: TaskDocumentationComponent,
        canActivate: [WorkshopTestResultsModuleGuard],
      },
      {
        path: `${WorkshopModuleRoutes.testEquipment}/:id`,
        component: TestEquipmentComponent,
        canActivate: [WorkshopTestingModuleGuard],
        canDeactivate: [DeactivateGuard],
        resolve: { editEquipmentResolver },
      },
      {
        path: `${WorkshopModuleRoutes.testingEquipment}/:id`,
        component: TestingComponent,
        canActivate: [WorkshopTestingModuleGuard],
      },
      {
        path: `${WorkshopModuleRoutes.testingEquipmentResults}/:id/results`,
        component: RedirectAfterTestingComponent,
      },
      {
        path: WorkshopModuleRoutes.workflow,
        component: WorkflowComponent,
        canActivate: [WorkshopWorkflowModuleGuard],
        children: [
          {
            path: WorkshopModuleRoutes.cylinder,
            component: CylinderChargerComponent,
          },
          {
            path: `${WorkshopModuleRoutes.completeBatchChecklist}/:id`,
            component: CompleteBatchChecklistComponent,
            canDeactivate: [CompleteBatchChecklistDeactivateGuard],
            canActivate: [CanActivateBachChecklistGuard],
            resolve: { checklistResolver: batchChecklistResolver },
          },
          {
            path: `${WorkshopModuleRoutes.completeChecklist}/:id`,
            component: CompleteChecklistComponent,
            canDeactivate: [CompleteChecklistDeactivateGuard],
            resolve: { checklistResolver: batchChecklistResolver, getEquipmentById: editEquipmentResolverFn },
          },
        ],
      },
      {
        path: WorkshopModuleRoutes.tasks,
        component: TasksComponent,
        canActivate: [WorkshopWorkflowModuleGuard],
        children: [
          {
            path: WorkshopModuleRoutes.cylinder,
            component: CylinderChargerComponent,
          },
          {
            path: `${WorkshopModuleRoutes.completeBatchChecklist}/:id`,
            component: CompleteBatchChecklistComponent,
            canDeactivate: [CompleteBatchChecklistDeactivateGuard],
            canActivate: [CanActivateBachChecklistGuard],
            resolve: { checklistResolver: batchChecklistResolver },
          },
          {
            path: `${WorkshopModuleRoutes.completeChecklist}/:id`,
            component: CompleteChecklistComponent,
            canDeactivate: [CompleteChecklistDeactivateGuard],
            resolve: { checklistResolver: batchChecklistResolver, getEquipmentById: editEquipmentResolverFn },
          },
        ],
      },
      {
        path: WorkshopModuleRoutes.taskDocumentation,
        component: TaskDocumentationComponent,
        canActivate: [WorkshopFeatureToggleGuard],
      },
      {
        path: WorkshopModuleRoutes.createEquipment,
        component: CreateUpdateEquipmentComponent,
        canDeactivate: [DeactivateGuard],
      },
      {
        path: `${WorkshopModuleRoutes.updateEquipment}/:id`,
        component: CreateUpdateEquipmentComponent,
        canDeactivate: [DeactivateGuard],
        resolve: { editEquipmentResolver },
      },
      {
        path: `${WorkshopModuleRoutes.equipmentHistory}/:id`,
        component: EquipmentHistoryComponent,
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WorkshopRoutingModule {}
