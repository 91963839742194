import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs';
import { EquipmentNotificationConstants, ProtectorSoftwareNotificationConstants } from 'src/app/workshop/constants';
import { WorkshopConstants } from 'src/app/workshop/constants/workshop.constants';
import { ILocationAggregateId, IRAMStatus, ITestStatus } from 'src/app/workshop/models/equipment.model';
import { ILocationId } from 'src/app/workshop/models/service-task.model';
import { RAMConnectorErrorsService } from 'src/app/workshop/services/ram-connector-errors.service';
import { RAMActions } from 'src/app/workshop/state/actions/ram';
import {
  selectCheckRAMConnectorStatus,
  selectCheckRAMPortStatus,
} from 'src/app/workshop/state/selectors/equipment.selector';
import { AccessControlService } from '../../../root';
import { CommonConstants } from '../../constants/common.constants';
import { IStoreApiItem } from '../../models/store-api-item.model';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { ApplicationState } from '../../state/models/app.state.model';

export function checkRAMConnectorPort(parameters: {
  ramActions: RAMActions;
  store: Store<ApplicationState>;
  notificationsService: NotificationsService;
}): Promise<IRAMStatus> {
  const portLists: number[] = [65130, 65140, 65150, 65160, 65170];
  const availableRAMConnectorPort: number =
    CommonConstants.availableRAMConnectorPort in localStorage
      ? Number(localStorage.getItem(CommonConstants.availableRAMConnectorPort))
      : portLists[0];

  return new Promise<IRAMStatus>((resolve: (value: IRAMStatus) => void) => {
    parameters.ramActions.requestCheckRAMPortStatus(availableRAMConnectorPort);

    parameters.store
      .select(selectCheckRAMPortStatus)
      .pipe(
        filter((ramConnectorPortStatus: IStoreApiItem<IRAMStatus>) => !ramConnectorPortStatus.isLoading),
        take(5),
      )
      .subscribe((ramConnectorPortStatus: IStoreApiItem<IRAMStatus>) => {
        if (ramConnectorPortStatus.errors && ramConnectorPortStatus.errors.status !== 200) {
          const url: URL = ramConnectorPortStatus.errors?.url ? new URL(ramConnectorPortStatus.errors?.url) : null;
          const port: string = url?.port;

          requestToCheckRAMPort(port, portLists, parameters);
          displayRAMConnectorNotificationIfConnectionFail(portLists, parameters.notificationsService);
        }

        if (ramConnectorPortStatus.data && ramConnectorPortStatus.data.ramIsUp) {
          localStorage.setItem(CommonConstants.availableRAMConnectorPort, ramConnectorPortStatus.data.port);

          resolve(ramConnectorPortStatus.data);
        }
      });
  });
}

function requestToCheckRAMPort(port: string, portLists: number[], parameters): void {
  if (Number(port) === portLists[0]) {
    portLists.shift();

    if (portLists[0]) {
      parameters.ramActions.requestCheckRAMPortStatus(portLists[0]);
    }
  } else {
    parameters.ramActions.requestCheckRAMPortStatus(portLists[0]);
    portLists.shift();
  }
}

function displayRAMConnectorNotificationIfConnectionFail(
  ports: number[],
  notificationsService: NotificationsService,
): void {
  if (ports.length < 1) {
    notificationsService.requestShowNotification(
      CommonConstants.notificationType.ERROR,
      ProtectorSoftwareNotificationConstants.notificationCodes.PROTECTOR_SOFTWARE_CONNECTION_LOST,
      ProtectorSoftwareNotificationConstants.notificationCodes,
    );
  }
}

export function startTest(parameters: {
  ramActions: RAMActions;
  store: Store<ApplicationState>;
  ramConnectorErrorsService: RAMConnectorErrorsService;
  router: Router;
  notificationsService: NotificationsService;
  equipmentAggregateId: string;
  module?: string;
}): Promise<boolean | string> {
  return new Promise<boolean | string>((resolve: (value: boolean | string) => void) => {
    parameters.ramActions.requestCheckRAMConnectorStatus(
      Number(localStorage.getItem(CommonConstants.availableRAMConnectorPort)),
    );

    parameters.store
      .select(selectCheckRAMConnectorStatus)
      .pipe(
        filter((ramConnectorStatus: IStoreApiItem<ITestStatus>) => !ramConnectorStatus.isLoading),
        take(1),
      )
      .subscribe((ramConnectorStatus: IStoreApiItem<ITestStatus>) => {
        if (ramConnectorStatus.errors) {
          if (
            ramConnectorStatus.errors.error.code.toString() !==
            ProtectorSoftwareNotificationConstants.notificationCodes.PROTECTOR_SOFTWARE_VERSION_NOT_SUPPORTED.value
          ) {
            displayErrorMessage(ramConnectorStatus.errors, parameters.ramConnectorErrorsService);
          }

          resolve(ramConnectorStatus.errors.error.code.toString());
        } else if (ramConnectorStatus.data && ramConnectorStatus.data.status === 'idle') {
          parameters.router.navigate(['workshop', 'test-equipment', parameters.equipmentAggregateId]);
          localStorage.setItem(WorkshopConstants.testStartFrom, parameters.module);

          resolve(true);
        } else {
          resolve(true);

          if (ramConnectorStatus.data.status === 'migration-running') {
            parameters.notificationsService.requestShowNotification(
              CommonConstants.notificationType.WARNING,
              EquipmentNotificationConstants.notificationCodes.MIGRATION_IN_PROGRESS,
              EquipmentNotificationConstants.notificationCodes,
            );

            return;
          }

          parameters.notificationsService.requestShowNotification(
            CommonConstants.notificationType.WARNING,
            EquipmentNotificationConstants.notificationCodes.EQUIPMENT_TEST_IN_PROGRESS,
            EquipmentNotificationConstants.notificationCodes,
          );
        }
      });
  });
}

function displayErrorMessage(
  httpCustomError: HttpErrorResponse,
  ramConnectorErrorsService: RAMConnectorErrorsService,
): void {
  ramConnectorErrorsService.processProtectorSoftwareErrorMsg(httpCustomError.error.code);
}

export function processLocationPath(locationPathList: ILocationAggregateId[]): ILocationId[] {
  const locationPath: ILocationId[] = locationPathList?.map(({ aggregateId, ...rest }) => ({
    id: aggregateId,
    ...rest,
  }));

  return locationPath ?? [];
}

export function checkAutoUpdateFeatureToggleBeforeStartProtectorTest(
  accessControlService: AccessControlService,
  ramConnectorStatus: boolean | string,
  notificationsService: NotificationsService,
): boolean | string {
  if (!accessControlService.ramConnectorAutoUpdateFeatureToggle && typeof ramConnectorStatus === 'string') {
    notificationsService.requestShowNotification(
      CommonConstants.notificationType.ERROR,
      ProtectorSoftwareNotificationConstants.notificationCodes.PROTECTOR_SOFTWARE_VERSION_NOT_SUPPORTED,
      ProtectorSoftwareNotificationConstants.notificationCodes,
    );

    return false;
  } else {
    return ramConnectorStatus;
  }
}
