<div class="mb-4">
  <h1 class="tab-menu-header" *ngIf="modalType !== modalTypes.CREATE; else addEventHeader">
    {{ 'EVENT_MARKER.UPDATE_EVENT' | translate }}
  </h1>
  <ng-template #addEventHeader>
    <h1 class="tab-menu-header">
      {{ 'EVENT_MARKER.ADD_EVENT' | translate }}
    </h1>
  </ng-template>
</div>
<form [formGroup]="eventMarkerForm">
  <div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12 col-lg-6 mb-3">
      <div class="uib-form-field uib-form-field--required">
        <label class="uib-form-field__label">
          {{ 'EVENT_MARKER.EVENT_NAME' | translate }}
        </label>
        <div
          [ngClass]="
            eventMarkerForm.controls.name.invalid && isSubmitted
              ? 'uib-textfield uib-textfield--error'
              : eventMarkerForm.controls.name.valid && isSubmitted
                ? 'uib-textfield uib-textfield--success'
                : 'uib-textfield'
          "
        >
          <input
            ngDefaultControl
            type="text"
            ignisDisableAutocomplete
            id="event-marker-name"
            data-test-id="event_marker.event_name"
            formControlName="name"
            placeholder="{{ 'EVENT_MARKER.EVENT_NAME_PLACEHOLDER' | translate }}"
            class="uib-textfield__input [ c-location-value__ellipsis ]"
            (change)="nameChanged()"
          />

          <span
            class="uib-textfield__validation-icon"
            data-test-id="event_marker.event_name_error_tooltip"
            [pTooltip]="
              eventMarkerForm.controls.name.invalid && isSubmitted
                ? ('INCIDENT_NOTIFICATION.STR_EVENT_NAME_TOOLTIP' | translate)
                : null
            "
            tooltipPosition="top"
            positionTop="-10"
          >
          </span>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12 col-lg-6 mb-3">
      <div class="uib-form-field">
        <label class="uib-form-field__label">
          {{ 'EVENT_MARKER.EVENT_TYPE' | translate }}
        </label>
        <div class="uib-dropdown">
          <p-dropdown
            #dd1
            (onShow)="dropdownService.openDropdown(dd1)"
            (onHide)="dropdownService.closeDropdown(dd1)"
            optionValue="value"
            styleClass="uib-dropdown"
            ngDefaultControl
            formControlName="type"
            data-test-id="event_marker.event_type"
            [dropdownIcon]="dropdownIconCSSClass"
            [options]="eventMarkerEntries"
            [placeholder]="'EVENT_MARKER.EVENT_TYPE_PLACEHOLDER' | translate"
            [showClear]="true"
          >
            <ng-template let-option pTemplate="item">
              <span>{{ option.label }}</span>
            </ng-template>
          </p-dropdown>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12 mb-3">
      <div class="uib-form-field uib-form-field--required">
        <label class="uib-form-field__label">
          {{ 'EVENT_MARKER.EVENT_START_DATE' | translate }}
        </label>
        <div
          class="uib-textfield"
          [ngClass]="{ 'error-border': startTimeError || (isSubmitted && !eventMarkerForm.get('startTime').value) }"
        >
          <span
            *ngIf="startTimeError || (isSubmitted && !eventMarkerForm.get('startTime').value)"
            class="datepicker-tooltip"
            id="start-date-error-tooltip"
            data-test-id="event_marker.start_date_error_tooltip"
            [odxTooltip]="startDateErrorTooltip"
            [odxTooltipOptions]="{ position: 'top' }"
            [odxTooltipVisible]="startTimeError || (isSubmitted && !eventMarkerForm.get('startTime').value)"
          >
            <ng-template #startDateErrorTooltip>
              {{
                startTimeError === incidentErrors.START_DATE_OUTSIDE_TIMELINE.value
                  ? ('INCIDENT_NOTIFICATION.STR_START_DATE_OUTSIDE_TIMELINE' | translate)
                  : ('EVENT_MARKER.EVENT_START_DATE_MANDATORY' | translate)
              }}
            </ng-template>
          </span>
          <ignis-datepicker
            formControlName="startTime"
            ngDefaultControl
            data-test-id="event_marker.start_time"
            appendTo="body"
            [selectionMode]="'single'"
            [formatDate]="formatDate"
            [showTime]="true"
            [clearable]="false"
            [defaultRange]="
              modalType === modalTypes.CREATE
                ? initialMinDate
                : formatLocaleDate(eventMarkerForm.get('startTime').value)
            "
            (dateSelect)="onStartDateSelect($event)"
            [changeToPreviousValue]="true"
          >
          </ignis-datepicker>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12 mb-3">
      <div class="uib-form-field uib-form-field--required">
        <label class="uib-form-field__label">
          {{ 'EVENT_MARKER.EVENT_END_DATE' | translate }}
        </label>
        <div
          class="uib-textfield"
          [ngClass]="{
            'error-border':
              endTimeError ||
              (isSubmitted && !eventMarkerForm.get('endTime').value) ||
              (isSubmitted && eventMarkerForm.get('endTime').errors?.wrongDuration),
          }"
        >
          <span
            *ngIf="
              endTimeError ||
              (isSubmitted && !eventMarkerForm.get('endTime').value) ||
              (isSubmitted && eventMarkerForm.get('endTime').errors?.wrongDuration)
            "
            class="datepicker-tooltip"
            id="end-date-error-tooltip"
            data-test-id="event_marker.end_date_error_tooltip"
            [odxTooltip]="endDateErrorTooltip"
            [odxTooltipOptions]="{ position: 'top' }"
            [odxTooltipVisible]="
              endTimeError ||
              (isSubmitted && !eventMarkerForm.get('endTime').value) ||
              (isSubmitted && eventMarkerForm.get('endTime').errors?.wrongDuration)
            "
          >
            <ng-template #endDateErrorTooltip>
              {{
                endTimeError === incidentErrors.END_DATE_BEFORE_START_DATE.value
                  ? ('EVENT_MARKER.EVENT_END_DATE_ERROR' | translate)
                  : endTimeError === incidentErrors.END_DATE_OUTSIDE_TIMELINE.value
                    ? ('INCIDENT_NOTIFICATION.STR_END_DATE_OUTSIDE_TIMELINE' | translate)
                    : eventMarkerForm.get('endTime').errors?.wrongDuration
                      ? ('EVENT_MARKER.EVENT_DURATION_LESS_THAN_ONE_MINUTE' | translate)
                      : ('EVENT_MARKER.EVENT_END_DATE_MANDATORY' | translate)
              }}
            </ng-template>
          </span>
          <ignis-datepicker
            formControlName="endTime"
            ngDefaultControl
            appendTo="body"
            [selectionMode]="'single'"
            [formatDate]="formatDate"
            data-test-id="event_marker.end_time"
            [showTime]="true"
            [clearable]="false"
            [defaultRange]="
              modalType === modalTypes.CREATE ? initialMaxDate : formatLocaleDate(eventMarkerForm.get('endTime').value)
            "
            (dateSelect)="onEndDateSelect($event)"
            [changeToPreviousValue]="true"
          >
          </ignis-datepicker>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12 col-lg-6 mb-3">
      <div class="uib-form-field">
        <label class="uib-form-field__label">
          {{ 'EVENT_MARKER.EVENT_ECP' | translate }}
        </label>
        <div class="uib-dropdown">
          <p-dropdown
            #dd2
            (onShow)="dropdownService.openDropdown(dd2)"
            (onHide)="dropdownService.closeDropdown(dd2)"
            optionValue="appId"
            styleClass="uib-dropdown"
            data-test-id="event_marker.event_ecp"
            ngDefaultControl
            formControlName="ecp"
            [dropdownIcon]="dropdownIconCSSClass"
            [options]="ecpsList"
            [placeholder]="'EVENT_MARKER.EVENT_ECP_PLACEHOLDER' | translate"
            [showClear]="false"
          >
            <ng-template let-option pTemplate="item">
              <span>{{ option.label }}</span>
            </ng-template>
          </p-dropdown>
        </div>
      </div>
    </div>

    <div class="col-md-12 col-sm-12 col-xs-12 col-lg-6 mb-3">
      <div class="uib-form-field">
        <label class="uib-form-field__label">
          {{ 'EVENT_MARKER.EVENT_DURATION' | translate }}
        </label>
        <div class="uib-textfield">
          <input
            ngDefaultControl
            type="text"
            ignisDisableAutocomplete
            data-test-id="event_marker.event_duration"
            id="duration"
            formControlName="duration"
            class="uib-textfield__input"
          />
          <span class="uib-textfield__validation-icon"></span>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-2">
      <div class="uib-form-field comment-row">
        <label class="uib-form-field__label">
          {{ 'EVENT_MARKER.EVENT_COMMENT' | translate }}
        </label>
        <textarea
          ignisDisableAutocomplete
          ngDefaultControl
          class="form-control text-area"
          id="event-marker-comment"
          data-test-id="event_marker.event_comment"
          formControlName="comment"
          rows="10"
        ></textarea>
      </div>
    </div>
  </div>
</form>
