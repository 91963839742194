<ng-template odxModal #confirmMergeRemoteIncidentsModal>
  <odx-modal-header>
    <odx-area-header>
      <odx-icon size="xlarge" name="warning"></odx-icon>
      {{ 'CONFIRM_MERGE_REMOTE_INCIDENTS.STR_TITLE' | translate }}
    </odx-area-header>
  </odx-modal-header>
  <odx-modal-content [ngStyle]="{ overflow: 'visible' }">
    <p>
      {{ 'CONFIRM_MERGE_REMOTE_INCIDENTS.STR_DESCRIPTION' | translate }}
    </p>
  </odx-modal-content>
  <odx-modal-footer>
    <button odxButton odxModalClose variant="secondary" (click)="close()" data-test-id="modal_actions.no_btn">
      {{ 'CONFIRM_MERGE_REMOTE_INCIDENTS.STR_NO_BTN' | translate }}
    </button>

    <button odxButton variant="primary" (click)="proceedMerge()" data-test-id="modal_actions.yes_btn">
      {{ 'CONFIRM_MERGE_REMOTE_INCIDENTS.STR_YES_BTN' | translate }}
    </button>
  </odx-modal-footer>
</ng-template>
