<ng-template uibModal #createUpdateEventMarkerModal let-modal="close">
  <div *ngIf="!isLoading; else loadingEventMarker" class="c-create-form__wrapper-height">
    <div class="c-create-update-wrapper">
      <div class="mt-2 c-form-wrapper">
        <form [formGroup]="eventMarkerForm" (ngSubmit)="onSubmit()">
          <ignis-event-marker-form
            (closeModal)="closeModal($event)"
            [selectedEventMarker]="selectedEventMarker"
            [formatDate]="formatDate"
            [isLoading]="isLoading"
            [isSubmitted]="isSubmitted"
            [eventMarkerForm]="eventMarkerForm"
            [errors]="errors"
            [modalType]="modalType"
            [currentIncident]="currentIncident"
            [entries]="entries | async"
          ></ignis-event-marker-form>
        </form>
      </div>

      <div class="uib-button-group uib-button-group--right-to-left mb-2 [ c-form-footer__buttons ]">
        <button
          class="c-uib__btn"
          data-test-id="create_event_marker.save_btn"
          uibButton
          variant="primary"
          (click)="onSubmit()"
          [disabled]="!eventMarkerForm.dirty || (isSubmitting | async)"
        >
          {{ 'CONFIRM_CLOSE_MODAL.STR_SAVE_BTN' | translate }}
        </button>
        <button
          *ngIf="eventMarkerForm.dirty; else closeButton"
          class="c-uib__btn"
          data-test-id="create_event_marker.cancel_btn"
          uibButton
          (click)="confirmCloseModalOpen()"
          variant="secondary"
        >
          {{ 'CONFIRM_CLOSE_MODAL.STR_CANCEL_BTN' | translate }}
        </button>
        <ng-template #closeButton>
          <button
            class="c-uib__btn"
            uibButton
            (click)="closeBanner(true)"
            variant="secondary"
            data-test-id="create_event_marker.cancel_btn"
          >
            {{ 'CONFIRM_CLOSE_MODAL.STR_CANCEL_BTN' | translate }}
          </button>
        </ng-template>
      </div>
    </div>
  </div>
  <ng-template #loadingEventMarker>
    <div class="loading-container">
      <div [odxLoadingSpinner]="true" [odxLoadingSpinnerMinHeight]="60" class="app-loader"></div>
    </div>
  </ng-template>

  <ignis-confirmation-modal
    class="discard-changes-banner"
    [isOpen]="isConfirmCloseModalOpen"
    (handleConfirmationModal)="closeBanner($event)"
  >
    <div slot="content">
      {{ 'CONFIRM_CLOSE_MODAL.STR_DESCRIPTION_EVENT_MARKER' | translate }}
    </div>
  </ignis-confirmation-modal>
</ng-template>
