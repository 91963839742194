import { ITableColumn } from 'src/app/common';
import { TableFilterType } from 'src/app/common/constants';

export const tableColumns: ITableColumn[] = [
  {
    field: 'personalId',
    header: 'BA_WEARER.STR_TABLE_PERSONAL_ID',
    visible: true,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'BA_WEARER.STR_TABLE_SEARCH_PERSONAL_ID',
  },
  {
    field: 'firstName',
    header: 'BA_WEARER.STR_TABLE_FIRST_NAME',
    visible: true,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'BA_WEARER.STR_TABLE_SEARCH_FIRST_NAME',
  },
  {
    field: 'lastName',
    header: 'BA_WEARER.STR_TABLE_LAST_NAME',
    visible: true,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'BA_WEARER.STR_TABLE_SEARCH_LAST_NAME',
  },
  {
    field: 'fireStation',
    header: 'BA_WEARER.STR_TABLE_FIRE_STATION',
    visible: true,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'BA_WEARER.STR_TABLE_SEARCH_FIRE_STATION',
  },
  {
    field: 'rfidCard',
    header: 'BA_WEARER.STR_TABLE_RFID_CARD',
    visible: true,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'BA_WEARER.STR_TABLE_SEARCH_RFID_CARD',
  },
  {
    field: 'displayLastUpdate',
    header: 'BA_WEARER.STR_TABLE_LAST_UPDATE',
    visible: true,
    filterType: TableFilterType.DATE,
    filterKey: ['startLastUpdate', 'endLastUpdate'],
  },
];

export const baWearerImportCSVColumns: ITableColumn[] = [
  { field: 'lineNumber', header: 'BA_WEARER_ERRORS.STR_LINE_NUMBER', visible: true },
  { field: 'personalIdentifier', header: 'BA_WEARER_ERRORS.STR_PERSONAL_ID', visible: true },
  { field: 'errorMessage', header: 'BA_WEARER_ERRORS.STR_ERROR_MSG', visible: true },
];
